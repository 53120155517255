import GoodsBelt from './GoodsBelt.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsBelt = createHelper('GoodsBelt', getProps)

export {
  GoodsBelt,
  useGoodsBelt,
}
