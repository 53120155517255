/**
 * PromotionTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  const {
    typeIdText,
    typeIdIcon,
  } = data.value.promotionTag ?? {}

  const type = getType(data.value)

  const isShowMask = data.value.is_checked !== 1 && data.value.isGiftItem

  return {
    isShow: !!typeIdText,
    isShowMask,
    isClick: !!typeIdIcon && (data.value.isSheinClubGiftItem || !isShowMask),
    showMoreIcon: !!typeIdIcon,
    type,
    text: typeIdText,
    hideCountdown: true,
    timestamp: null,
    displayDateOrTime: false,
    maxWidth: '100%',
  }
}

export function getEvents() {
  return {}
}

const getType = item => {
  const { typeId, promotionLogoType } = item.promotionTag ?? {}

  if (typeId === 4 && promotionLogoType === 8) return 'club-gift'

  if (typeId === 10 && [1, 4, 5].includes(item.flashType)) return 'flash'

  if (typeId === 32) return 'coupon'

  return 'normal'
}
