<template>
  <div
    v-if="isShow"
    class="goods-black5-flag"
    @click="handleClick"
  >
    <img
      class="lazyload"
      :data-src="finallyImgUrl"
      :src="constants.LAZY_IMG"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useInjectConstants } from'../../hooks/useConstant'
import { transformImg } from '@shein/common-function'

/**
 * 商品行黑五角标组件
 */

// hooks
const constants = useInjectConstants()

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
  */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 图片地址
   * @type {String}
   */
  imgUrl: {
    type: String,
    default: '',
  },
})

const finallyImgUrl = computed(() => transformImg({ img: props.imgUrl }))
</script>

<style lang="less" scoped>
.goods-black5-flag {
  position: absolute;
  top: 0px;
  right: 0px;
  img {
    width: 32px;
  }
}
</style>
