/**
 * 触发更新购物车信息的action
 */
export const UPDATE_CART_INFO_ACTIONS = {
  /**
   * 查车
   */
  FETCH: 'fetch',
  /**
   * 更新数量
   */
  UPDATE_QUANTITY: 'updateQuantity',
  /**
   * 删除
   */
  DELETE: 'delete',
  /**
   * 勾选/取消勾选
   */
  MODIFY: 'modify',
  /**
   * 更新销售属性
   */
  UPDATE_ATTR: 'updateAttr',
}


export const DEFAULT_UI_CONFIG = {
  disableQuickShip: false,
  disableFreeShipping: true,
  autoUseCoupon: false,
  autoUsePoints: false,
  allowDeleteGift: 0, // 0 不允许删除 1允许删除
  repickAddon: 0, // 0不允许切换 1允许切换
  showDelPrice: 1, // 是否展示划线价 0不展示 1展示
}

export const NEW_POS_KEYS = [
  'Cartnoteligibleforcoupons',
]

export const PAGES = ['cart_item', 'business_cart']
