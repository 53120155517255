<template>
  <div
    v-if="isShow"
    :class="classnames"
    :style="style"
    @click="handleClick"
  >
    <template v-if="type === 'preferred-seller'">
      <img
        class="goods-store__icon-preferred-seller"
        :src="`${constants.PUBLIC_CDN}/she_dist/images/cart/sui_icon_preferred_seller-9f0612ec30.png`"
      />
    </template>
    <template v-else>
      <sui_icon_store_16px_1
        class="goods-store__icon-store"
        :size="storeIconFontSize"
      />
    </template>
    <span class="goods-store__text">{{ text }}</span>
    <sui_icon_more_right_12px_1
      v-if="isClick"
      class="goods-store__icon-more"
      size="12px"
      :is-rotate="constants.GBCssRight"
    />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { sui_icon_more_right_12px_1, sui_icon_store_16px_1 } from '@shein-aidc/icon-vue3'
import { useInjectConstants } from'../../hooks/useConstant'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

// hooks
const constants = useInjectConstants()
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['click', 'report', 'expose'])

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: false,
  },
  /**
   * preferred-seller: 首选卖家
   */
  type: {
    type: String,
    default: 'normal',
  },
  text: {
    type: String,
    default: '',
  },
  storeIconFontSize: {
    type: String,
    default: '16px',
  },
  textFontSize: {
    type: String,
    default: '13px',
  },
  textFontWeight: {
    type: String,
    default: 'normal',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const classnames = computed(() => [
  'goods-store',
  {
    'goods-store__pointer': props.isClick,
  },
])

const style = computed(() => {
  return {
    '--font-size': props.textFontSize,
    '--font-weight': props.textFontWeight,
    '--store-icon-font-size': props.storeIconFontSize,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    maxWidth: props.maxWidth,
  }
})

const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('GoodsStore', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less" scoped>
.goods-store {
  display: flex;
  align-items: center;
  opacity: var(--mask-opacity);

  &__pointer {
    cursor: pointer;
  }

  &__text {
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon-store, &__icon-preferred-seller {
    flex-shrink: 0;
    margin-right: 2px;
  }

  &__icon-preferred-seller {
    width: var(--store-icon-font-size);
    height: var(--store-icon-font-size);
  }

  &__icon-more {
    flex-shrink: 0;
    margin-left: 2px;
  }
}
</style>
