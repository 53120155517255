import { template } from '@shein/common-function'

/**
 * EstimatedPrice 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language, constant }) {
  // 获取颜色
  const getColor = (colors) => constant.value.IS_RW ? colors[1] : colors[0]

  let text = language.value.SHEIN_KEY_PC_29465
  if (constant.value.IS_SUGGESTED) {
    text = template(data.value.product?.feFinallyPrice?.amountWithSymbol, '{0} ' + language.value.SHEIN_KEY_PC_30553)
  }

  let type = 'normal'
  if (data.value.promotionTypeIds?.includes(29)) {
    type = 'club'
  }

  let isShowEstimated = false
  if (data.value.isSoldOutItem || data.value.isGiftItem || data.value.isAddItem) {
    isShowEstimated = false
  } else if (data.value.is_checked === 0) {
    isShowEstimated = data.value.isNotCheckedEstimate && !!data.value.per_coupon_discount?.length
  } else {
    isShowEstimated = data.value.coupn_flag == 1 || data.value.is_auto_use_points_flag == 1
  }

  // 有折扣 && 不是赠品 && 非合规站点
  let isShowDiscount = +data.value.product?.unit_discount > 0
    && !data.value.isGiftItem
    && !constant.value.IS_SUGGESTED
    && data.value.isNewDiscountStyle

  const info = getDiscountInfo(data.value, constant.value.lang)
  const discount = info.unitDiscount

  return {
    isShow: isShowEstimated || isShowDiscount,
    isShowEstimated,
    isShowDiscount,
    isShowMask: false,
    isClick: false,
    hideCountdown: false,
    showMoreIcon: false,
    text,
    discount,
    fontColor: getColor(
      type === 'club' ?
        (data.value.isDiscountLogoA ? FONT_COLOR.CLUB_V2 : FONT_COLOR.CLUB) :
        FONT_COLOR.NORMAL
    ),
    lineColor: getColor(
      type === 'club' ?
        (data.value.isDiscountLogoA ? LINE_COLOR.CLUB_V2 : LINE_COLOR.CLUB) :
        LINE_COLOR.NORMAL
    ),
    bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',

    // 优惠明细需要的字段
    data: data.value,
  }
}

const getDiscountInfo = (data, lang) => {
  // 折扣格式化
  const discountFormatter = (discount, lang) => (lang !== 'il' ? `-${discount}%` : `${discount}%`)

  // 单项折扣
  const unitDiscount = discountFormatter(data.product?.unit_discount, lang)
  // 特价折扣
  const saleDiscount = discountFormatter(data.product?.sale_discount, lang)

  return {
    unitDiscount, // 最大的折扣
    saleDiscount,
  }
}

const FONT_COLOR = {
  NORMAL: [
    '#FA6338',
    '#FA6338',
  ],
  CLUB: [
    '#C96E3F',
    '#C96E3F',
  ],
  CLUB_V2: [
    '#873C00',
    '#873C00',
  ],
}

const LINE_COLOR = {
  NORMAL: [
    '#FDB9A6',
    '#FDB9A6',
  ],
  CLUB: [
    'rgba(201, 110, 63, .4)',
    'rgba(201, 110, 63, .4)',
  ],
  CLUB_V2: [
    'rgba(135, 60, 0, .4)',
    'rgba(135, 60, 0, .4)',
  ],
}
