/**
 * MorePromotionTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant, language }) {
  const getPromotionDiscounts = () => (data.value.promotionDiscounts?.map(item => ({
    label: item.promotionName,
    value: `: -${item.single_discount_value?.amountWithSymbol}`
  })))

  const getSingleOtherDiscount = () => {
    if (data.value.single_other_discount?.amount > 0) {
      return {
        label: language.value.SHEIN_KEY_PC_17702,
        value: `: -${data.value.single_other_discount?.amountWithSymbol}`
      }
    }
  }
  const getCouponDiscount = () => {
    if (data.value.couponDiscounts?.length) {
      return {
        label: language.value.SHEIN_KEY_PC_29825,
        value: `: -${data.value.single_coupon_discount?.amountWithSymbol}`
      }
    }
  }
  const getPointDiscount = () => {
    if (data.value.single_point_discount?.amount > 0) {
      return {
        label: language.value.SHEIN_KEY_PC_29826,
        value: `: -${data.value.single_point_discount?.amountWithSymbol}`
      }
    }
  }

  return {
    isShow: data.value.promotion_discount?.length > 0 || data.value.single_other_discount?.amount > 0 || data.value.single_coupon_discount?.amount > 0 || data.value.single_point_discount?.amount > 0,
    isShowMask: data.value.is_checked !== 1 && data.value.isGiftItem,
    text: language.value.SHEIN_KEY_PC_29500,
    disabledIcon: false,
    popoverTitle: constant.value.IS_SUGGESTED ? language.value.SHEIN_KEY_PC_31172 : language.value.SHEIN_KEY_PC_29502,
    showPromotionDiscounts: true,
    showSingleOtherDiscount: true,
    showCouponDiscount: true,
    showPointDiscount: true,
    promotionDiscounts: getPromotionDiscounts(),
    singleOtherDiscount: getSingleOtherDiscount(),
    couponDiscount: getCouponDiscount(),
    pointDiscount: getPointDiscount()
  }
}

export function getEvents() {
  return {}
}
