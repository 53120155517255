<template>
  <div
    v-if="isShow"
    :style="styles"
    class="reduce-price-hint"
  >
    <sui_icon_decrease_12px
      :color="props.fontColor"
      size="12px"
    />
    <div
      class="reduce-price-hint__text"
      v-html="hintText"
    >
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_decrease_12px } from '@shein-aidc/icon-vue3'
import { useInjectLanguage } from '../../hooks/useLanguage'

/**
 * 商品行降价提示组件
 */

// hooks
const language = useInjectLanguage()

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 颜色
   * @type {String}
   */
  fontColor: {
    type: String,
    default: '#C44A01',
  },
  /**
   * 降价金额
   * @type {String}
   */
  reduceAmount: {
    type: String,
    default: '',
  },
})

// computed
const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
  }
})

const hintText = computed(() => {
  return language.value?.SHEIN_KEY_PC_16553?.replace('{0}', `<b>${props.reduceAmount}</b>`)
})
</script>

<style lang="less" scoped>
.reduce-price-hint {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: var(--font-color);

  .reduce-price-hint__text {
    b {
      font-weight: bold;
    }
  }
}
</style>
