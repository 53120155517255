import GoodsPrice from './GoodsPrice.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsPrice = createHelper('GoodsPrice', getProps)

export {
  GoodsPrice,
  useGoodsPrice,
}
