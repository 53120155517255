/**
 * 数量编辑器组件 GoodsQty
 * GoodsQty 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }) {
  const max = Math.min(data.value.realTimeInventory, 99)
  // 勾选数量超过库存导致失效
  const isSoldoutByInventory = data.value.status == 1 && data.value.realTimeInventory && data.value.quantity > data.value.realTimeInventory
  /**
   * 是否显示
   * 1. 不是附赠品会显示
   * 2. 不是失效商品会显示
   * 3. 因为勾选数量超过库存导致失效的商品会显示
   */
  const isShow = !data.value.isGiftItem && !data.value.isSoldOutItem || isSoldoutByInventory
  

  let isShowMask = false
  let isClick = true
  /**
   * 新人专享价商品 || 附赠品 需要展示蒙层且不可点击
   */
  if (data.value.isNewcomerItem || data.value.isGiftItem || data.value.isAddItem) {
    isShowMask = true
    isClick = false
  }

  return {
    isShow,
    isShowMask,
    isClick,
    isSoldoutByInventory,
    value: data.value.quantity,
    max,
    inputTips: language.value.SHEIN_KEY_PC_28787
  }
}
