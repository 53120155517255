import PromotionTag from './PromotionTag.vue'
import { getProps, getEvents } from './helper.js'
import { createHelper } from '../../../hooks/useHelper'

const usePromotionTag = createHelper('PromotionTag', getProps, getEvents)

export {
  PromotionTag,
  usePromotionTag,
}
