import GoodsDeleteButtonV1 from './GoodsDeleteButtonV1.vue'
import GoodsDeleteButtonV2 from './GoodsDeleteButtonV2.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsDeleteButton = createHelper('GoodsDeleteButton', getProps)

export {
  GoodsDeleteButtonV1,
  GoodsDeleteButtonV2,
  useGoodsDeleteButton,
}
