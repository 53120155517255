<template>
  <div 
    class="progress-bar j-progress-bar"
  >
    <div
      v-if="isSingle === true"
      key="single"
      class="progress-bar-bg bg-single-rule"
      :class="{
        'is-animation': animation,
        'is-transparent': transparent,
        'bg-single-rule_none-border': singleProgress == '0',
      }"
      :style="{
        width: `${singleProgress}%`,
      }"
    >
    </div>
    <template v-else-if="isSingle === false">
      <div
        key="multiple"
        class="progress-bar-bg bg-multiple-rule"
        :class="{
          'is-animation': animation,
          'is-transparent': transparent,
          'bg-multiple-rule_none-border': multipleProgress == '0',
        }"
        :style="{
          width: `${multipleProgress}%`,
        }"
      >
      </div>
      <CircleIcon
        v-for="(item, index) in progressData"
        :key="index"
        class="ico"
        :style="{
          [GB_cssRight ? 'left' : 'right']: `${100/progressData.length * (progressData.length - index - 1)}%`
        }"
        :complete="isComplete(item)"
        :opacity="isOpacity(item)"
      />
    </template>
  </div>
</template>

<script>
import CircleIcon from './CircleIcon.vue'
const { GB_cssRight } = gbCommonInfo

const sleep = (time) => {
  return new Promise(r => setTimeout(r, time))
}

export default {
  components: {
    CircleIcon
  },
  props: {
    progressData: {
      type: Array,
      default: () => ([])
    },
    animation: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    b5: {
      type: Boolean,
      default: false,
    },
    styleConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      GB_cssRight,
      isSingle: null, // 初始化时，single/multiple 进度条都不渲染
      singleProgress: 0,
      multipleProgress: 0,
    }
  },
  watch: {
    progressData: {
      handler(newV, oldV) {
        const ov = this.getMultipleProgress(oldV || [])
        const nv = this.getMultipleProgress(newV || [])
        if (ov !== nv) {
          this.dealWithProgress(newV, oldV)
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    async dealWithProgress(data, oldData) {
      if (!data || !Array.isArray(data)) return 
      const { activeRuleIndex, progressRate } = data[0] || {}
      const isSingle = data.length == 1 || activeRuleIndex == 0

      // 首次打开凑单的进度不需要动画
      if (!oldData) {
        this.isSingle = isSingle
        isSingle ? this.singleProgress = progressRate : this.multipleProgress = this.getMultipleProgress(data)
        return
      }

      if (isSingle) {
        this.isSingle = isSingle
        await sleep(30) // 需要等待视图出现才有动画
        this.singleProgress = progressRate
        return
      } 

      // 如果加车之后直接跳到超过第一档
      if (this.isSingle == null) {
        this.isSingle = true
        await sleep(30)
      }

      // 从第一档跳到第二档
      if (this.isSingle === true) {
        this.singleProgress = 100
        await sleep(500)
        this.isSingle = isSingle
        await sleep(30)
        this.multipleProgress = this.getMultipleProgress(data)
        return
      }

      this.isSingle = isSingle
      await sleep(30)
      this.multipleProgress = this.getMultipleProgress(data)
    },
    getMultipleProgress(progressData) {
      return progressData.reduce((acc, cur) => {
        return acc + (cur.progressRate / progressData.length)
      }, 0)
    },
    isComplete(item) {
      return item.activeRuleIndex == -1 || item.activeRuleIndex > item.ruleIndex
    },
    isOpacity(item) {
      if (item.activeRuleIndex == -1) {
        return item.ruleIndex !== this.progressData.length - 1
      }
      return item.ruleIndex >= item.activeRuleIndex || item.activeRuleIndex - item.ruleIndex > 1
    },
  },
}
</script>

<style lang="less" scoped>
@BorderRadius: 12px;
@height: 10px;
.progress-bar {
  position: relative;
  max-width: 532px;
  min-width: 70%;
  height: @height;
  background: #EDEDED;
  border-radius: @BorderRadius;
  overflow: hidden;
  .progress-bar-bg {
    position: relative;
    box-sizing: border-box;
    height: @height;
    border-radius: @BorderRadius;
    background-size: 450px 20px;
    max-width: 100%;
    &.bg-single-rule {
      border: 0.5px solid #FF5F1B;
      background-image: repeating-linear-gradient(
        115deg,
        #FF8F3F 0%,
        #FF8F3F 1%,
        #FF772B 1%,
        #FF772B 2%
      );
      &_none-border {
        border: none;
      }
    }
    &.bg-multiple-rule {
      &_none-border {
        border: none;
      }

      border: 0.5px solid #E6433E;
      background-image: repeating-linear-gradient(
        115deg,
        #FB5F56 0%,
        #FB5F56 1%,
        #E03C38 1%,
        #E03C38 2%
      );
      /* rw:begin */
      background-image: var(--inner-bar-bg, repeating-linear-gradient(
        115deg,
        #F949B2 0%,
        #F949B2 1%,
        #F81E55 1%,
        #F81E55 2%
      ));
    }

    &.is-animation {
      animation: slider-ltr 16s linear infinite /* rtl: slider-rtl 16s linear infinite */;
      transition: width .3s linear;
    }
    &.is-transparent::before {
      content: '';
      border-radius: @BorderRadius;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, .2));
    }
  }

  .ico {
    position: absolute;
    top: 1px;
  }
}

@keyframes slider-ltr {
  0% { background-position-x: 100%; }
}
@keyframes slider-rtl {
  0% { background-position-x: -100%; }
}
</style>
