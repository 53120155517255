/**
 * GoodsBelt 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant, language }) {
  const BG_COLOR = {
    SOLD_OUT_BELT: [
      'rgba(0,0,0,0.6)',
      'rgba(0,0,0,0.6)',
    ],
    GIFT_BELT: [
      'rgba(250, 99, 56, 0.8)',
      'rgba(255, 105, 110, 0.8)',
    ],
    VIP_GIFT_BELT: [
      'rgba(135, 60, 0, 0.8)',
      // RW 没有
      'rgba(135, 60, 0, 0.8)',
    ],
    FLASH: [
      'rgba(252, 211, 100, 0.9)',
      'rgba(255, 80, 120, 0.8)'
    ],
    FLASH_NEW_PERSON: [
      'rgba(250, 99, 56, 0.8)',
      'rgba(255, 105, 110, 0.8)',
    ],
    FLASH_V2: [
      'rgba(0,0,0,0.6)',
      'rgba(0,0,0,0.6)',
    ],
    FLASH_NEW_PERSON_V2: [
      'rgba(0,0,0,0.6)',
      'rgba(0,0,0,0.6)',
    ],
    INVENTORY_BELT: [
      'rgba(0,0,0,0.6)',
      'rgba(0,0,0,0.6)',
    ]
  }

  const FONT_COLOR = {
    SOLD_OUT_BELT: [
      '#FFFFFF',
      '#FFFFFF',
    ],
    GIFT_BELT: [
      '#FFFFFF',
      '#FFFFFF',
    ],
    VIP_GIFT_BELT: [
      '#FFFFFF',
      // RW 没有
      '#FFFFFF',
    ],
    FLASH: [
      '#222222',
      '#FFFFFF',
    ],
    FLASH_NEW_PERSON: [
      '#FFFFFF',
      '#FFFFFF',
    ],
    FLASH_V2: [
      '#FFFFFF',
      '#FFFFFF',
    ],
    FLASH_NEW_PERSON_V2: [
      '#FFFFFF',
      '#FFFFFF',
    ],
    INVENTORY_BELT: [
      '#FFFFFF',
      '#FFFFFF',
    ]
  }
  
  // 转换闪购类型, 0: 非闪购, 1: 普通闪购, 2: 新人闪购, 3: 特殊闪购, 4: 分人群专区闪购, 5: 不分人群专区闪购, -1: 直播闪购(PC无直播闪购)
  const flashSaleType = data.value.promotionTypeIds?.includes(10) ? data.value.flashType : (data.value.promotionTypeIds?.includes(11) ? -1 : 0)
  // 售罄腰带
  const isSoldOutBelt = data.value.product?.is_on_sale == '0' || data.value.isSoldOutItem
  // 赠品腰带
  const isGiftBelt = data.value.isGiftItem && !data.value.isSheinClubGiftItem
  // 会员赠品腰带
  const isVipGiftBelt = data.value.isGiftItem && data.value.isSheinClubGiftItem
  // 库存腰带
  const isInventoryBelt = data.value.stock_show_type == 1 && constant.value.CART_STOCK_TIPS_SWITCH
  // 闪购腰带
  const isFlashBelt = data.value.stock_show_type == 0 && data.value.realTimeInventory > 0 && [-1, 1, 4, 5].includes(flashSaleType)
  // 新人腰带
  const isFlashNewPersonBelt = data.value.stock_show_type == 0 && data.value.realTimeInventory > 0 && [0, 2, 3, 6].includes(flashSaleType)

  // 获取不同站点腰带颜色
  const getColor = (colors) => constant.value.IS_RW ? colors[1] : colors[0]
  const isShowMask = (data.value.isGiftItem || data.value.isSheinClubGiftItem) && !data.value.is_checked

  // 获取闪购腰带数量
  const getFlashNum = () => {
    const promotionFlash = data.value.product?.product_promotion_info?.filter(i => [10].includes(i.type_id))
    const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
    const realTimeInventory = +data.value.realTimeInventory || 0
    const flashNum = stock - sold_num
    const minNum = Math.min(realTimeInventory, flashNum)
    const maxNum = Math.max(realTimeInventory, flashNum)
    return minNum > 0 ? minNum : maxNum
  }

  if (isSoldOutBelt) {
    return {
      isShowMask,
      bgColor: getColor(BG_COLOR.SOLD_OUT_BELT),
      fontColor: getColor(FONT_COLOR.SOLD_OUT_BELT),
      beltText: data.value.isInvalid ? language.value.SHEIN_KEY_PC_30888 : language.value.SHEIN_KEY_PC_14553,
    }
  } else if (isGiftBelt) {
    return {
      isShowMask,
      bgColor: getColor(BG_COLOR.GIFT_BELT),
      fontColor: getColor(FONT_COLOR.GIFT_BELT),
      beltText: language.value.SHEIN_KEY_PC_25239,
    }
  } else if (isVipGiftBelt) {
    return {
      isShowMask,
      bgColor: getColor(BG_COLOR.VIP_GIFT_BELT),
      fontColor: getColor(FONT_COLOR.VIP_GIFT_BELT),
      beltText: language.value.SHEIN_KEY_PC_25239,
    }
  } else if (isInventoryBelt) {
    return {
      isShowMask,
      bgColor: getColor(BG_COLOR.INVENTORY_BELT),
      fontColor: getColor(FONT_COLOR.INVENTORY_BELT),
      beltText: language.value.SHEIN_KEY_PC_21054,
    }
  } else if (isFlashBelt) {
    return {
      isShowMask,
      bgColor: data.value.isDiscountLogoA ? getColor(BG_COLOR.FLASH_V2) : getColor(BG_COLOR.FLASH),
      fontColor: data.value.isDiscountLogoA ? getColor(FONT_COLOR.FLASH_V2) : getColor(FONT_COLOR.FLASH),
      beltText: language.value.SHEIN_KEY_PC_25797?.replace('{0}', flashSaleType ? getFlashNum() : data.value.realTimeInventory)
    }
  } else if (isFlashNewPersonBelt) {
    return {
      isShowMask,
      bgColor: data.value.isDiscountLogoA ? getColor(BG_COLOR.FLASH_NEW_PERSON_V2) : getColor(BG_COLOR.FLASH_NEW_PERSON),
      fontColor: data.value.isDiscountLogoA ? getColor(FONT_COLOR.FLASH_NEW_PERSON_V2) : getColor(FONT_COLOR.FLASH_NEW_PERSON),
      beltText: language.value.SHEIN_KEY_PC_25797?.replace('{0}', flashSaleType ? getFlashNum() : data.value.realTimeInventory),
    }
  }
}
