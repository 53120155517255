import GoodsAlert from './GoodsAlert.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsAlert = createHelper('GoodsAlert', getProps)

export {
  GoodsAlert,
  useGoodsAlert,
}
