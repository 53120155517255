import GoodsImg from './GoodsImg.vue'
import { getProps, getEvents } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsImg = createHelper('GoodsImg', getProps, getEvents)

export {
  GoodsImg,
  useGoodsImg,
}
