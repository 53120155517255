import CustomContentBox from 'public/src/pages/components/custom-content/index.js'

/**
 * GoodsCustomContent 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language, constant }) {
  const isCustomGood = Boolean(Object.keys(data.value.product?.customization_info || {}).length)
  const customInfo = data.value.product?.customization_info?.preview || {}
  const haveCustomData = customInfo?.images?.length || customInfo?.text?.length || customInfo?.effects?.length
  return {
    isShow: isCustomGood && haveCustomData && !constant.value.IS_RW && !data.value.isSoldOutItem,
    btnText: language.value.SHEIN_KEY_PC_27387,
    data: data.value.product?.customization_info || {}
  }
}

export function getEvents() {
  return {
    /**
     * 点击标题
     * @param {Object} data - 商品行数据
     * @param {Object} props - 当前组件 props
     * @param {Object} constant - 常量配置
     */
    onClick: async ({ props }) => {
      CustomContentBox.open({
        data: props.value.data,
      })
    },
  }
}
