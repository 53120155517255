import EvoluTag from './EvoluTag.vue'
import { getProps, getEvents } from './helper.js'
import { createHelper } from '../../../hooks/useHelper'

const useEvoluTag = createHelper('EvoluTag', getProps, getEvents)

export {
  EvoluTag,
  useEvoluTag,
}
