<template>
  <div
    v-if="isShow"
    class="estimated-price__wrapper"
  >
    <EstimatedPopover
      v-if="isHover"
      v-bind="estimatedPopoverProps"
    >
      <template #reference="{ visible }">
        <EstimatedReference
          v-bind="estimatedReferenceProps"
          :visible="visible"
        />
      </template>
    </EstimatedPopover>
    <EstimatedReference
      v-else
      v-bind="estimatedReferenceProps"
    />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { useInjectConstants } from'../../hooks/useConstant'
import { useInjectLanguage } from '../../hooks/useLanguage'

// TODO: 当前依赖外部业务组件
import EstimatedPopover from '@/public/src/pages/cart_new/components/EstimatedDetailPopover/index.vue'

import EstimatedReference from './components/EstimatedReference.vue'

// hooks
const registerExpose = useInjectRegisterExpose()
const constants = useInjectConstants()
const language = useInjectLanguage()

const emit = defineEmits(['expose'])

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 显示 Estimated
   */
  isShowEstimated: {
    type: Boolean,
    default: true,
  },
  /**
   * 显示 Discount
   */
  isShowDiscount: {
    type: Boolean,
    default: true,
  },
  /**
   * 是否有移入交互
   * @type {Boolean}
   */
  isHover: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  discount: {
    type: String,
    default: '',
  },
  /**
   * 隐藏倒计时
   */
  hideCountdown: {
    type: Boolean,
    default: false,
  },
  timestamp: {
    type: Number,
    default: null,
  },
  fontColor: {
    type: String,
    default: '#FA6338',
  },
  lineColor: {
    type: String,
    default: '#FDB9A6',
  },
  bgColor: {
    type: String,
    default: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },

  data: {
    type: Object,
    default: null,
  },
  cartInfo: {
    type: Object,
    default: () => ({}),
  },
  integrityPromotionInfo: {
    type: Object,
    default: () => ({}),
  },
  flashSaleGroupList: {
    type: Object,
    default: () => ({}),
  },
})


const estimatedReferenceProps = computed(() => ({
  isShowEstimated: props.isShowEstimated,
  isShowDiscount: props.isShowDiscount,
  isHover: props.isHover,
  text: props.text,
  discount: props.discount,
  hideCountdown: props.hideCountdown,
  timestamp: props.timestamp,
  fontColor: props.fontColor,
  lineColor: props.lineColor,
  bgColor: props.bgColor,
  maxWidth: props.maxWidth,
}))

const estimatedPopoverProps = computed(() => ({
  show: true,
  appendToBody: true,
  fixDisappearPosition: true,
  language: language.value,
  isSuggested: constants.value.IS_SUGGESTED,

  item: props.data,
  cartInfo: props.cartInfo,
  integrityPromotionInfo: props.integrityPromotionInfo,
  flashSaleGroupList: props.flashSaleGroupList,
}))

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('EstimatedPrice', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less" scoped>
.estimated-price__wrapper {
  display: inline-block;
  max-width: 100%;
}
</style>
