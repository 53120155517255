import isReselect from '../../utils/isReselect'

/**
 * 商品销售属性组件 GoodsSaleAttr
 * GoodsSaleAttr 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant, language }) {
  // 是否赠品/加价购附属品
  const isGiftOrAddItem = data.value.isGiftItem || data.value.isAddItem
  /**
   * 是否展示重新选择按钮
   * 商品售罄：
   *    1. 有效商品（当前尺码售罄，其他尺码有货）&& 非赠品/加价购附属品 && 非新人商品
   *    2. 付费会员赠品 && 付费会员权益存在
   */
  const showReselect = isReselect({ data, constant })

  // 是否展示尺码按钮
  const showAttr = !data.value.isSoldOutItem && data.value.product?.sku_sale_attr?.length > 0

  // 是否展示色块图
  const showColorImg = data.value.product?.main_sale_attr_show_mode == 2 && data.value.product?.color_image && data.value.product?.relatedColor?.length > 0

  // 色块图
  const colorImage = showColorImg ? data.value.product?.color_image : ''
  // 其他销售属性
  const otherAttrs = []
  data.value.product?.sku_sale_attr?.forEach(item => {
    // 当存在色块图(color_image)时，颜色属性一定是主属性(is_main=1)
    if (showColorImg && item.is_main)  {
      otherAttrs.push(` ${item.attr_value_name}`)
    } else {
      otherAttrs.push(item.attr_value_name)
    }
  })

  // 是否显示蒙层
  let isShowMask = false
  // 是否可点击
  let isClick = true
  if (data.value.isCustomGood || data.value.isNewcomerItem || (data.value.isGiftItem && !data.value.is_checked)) {
    isShowMask = true
  }
  if (data.value.isNewcomerItem || isGiftOrAddItem) {
    isClick = false
  }

  // 是否隐藏箭头，赠品、加价购附属品隐藏
  const hideArrow = isGiftOrAddItem
  return {
    isShow: showReselect || showAttr,
    mode: showAttr ? 'attr' : 'reselect',
    isShowMask,
    isClick,
    hideArrow,
    reselectText: language.value.SHEIN_KEY_PC_16381,
    color: showAttr ? '#666' : '#222',
    fontWeight: showAttr ? 'normal' : 'bold',
    colorImage,
    goodsAttr: otherAttrs.join(' / ')
  }
}
