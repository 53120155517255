<script setup>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { computed, onMounted, provide, watch, nextTick, onBeforeMount } from 'vue'
import Skeleton from './components/Skeleton.vue'
import BusinessCartMask from './components/BusinessCartMask.vue'
import BusinessCartContent from './components/BusinessCartContent.vue'
import BusinessCartHeader from './components/BusinessCartHeader.vue'
import Group from './components/group/Group.vue'
import GroupHeader from './components/group/GroupHeader.vue'
import GroupEmpty from './components/group/GroupEmpty.vue'
import useBusinessCart from './hooks/useBusinessCart'
import BusinessCartItem from './components/BusinessCartItem.vue'
import BusinessCartItemDel from './components/BusinessCartItemDel.vue'
import Loading from './components/Loading.vue'
import BackTop from './components/BackTop.vue'
import theme from './theme'
import { scroll } from './utils/scroll'
import { DEFAULT_UI_CONFIG } from './constants.js'
import { preloadImgs } from '@shein/common-function'

import {
  selectGoodsReport,
  closeBCReport,
  goBackToAddReport,
  goBackToAddExposeReport,
  goodsExposeReport,
  pageViewExport,
  scrollTopReport,
  scrollTopExposeReport,
} from './utils/analysis'

daEventCenter.addSubscriber({ modulecode: '1-7-10' })

// events
const emit = defineEmits(['update:visible', 'cartUpdated', 'opened'])

// props
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  promotionId: {
    type: [Number, String],
    required: true,
  },
  theme: {
    type: String,
    default: 'default', // default 默认主题 ...
  },
  // 类型
  type: {
    type: String,
    default: 'promotion', // 优惠券coupon 免邮活动shipping 促销活动promotion
  },
  // 场景值
  state: {
    type: String,
    default: '',
  },
  themeVars: {
    type: Object,
    default: () => ({}),
  },
  uiConfig: {
    type: Object,
    default: () => ({}),
  },
  cartItemId: {
    type: String,
    default: ''
  }
})


// hooks
const option = computed(() => {
  return {
    promotionId: props.promotionId,
    autoUseCoupon: props.uiConfig.autoUseCoupon,
    autoUsePoints: props.uiConfig.autoUsePoints,
    cartUpdateCallBack: (info) => emit('cartUpdated', info),
  }
})
const {
  // variables
  language,
  groups,
  pageLoading,
  cartInfo,
  flashSaleOverLimit,
  loading,
  constant,
  isOpenDelete,
  deleteItemData,
  ABTParams,

  // methods
  getData,
  updateQty,
  modify,
  del,
  refetchData,
  autoChecked,
  openDelete,
  closeDelete,
  publicRefetchData,
} = useBusinessCart(option)

provide('updateQty', updateQty)
provide('modify', modify)
provide('del', del)
provide('refetchData', refetchData)
provide('constant', constant)
provide('autoChecked', autoChecked)
provide('openDelete', openDelete)
provide('closeDelete', closeDelete)

// computed
const themeVars = computed(() => {
  const _themeVars = theme[props.theme] || theme.default
  return Object.assign({}, _themeVars, props.themeVars)
})
const uiConfig = computed(() => ({
  ...DEFAULT_UI_CONFIG,
  ...props.uiConfig,
}))

// methods
const handleDelete = (item) => {
  del({
    id: item.id,
  })
}
const handleClose = ({ from }) => {
  emit('update:visible', false)
  
  if (from === 'mask' || from === 'content') {
    closeBCReport()
  }
  if (from === 'empty') {
    goBackToAddReport()
  }
}
const handleModifyAll = ({ checked, list, isPromotion }) => {
  let cart_id_list = []
  list.forEach(item => {
    cart_id_list = cart_id_list.concat([item.id, ...(item.appendIds || [])])
  })
  const operation_type = checked ? 1 : 2
  modify({ cart_id_list: Array.from(new Set(cart_id_list)), operation_type })
  selectGoodsReport({
    is_select: checked ? 0 : 1,
    select_type: isPromotion ? 4 : 5,
  })
}

const onAfterEnter = () => {
  emit('opened')
}

const handleItemExpose = ({ item, groupIndex, itemIndex }) => {
  // 获取当前商品在整个购物车中的索引
  const index = groupIndex === 0 ? itemIndex + 1 : itemIndex + groups.value[0].list.length + 1
  goodsExposeReport({
    item,
    index,
    ABTParams: ABTParams.value,
    constant
  })
}

onBeforeMount(() => {
  preloadImgs({
    imgs: [
      'https://img.ltwebstatic.com/images3_ccc/2024/06/20/a3/17188757422c7205911d9b042d667710ac81046e0c.png',
      'https://img.ltwebstatic.com/images3_ccc/2024/06/18/6f/1718693778169f79d9ddd7e21fe09c64073b451e94.png',
    ]
  })
})
onMounted(async () => {
  getData()
})

watch(() => props.visible, (val) => {
  if (val) {
    getData()
  }
})
watch(() => props.promotionId, (val) => {
  if (val) {
    getData()
  }
})
watch(() => pageLoading.value, async (val) => {
  if (!val) {
    await nextTick()
    const immediate = props.cartItemId ? false : true
    scroll(props.cartItemId, immediate)
    pageViewExport({
      type: props.type,
      state: props.state,
      promotion_code: props.promotionId,
    })
  }
})

defineExpose({
  publicRefetchData,
})
</script>

<template>
  <Transition name="sui-animation__drawer_none">
    <div
      v-show="visible"
      class="business-cart"
      :style="themeVars"
    >
      <Transition name="sui-animation-modal__dialog">
        <BusinessCartMask
          v-show="visible"
          @close="handleClose({ from: 'mask' })"
        />
      </Transition>
      <Transition
        name="slide"
        @after-enter="onAfterEnter"
      >
        <div
          v-if="visible"
          class="business-cart__container"
        >
          <Loading v-show="loading" />
          <Skeleton v-show="pageLoading" />
          <BusinessCartItemDel
            v-model:visible="isOpenDelete"
            :data="deleteItemData"
            :language="language"
            :ui-config="uiConfig"
            @confirm="handleDelete"
          />
          <BusinessCartContent
            v-if="!pageLoading"
            @close="handleClose({ from: 'content' })"
          >
            <template #header>
              <BusinessCartHeader
                :title="language.SHEIN_KEY_PC_29493"
                :question-mark="language.SHEIN_KEY_PC_29495"
              />
            </template>
            <template
              v-for="(group, groupIndex) in groups"
              :key="groupIndex"
            >
              <Group
                v-if="group.list.length > 0 || groupIndex === 0"
                class="business-cart__group"
                :data-promotion-group="groupIndex === 0"
              >
                <template #header>
                  <GroupHeader
                    :is-promotion="groupIndex === 0"
                    :is-empty="group.list.length === 0"
                    :is-checked="group.isChecked"
                    :title="group.title"
                    :number="group.total"
                    :total-price="group.amount"
                    @modify="(checked) => handleModifyAll({ checked, list: group.list, isPromotion: groupIndex === 0})"
                  />
                </template>
                <template v-if="group.list.length === 0">
                  <GroupEmpty
                    v-expose="goBackToAddExposeReport()"
                    :tip-text="language.SHEIN_KEY_PC_29497"
                    :button-text="language.SHEIN_KEY_PC_29498"
                    @close="handleClose({ from: 'empty' })" 
                  />
                </template>
                <template v-else>
                  <BusinessCartItem
                    v-for="(item, itemIndex) in group.list"
                    :key="item.id"
                    :data="item"
                    :language="language"
                    :ui-config="uiConfig"
                    :cart-info="cartInfo"
                    :flash-sale-over-limit="flashSaleOverLimit"
                    :data-cart-id="item.id"
                    class="business-cart__item j-business-cart__item"
                    :class="[
                      'business-cart__item',
                      `j-business-cart__item-${item.id}`
                    ]"
                    @expose="handleItemExpose({ item, groupIndex, itemIndex })"
                  />
                </template>
              </Group>
            </template>
          </BusinessCartContent>
        </div>
      </Transition>
      <BackTop
        v-if="!pageLoading && visible"
        v-expose="scrollTopExposeReport()"
        visibility-target="[data-promotion-group=false]"
        @click="scrollTopReport"
      />
    </div>
  </Transition>
</template>

<style lang="less" scoped>
.business-cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  &__group {
    .business-cart__item:last-child {
      padding-bottom: 16px;
    }
  }
  &__item {
    padding: 8px 16px
  }
  .business-cart__container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
  }
}

.slide-enter-active {
  transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.slide-leave-active {
  transition: transform 0.2s cubic-bezier(0.4, 0, 1, 1);
}
.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%);
}
</style>
