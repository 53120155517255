import GoodsSaleAttr from './GoodsSaleAttr.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsSaleAttr = createHelper('GoodsSaleAttr', getProps)

export {
  GoodsSaleAttr,
  useGoodsSaleAttr,
}
