import GoodsTitle from './GoodsTitle.vue'
import { getEvents, getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsTitle = createHelper('GoodsTitle', getProps, getEvents)

export {
  GoodsTitle,
  useGoodsTitle,
}
