<script setup>
import { ref, computed } from 'vue'
import BusinessCart from 'public/src/pages/common/business-cart/BusinessCart.vue'
import PreviewGoods from './PreviewGoods.vue'
import NumBadge from './NumBadge.vue'
const { IS_SUGGESTED } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  state: {
    type: String,
    default: '',
  },
  promotionId: {
    type: [Number, String],
    required: true,
  },
  language: {
    type: Object,
    default: () => ({})
  },
  addOnStatus: { // 凑单状态
    type: Number,
    default: null, //  0:全部未满足; 1:全部满足; 2:部分满足
  },
})
const emits = defineEmits(['cartUpdated'])

const visible = ref(false)
const popoverShow = ref(false)
const isFirst = ref(false)
const cartInfo = ref(null)
const groups = ref(null)
const timer = ref(null)
const cartItemId = ref(null)

const cartRef = ref(null)

const unCheckedGoodsList = computed(() => {
  return groups.value[0]?.list?.filter((v) => !v.is_checked) || []
})

const totalPrice = computed(() => {
  if (IS_SUGGESTED) return cartInfo.value?.total_price_before_coupon || {}
  return cartInfo.value?.totalPrice || {}
})
const totalDiscountPrice = computed(() => {
  return cartInfo.value?.total_discount_price || {}
})
const showDiscountPrice = computed(() => {
  return !IS_SUGGESTED && totalDiscountPrice.value.amount > 0
})
const cartCheckedNum = computed(() => {
  const checkedItems = cartInfo.value?.carts?.filter(item => item.is_checked == 1) || []
  return checkedItems.reduce((acc, cur) => {
    return acc + cur.quantity
  }, 0)
})

// 打开非标埋点
const openBusinessCartReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-4-18',
    extraData: {
      promotion_code: props.promotionId,
      state: props.state,
    },
  })
}

const previewGoodsReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-4-20',
    extraData: {
      promotion_code: props.promotionId,
      state: props.state,
    },
  })
}

// 打开非标购物车
const openBusinessCart = (cartItem) => {
  cartItemId.value = cartItem?.id
  closePopover()
  visible.value = true
  if (cartItem?.id) {
    previewGoodsReport()
  } else {
    openBusinessCartReport()
  }
}

const onCartUpdated = info => {
  cartInfo.value = info?.cartInfo || {} // 更新底部车数据
  groups.value = info?.groups || []

  // 首次获取非标车，判断是否弹出povover
  if (!isFirst.value) {
    isFirst.value = true
    handlePopover()
  }

  emits('cartUpdated', info)
}

const handlePopover = () => {
  // 未满足最高档且有未勾选商品
  if (props.addOnStatus !== 1 && unCheckedGoodsList.value.length) {
    popoverShow.value = true
    timer.value = setTimeout(() => {
      closePopover()
    }, 5000)
  }
}
const closePopover = () => {
  popoverShow.value = false
  clearTimeout(timer.value)
}

const refetchCarts = () => { // 刷新非标购物车
  closePopover() // 关闭气泡
  cartRef.value?.publicRefetchData?.()
}

defineExpose({
  refetchCarts,
})
</script>

<template>
  <div class="business-cart-foot">
    <div 
      v-expose="{id: '1-7-4-19', data: { promotion_code: promotionId, state: state }}"
      class="foot-bar"
      @click="openBusinessCart"
    >
      <s-popover
        v-model="popoverShow"
        placemen="top-start"
        theme="dark"
        show-close-icon
        :append-to-body="false"
        trigger="unset"
        :prop-style="{
          'margin-bottom': '12px',
          'padding': '10px',
          'max-width': '554px',
        }"
      >
        <div 
          class="unchecked-items" 
          @click.stop="openBusinessCart(unCheckedGoodsList[0])"
        >
          <PreviewGoods
            v-expose="{id: '1-7-4-21', data: { promotion_code: promotionId, state: state }}"
            :list="unCheckedGoodsList"
          />
          <div 
            class="unchecked-tips" 
            v-html="language.SHEIN_KEY_PC_30155"
          >
          </div>
          <div class="pick-btn">
            {{ language.SHEIN_KEY_PC_30858 }}
          </div>
        </div>
        <template #reference>
          <NumBadge
            :value="cartCheckedNum"
            :max="99"
          >
            <img
              src="https://img.ltwebstatic.com/images3_acp/2024/03/15/a4/1710507571e64bf0c57e6ee9e2f3321242b1cb00f3.png"
              width="40"
              height="40"
            />
          </NumBadge>
        </template>
      </s-popover>
      <div class="price">
        <p 
          class="total"
          :class="{
            'is-discount': showDiscountPrice
          }"
        >
          {{ totalPrice.amountWithSymbol }}
        </p>
        <p 
          v-if="showDiscountPrice"
          class="saved"
        >
          Saved <b>{{ totalDiscountPrice.amountWithSymbol }}</b>
        </p>
      </div>
      <div class="divider"></div>
    </div>
    <slot></slot>

    <BusinessCart
      ref="cartRef"
      v-model:visible="visible"
      class="addon-business-cart"
      :type="type"
      :state="state"
      :cart-item-id="cartItemId"
      :promotion-id="promotionId"
      :ui-config="{ 
        isShowNewUserPrice: false,
        // autoUseCoupon: false,
        // autoUsePoints: false,
        autoUseCoupon: false, // TODO
        autoUsePoints: false, // TODO
        allowDeleteGift: 1,
        repickAddon: 1,
      }"
      @cart-updated="onCartUpdated"
    />
  </div>
</template>

<style lang="less" scoped>
.business-cart-foot {
  position: relative;
  height: 56px;
  padding: 0 48px;
  border-top: 1px solid #EAEAEA;
  display: flex;
  align-items: center;
  z-index: 9;
  text-align: left;
  .addon-business-cart {
    bottom: 55px;
  }
  
  :deep(.sui-popover__content-arrow) {
    bottom: -9px !important; /* stylelint-disable-line declaration-no-important */
    left: 30px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.foot-bar {
  display: flex;
  align-items: center;
  .price {
    width: 120px;
    margin-left: 12px;
    .total {
      font-size: 15px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .is-discount {
      color: @sui_color_promo;
    }
    .saved {
      font-size: 10px;
      line-height: 12px;
      color: @sui_color_gray_dark3;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .divider {
    width: 1px;
    height: 24px;
    background-color: #E5E5E5;
    margin: 0 20px;
  }
}
.unchecked-items {
  display: flex;
  align-items: center;
  overflow: hidden;
  .unchecked-tips {
    width: 250px;
    margin: 0 16px 0 8px;
    font-size: 14px;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .pick-btn {
    cursor: pointer;
    width: 80px;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px;
    color: #fff;
    background-color: @sui_color_discount;
    text-align: center;
  }
}
</style>
