/**
 * EvoluTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant }) {
  return {
    isShow: data.value.isShowEvoluSHEIN && data.value.rules?.includes(constant.value.EVOLU_SHEIN_ID),
    isShowMask: data.value.isSoldOutItem || data.value.is_checked !== 1 && data.value.isGiftItem,
    text: 'evoluSHEIN',
    maxWidth: '100%',
  }
}

export function getEvents() {
  return {}
}
