<template>
  <div class="multiple-coupon-head">
    <p :class="titleClasses">
      {{ language.SHEIN_KEY_PC_30224 }}
    </p>
    <HeadViewSwiper
      v-show="couponListInfo.length"
      ref="HeadViewSwiper"
    >
      <HeadViewSlide
        v-for="couponInfo in couponListInfo"
        :key="couponInfo.id"
      >
        <CouponMultipleItem
          :language="language"
          :abt-info="abtInfo"
          :coupon-info="couponInfo"
          :is-selected="couponInfo.coupon_code === selectedCouponCode"
          @click="handleSelectCoupon(couponInfo)"
        />
      </HeadViewSlide>
    </HeadViewSwiper>
  </div>
</template>

<script>
import { HeadViewSwiper, HeadViewSlide } from '../../headViewSwiper'
import CouponMultipleItem from './coupon/CouponMultipleItem.js'

/**
 * @description 优惠券凑单-多优惠券-头部
 */
export default {
  name: 'MultipleCouponHead',

  components: {
    HeadViewSwiper,
    HeadViewSlide,
    CouponMultipleItem,
  },
  emits: ['selectedCoupon'],

  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    couponListInfo: {
      type: Array,
      default: () => ([]),
    },
    abtInfo: {
      type: Object,
      default: () => ({}),
    },
    // 主券码
    primaryCoupon: {
      type: String,
      default: '',
    },
    // 副券码
    secondaryCoupon: {
      type: Array,
      default: () => ([]),
    },
    // 是否已对副券码进行排序
    isSorted: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      hasInitSwiper: false,
      selectedCouponCode: '',
    }
  },
  computed: {
    titleClasses() {
      return {
        'multiple-coupon-head__title': true,
        'multiple-coupon-head__title-style-a': true
      }
    }
  },

  watch: {
    couponListInfo: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.initHeadViewSwiper()
        })
        const isNotSelect = !this.selectedCouponCode
        const isNotMached = !isNotSelect && !this.couponListInfo.find(item => item.coupon_code === this.selectedCouponCode)
        if (isNotMached) {
          this.$nextTick(() => {
            this.updateHeadViewSwiper()
          })
        }
        if (isNotSelect || isNotMached) {
          this.handleSelectCoupon(this.couponListInfo[0])
        }
      }
    }
  },


  methods: {
    /**
     * 选择优惠券
     */
    handleSelectCoupon(couponInfo) {
      if (couponInfo.coupon_code === this.selectedCouponCode) return
      const idx = this.couponListInfo.findIndex(item => item.coupon_code === couponInfo.coupon_code)
      this.$refs?.HeadViewSwiper?.swiper.slideTo(idx)
      this.selectedCouponCode = couponInfo.coupon_code
      this.$emit('selectedCoupon', couponInfo)
    },

    /**
     * 初始化头图滚动
     */
    initHeadViewSwiper() {
      if (this.hasInitSwiper) return
      this.$refs.HeadViewSwiper.initSwiper()
      this.hasInitSwiper = true
    },

    /**
     * 更新头图滚动
     */
    updateHeadViewSwiper() {
      this.$refs.HeadViewSwiper.updateSwiper()
    }
  }
}
</script>

<style lang="less" scoped>
.multiple-coupon-head {
  background: url(https://img.ltwebstatic.com/images3_acp/2024/03/07/4b/17097929553fe97a73d56de1aed20706fc26417a9c.png) no-repeat;
  background-size: cover;
  height: 136px;

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    padding-top: 12px;
    background-image: linear-gradient(180deg, #FFFFFF, #FFEACB 100%);
    background-clip: text;
    text-align: center;
    color: transparent;
    /* stylelint-disable-next-line selector-nested-pattern */
    &-style-a {
      padding-top: 12px;
      padding-bottom: 10px;
    }
  }
  &__skeleton {
    height: 84px
  }
}
</style>
