/**
 * ReducePriceHint 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant }) {
  return {
    fontColor: constant.value.IS_RW ? '#B40954' : '#C44A01',
    reduceAmount: data.value.reduce_price?.reduceAmount?.amountWithSymbol,
    isShow: !data.value.isSoldOutItem
      && !data.value.is_checked
      && data.value?.reduce_price?.siteFrom == constant.value.SiteUID
      && data.value?.reduce_price?.reduceAmount?.amount > 0
  }
}
