<template>
  <div
    v-show="progressData.length"
    class="coupon-progress"
  >
    <div 
      class="tips" 
      v-html="currentRuleTips"
    >
    </div>
    <div class="process-container">
      <div
        class="process-content"
      >
        <div class="process-start process-node">
          <div class="process-top place-holder"></div>
          <div class="process-center">
            <i class="process-icon"></i>
          </div>
          <div class="process-bottom">
            0
          </div>
        </div>
        <template
          v-for="(item, index) in progressData"
          :key="index+'bar'"
        >
          <div
            class="process-bar"
          >
            <div 
              class="process-bar-bg"
              :style="{ width: `${item.progressRate}%` }"
            ></div>
          </div>
          <div
            class="process-node"
            :class="{
              'success': item.progressRate >= 100
            }"
          >
            <div class="process-top">
              <span>{{ item.discountText }}</span>
            </div>
            <div class="process-center">
              <i
                class="process-icon"
              >
                <Icon
                  v-if="item.progressRate >= 100"
                  name="sui_icon_success_24px"
                  size="18px"
                />
              </i>
            </div>
            <div class="process-bottom">
              <span>{{ item.thresholdText }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getCouponDiscount, getCouponProgress } from '../../../utils/index'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'CouponProgress',
  components: {
    Icon,
  },
  props: {
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    progressData() {
      const { coupon_rule_list, _data } = this.couponInfo
      const { activeRuleIndex, activeRule } = _data || {}
      if (!Array.isArray(coupon_rule_list)) return []

      const is_0ThresholdCoupon = activeRuleIndex === 0 && parseFloat(activeRule.min) <= 0 // 判断是否为0门槛券且未选择任何商品

      return coupon_rule_list.map((rule, index) => {
        let progressRate = 0
        if (activeRuleIndex == -1) { // 未找到凑单档位代表全部已满足门槛
          progressRate = 100
        } else if (index == activeRuleIndex) { // 当前凑单档位
          const preRule = coupon_rule_list[index - 1]
          progressRate = is_0ThresholdCoupon ? 0 : getCouponProgress(rule, preRule) // 0门槛券时，文案与进度需要与其他券做区分
        } else { // 小于当前档位进度为100，反之为0
          progressRate = index < activeRuleIndex ? 100 : 0
        }

        return {
          couponRangeId: rule.coupon_range_id, // 档位ID
          thresholdText: rule.min_amount?.amountWithSymbol, // 门槛
          discountText: template(getCouponDiscount(rule), this.language.SHEIN_KEY_PC_25225), // 折扣
          progressRate, // 凑单进度
        }
      })
    },
    currentRuleTips() {
      if (!this.couponInfo?._data) return ''
      const { activeRule, activeRuleIndex, preRule } = this.couponInfo._data
      const is_0ThresholdCoupon = activeRuleIndex === 0 && parseFloat(activeRule.min) <= 0 // 判断是否为0门槛券且未选择任何商品

      if (activeRuleIndex == -1) {
        return template(getCouponDiscount(preRule), this.language.SHEIN_KEY_PC_27079)
      } else if (activeRuleIndex == 0) {
        // 0门槛券时，文案需要与其他券做区分
        return is_0ThresholdCoupon
          ? template(getCouponDiscount(this.couponInfo?.coupon_rule_list[0]), this.language.SHEIN_KEY_PC_30899)
          : template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, getCouponDiscount(activeRule), this.language.SHEIN_KEY_PC_27077)
      }

      return template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, getCouponDiscount(preRule), getCouponDiscount(activeRule), this.language.SHEIN_KEY_PC_27078)
    },
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-max-specificity */
.coupon-progress {
  font-family: Arial;
  padding: 12px 16px 10px;
  background-color: @sui_color_promo_bg;
  text-align: center;
  .tips {
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    :deep(em) {
      color: @sui_color_highlight;
    }
  }

  .process-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 11px;
    font-weight: normal;
    .process-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 0 12px;
      z-index: 1;
    }
    .process-node {
      .process-top.place-holder {
        height: 13px;
        line-height: 13px;
      }
      .process-top, .process-bottom {
        line-height: 13px;
        height: 13px;
        position: relative;
        span {
          position: absolute;
          transform: translateX(-50%);
          max-width: 24vw;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .process-center {
        width: 52px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .process-node:not(.success) {
      color: #BBB;
      .process-icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #BBB;
      }
    }
    .process-node.success {
      color: @sui_color_discount;
      .process-icon {
        font-size: 18px;
      }
      .process-icon::before {
        border-radius: 50%;
        font-size: 18px;
        color: @sui_color_discount;
      }
    }
    .process-node.process-start {
      color: @sui_color_discount;
      .process-icon {
        background-color: @sui_color_discount;
      }
    }
    .process-bar {
      flex: 1;
      height: 2px;
      background-color: #BBB;
      border-radius: 1px;
      min-width: 10px;
      max-width: 400px;
      .process-bar-bg {
        height: 100%;
        background-color: @sui_color_discount;
        border-radius: 1px;
        transition: width .5s linear;
      }
    }
  }
  .process-container::-webkit-scrollbar {
    display: none;
  }
}
</style>
