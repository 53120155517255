<template>
  <div
    v-if="isShow"
    :style="styles"
    class="goods-tag"
    @click="handleClick"
  >
    <div class="goods-tag__item">
      {{ unitDiscount }}
    </div>
    <div
      v-if="isShowSaleDiscount"
      class="goods-tag__item"
    >
      {{ saleDiscount }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

/**
 * 商品行商品标签组件
 */

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示遮罩
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 背景颜色
   * @type {String}
   */
  bgColor: {
    type: String,
    default: '',
  },
  /**
   * 字体颜色
   * @type {String}
   */
  fontColor: {
    type: String,
    default: '',
  },
  /**
   * 单项折扣内容
   * @type {String}
   */
  unitDiscount: {
    type: String,
    default: '',
  },
  /**
   * 特价折扣
   * @type {String}
   */
  saleDiscount: {
    type: String,
    default: '',
  },
  /**
   * 是否展示特价折扣
   * @type {Boolean}
   */
  isShowSaleDiscount: {
    type: Boolean,
    default: false,
  },
})

// computed
const styles = computed(() => ({
  '--bg-color': props.bgColor,
  '--font-color': props.fontColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
</script>

<style lang="less" scoped>
.goods-tag {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: var(--bg-color);
  color: var(--font-color);
  /*sh:begin*/
  margin-top: 5px;
  /*rw:begin*/
  padding: 0 2px;
  margin-top: 0;
  font-family: Adieu-Bold, Adieu;
  font-weight: bold;
  opacity: var(--mask-opacity);
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 18px;
    flex-shrink: 0;
    &:nth-child(2) {
      text-decoration: line-through;
      opacity: .8;
    }
  }
}
</style>
