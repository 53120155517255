import SimilarPopover from 'public/src/pages/common/similar_products/index.js'

/**
 * FindSimilarIcon 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  return {
    isShow: !data.value.isNewcomerItem && data.value.isOnSaleSimilarShow,
  }
}

export function getEvents() {
  return {
    /**
     * 点击
     * @param {Object} data - 商品行数据
     * @param {Object} props - 当前组件 props
     * @param {Object} constant - 常量配置
     */
    onClick({ data }){
      SimilarPopover.showModal(data.value.product)
    },
  }
}
