<script setup>
import { SButton } from '@shein-aidc/sui-button'
defineProps({
  tipText: {
    type: String,
    default: ''
  },
  buttonText: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['close'])
const handleClick = () => {
  emit('close')
}
</script>

<template>
  <div class="bc-group-empty">
    <img
      class="bc-group-empty__img"
      src="https://img.ltwebstatic.com/images3_ccc/2024/06/18/52/171870987928e3ad0a04a88b4a20ff59911adaa13f.png"
    />
    <p class="bc-group-empty__text">
      {{ tipText }}
    </p>
    <SButton
      :type="['primary', 'H28PX']"
      @click="handleClick"
    >
      {{ buttonText }}
    </SButton>
  </div>
</template>

<style lang="less" scoped>
.bc-group-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 212px;
  .bc-group-empty__img {
    margin-top: 30px;
    width: 75px;
    height: 75px;
  }
  .bc-group-empty__text {
    margin: 4px 0 10px;
    padding: 0 16px;
    width: 100%;
    color: #222;
    text-align: center;
    font-family: "SF UI Text";
    font-size: 12px;
  }
}
</style>
