import { template } from '@shein/common-function'
import { SToast } from '@shein-aidc/sui-toast'

/**
 *
 * @param {Object} item 商品行对象
 * @returns 筛选符合app评判表准的新人用户专享价, 命中则返回item中的新人专享价简略信息
 */
export function getPromotionInfo4AppNewComerItem(item = {}) {
  return item?.product?.product_promotion_info?.find(promo => promo.type_id == 31 && promo.promotion_logo_type == 1)
}

export function getPromotionDiscounts(item = {}, language = {}, integrityPromotionInfo = {}, flashSaleGroupList = []) {
  const source = item?.promotion_discount || []
  const showDetailTipTids = [1, 9, 14, 15, 16, 17, 18, 19, 25, 26]
  const showTitleTipTids = [2, 3, 4, 8, 10, 11, 12, 13, 22, 24, 28, 29, 30, 33]
  const titleLangMap = {
    '2': language.SHEIN_KEY_PC_15751,
    '3': language.SHEIN_KEY_PC_17697,
    '4-0': language.SHEIN_KEY_PC_15751,
    '4-8': language.SHEIN_KEY_PC_30894,
    '8': language.SHEIN_KEY_PC_17698,
    '10-1': language.SHEIN_KEY_PC_15950, // 常规闪购
    '10-2': language.SHEIN_KEY_PC_23050, // 新人闪购
    '10-3': language.SHEIN_KEY_PC_23052, // 特殊闪购
    '10-4': language.SHEIN_KEY_PC_27297, // 分人群专区闪购
    '10-5': language.SHEIN_KEY_PC_27297, // 不分人群专区闪购
    '10-6': language.SHEIN_KEY_PC_29870, // 品牌特卖
    '11': language.SHEIN_KEY_PC_15950,
    '12': language.SHEIN_KEY_PC_17699,
    '13': language.SHEIN_KEY_PC_15966,
    '22': language.SHEIN_KEY_PC_15966,
    '24': language.SHEIN_KEY_PC_17701,
    '28': language.SHEIN_KEY_PC_15751,
    '29': language.SHEIN_KEY_PC_22977 + ':',
    '30': item.isUnderPrice ? language.SHEIN_KEY_PC_24789 : language.SHEIN_KEY_PC_24790
  }

  const res = []
  source.forEach(item => {
    const id = item.promotion_id
    const tid = +item.promotion_type

    if (tid == 31) {
      if(item.promotion_logo_type == 3) {
        res.push({
          ...item,
          promotionName: language.SHEIN_KEY_PC_27730,
        })
      } else if(item.promotion_logo_type != 1 && item.promotion_logo_type != 3) {
        res.push({
          ...item,
          promotionName: language.SHEIN_KEY_PC_28264,
        })
      } else { // app专属新人专享价
        res.push({
          ...item,
          promotionName: language.SHEIN_KEY_PC_27122,
        })
      }
    }

    // 保价的活动id，活动类型，活动标识均为null TODO
    if(item.promotion_id === null && item.promotion_type === null && item.promotion_logo_type === null) {
      res.push({
        ...item,
        promotionName: language.SHEIN_KEY_PC_23163
      })
    }

    const isShowDetailTip = showDetailTipTids.includes(tid)
    const isShowTitleTip = showTitleTipTids.includes(tid)
    if (!isShowDetailTip && !isShowTitleTip) return

    if (showDetailTipTids.includes(tid)) {
      let promotionInfoContent = integrityPromotionInfo[id]?.content || {}
      if (promotionInfoContent.tips) {
        const tipsIndex = promotionInfoContent.tipsIndex
        const tipsList = promotionInfoContent.tips.list
        res.push({
          ...item,
          promotionName: tipsList[tipsIndex] || ''
        })
      }
    } else if (showTitleTipTids.includes(tid)) {
      // 闪购活动
      if (tid === 10) {
        let promotionName = ''
        let promotionTip = ''
        const { flash_type } = integrityPromotionInfo[id]
        if ([4, 5].includes(flash_type) && item?.promotionTag?.typeIdText && item?.promotionTag?.promotionId === id) {
          // 要求与商品行展示的活动名称一致
          promotionName = item?.promotionTag?.typeIdText
        } else {
          promotionName = titleLangMap[`${tid}-${flash_type}`]
        }
        // 超限购买提示
        if(flashSaleGroupList[id] && integrityPromotionInfo[id]?.limit_mode == 1){ // 闪购活动且支持超限原价购买
          if (item?.is_checked == 1) {
            const promo = item?.product?.product_promotion_info?.find(f => f.promotion_id === id)
            if (promo && promo.single_over == 1 || promo.member_over == 1) {
              promotionTip = language.SHEIN_KEY_PC_24348
            }
          }
        }
        res.push({
          ...item,
          promotionName,
          promotionTip,
        })
      } else if (tid === 33) {
        let catNameMulti = integrityPromotionInfo[id]?.category_info?.[0]?.cat_name_multi || ''
        let promotionName = ''
        if(catNameMulti) promotionName = template(catNameMulti, language.SHEIN_KEY_PC_30878)
        else promotionName = language.SHEIN_KEY_PC_30873
        res.push({
          ...item,
          promotionName,
        })
      } else if(tid === 4) { // 全场满赠（全场满赠、付费会员权益礼物）
        const { promotion_logo_type } = integrityPromotionInfo[id]
        // typeId=4都是增品，所以用free gift做文案兜底
        const promotionName = titleLangMap[`${tid}-${promotion_logo_type}`] || titleLangMap[`${tid}-0`]
        res.push({
          ...item,
          promotionName
        })
      } else {
        res.push({
          ...item,
          promotionName: titleLangMap[tid]
        })
      }
    }
  })

  return res
}

export function getCouponDiscounts(item = {}, language = {}) {
  let res = item?.per_coupon_discount || []
  res = res.map(item => ({
    ...item,
    showLangText: true,
  }))

  const specialCoupon = item?.promotion_discount?.find?.(p => p.promotion_type == 32)
  if (specialCoupon) {
    // 将活动作为优惠券传入并按价格从高到低排序
    res.push({
      ...specialCoupon,
      showLangText: false,
      coupon_code: language.SHEIN_KEY_PC_27371,
    })
    res.sort((a, b) => (b?.single_discount_value?.amount || 0) - (a?.single_discount_value?.amount || 0))
  }
  return res
}

/**
 * @description: 
 * @param {Array<CartItem>} cartList 商品列表
 * @param {Array<number>|Function} classify 分类标准, 可能存在多个id属于同一类, 也支持定制判断函数function, 其中function类型入参为item, 返参为从product_promotion_info中找到的一个promotion info
 * @return {*} 同一类活动可能同时存在多场, 因此根据promotion_id, 分成多组聚合输出
 */
export function getPromoGroupList(cartList = [], classify = []) {
  let classifyFun = null
  if(typeof classify == 'function') classifyFun = classify
  else if (Array.isArray(classify)) classifyFun = item => item.product?.product_promotion_info?.find(i => classify.includes(i.type_id))
  else console.error('classify must be array or function')
  
  let result = {} // 同一类活动可能同时存在多场, 需要根据promotionId进行区分
  cartList.forEach(item => {
    let promoInfo = classifyFun(item)
    if (promoInfo) {
      if(!result[promoInfo.promotion_id]){
        result[promoInfo.promotion_id] = []
      }
      result[promoInfo.promotion_id].push(item)
    }
  })
  return result
}

export function promotionGroupInfo({ item, cartList, integrityPromotionInfo, itemPromotionGroupInfo, cartNeedGroupPromotionTypeIds, underPriceGroupList, flashSaleGroupList }) {
  let groupPromotion = item.product.product_promotion_info?.find((promotion) =>
    [10, 11, 30].includes(Number(promotion.type_id))
  ) // 常规闪购活动支持跨店铺or一口价
  if (groupPromotion) {
    let groupName = [10, 11].includes(groupPromotion.type_id)
      ? flashSaleGroupList
      : underPriceGroupList
    let groupPro = integrityPromotionInfo[groupPromotion.promotion_id] || {}
    return { ...groupPro, list: groupName[groupPromotion.promotion_id] || [] }
  }
  if (itemPromotionGroupInfo?.list) return itemPromotionGroupInfo

  let needGroupPromo = item.product.product_promotion_info?.find(
    (promotion) =>
      cartNeedGroupPromotionTypeIds.includes(Number(promotion.type_id)) ||
      promotion.type_id == 31
  )
  if (needGroupPromo) {
    let groupPro = integrityPromotionInfo[needGroupPromo.promotion_id] || {}
    if (item.isNewComerItem4App) {
      return {
        ...groupPro,
        list: getPromoGroupList(cartList, (item) => {
          return getPromotionInfo4AppNewComerItem(item)
        })?.[needGroupPromo.promotion_id],
      }
    }
    return {
      ...groupPro,
      list: getPromoGroupList(cartList, [needGroupPromo.type_id])?.[
        needGroupPromo.promotion_id
      ],
    }
  }
  return {}
}

export function appendTag(cartItem, promotion) {
  // 新人专享价活动 pageId=1178829727
  // 新人专享价pwa端与app端判断条件不一致, 并且展示样式与逻辑也不通, 因此拆分为两个字段
  if (cartItem.status == 2 && cartItem.promotion_status == 12) { // 新人专享价pwa
    cartItem.isNewcomerItem = true
    cartItem.isDisabled = true
  }
  if (getPromotionInfo4AppNewComerItem(cartItem)) { // 新人专享价app
    cartItem.isNewComerItem4App = true
  }
  if (promotion.type_id == 22) cartItem.isAddBuyAllItem = promotion.promotion_product_type == '2'
  if (promotion.type_id == 13) cartItem.isAddBuyItem = promotion.promotion_product_type == '2'
  if (promotion.type_id == 2) cartItem.isBuyGiftItem = promotion.promotion_product_type == '1'
  if (promotion.type_id == 4) {
    const bool = promotion.promotion_product_type == '1'
    if (promotion.promotion_logo_type === 8) {
      cartItem.isSheinClubGiftItem = bool
    } else {
      cartItem.isFullGiftItem = bool
    }
  }
  if (promotion.type_id == 28) cartItem.isPartFullGiftItem = promotion.promotion_product_type == '1'

  cartItem.isAddItem = Boolean(cartItem.isAddBuyAllItem || cartItem.isAddBuyItem)
  cartItem.isGiftItem = Boolean(cartItem.isBuyGiftItem || cartItem.isSheinClubGiftItem || cartItem.isFullGiftItem || cartItem.isPartFullGiftItem)
  // 特殊商品禁用 checkbox
  cartItem.isDisabled = cartItem.isDisabled || cartItem.isAddItem || cartItem.isGiftItem
}

export const handleFlashSaleOverLimit = ({
  flashSaleOverShowType,
  flashSaleOverLimit,
  flashSaleGroupList,
  integrityPromotionInfo,
  language,
}) => {
  let res = {}
  let flashSaleGroupListCarts = []
  Object.keys(flashSaleGroupList).forEach((groupListKey) => {
    if (integrityPromotionInfo[groupListKey]?.limit_mode == 1) {
      flashSaleGroupListCarts = flashSaleGroupListCarts.concat(flashSaleGroupList[groupListKey])
    }
  })
  Object.keys(flashSaleOverLimit).forEach((cartId) => {
    if (!flashSaleGroupListCarts.find((cart) => cart.id == cartId)) {
      delete flashSaleOverLimit[cartId]
    }
  })
  flashSaleGroupListCarts.forEach((item) => {
    if (flashSaleOverLimit[item.id]) {
      res[item.id] = {
        ...flashSaleOverLimit[item.id],
        isChecked: item.is_checked == 1 ? true : false,
        overType:
          item.normalFlashSale?.single_over == 1
            ? 'singleOver'
            : item.normalFlashSale?.member_over == 1
              ? 'memberOver'
              : '',
      }
    } else {
      res[item.id] = {
        cartItemId: item.id,
        showType: flashSaleOverShowType,
        overType:
          item.normalFlashSale?.single_over == 1
            ? 'singleOver'
            : item.normalFlashSale?.member_over == 1
              ? 'memberOver'
              : '',
        alreadyShow: false,
        showStatus: false,
        timmer: null,
        isChecked: item.is_checked == 1 ? true : false,
        timeFn: function () {
          this.alreadyShow = true
          this.showStatus = true
          if (
            this.overType == 'memberOver' ||
            (this.overType == 'singleOver' && this.showType == 'toast')
          ) {
            SToast({ content: language.SHEIN_KEY_PC_24348, duration: 3000 })
            return
          }
          if (this.timmer) window.clearTimeout(this.timmer)
          this.timmer = setTimeout(() => {
            this.showStatus = false
            this.timmer = null

            if (flashSaleOverLimit[this.cartItemId]) {
              flashSaleOverLimit[this.cartItemId].showStatus = false
              flashSaleOverLimit[this.cartItemId].timmer = null
            }
          }, 5000)
        },
      }
    }
  })

  return res
}

export const handleFlashSaleOverScene = ({
  editCartId,
  carts,
  originCarts,
  flashSaleOverLimit,
}) => {
  let originCartList = originCarts || []
  let lastCartItemIsOver = false // 编辑前是否超限
  let cartItemIsOver = false // 编辑后是否超限
  let flashSaleOverLimitTarget = flashSaleOverLimit[editCartId] || null
  if (flashSaleOverLimitTarget) {
    let originCartItem = originCartList.find((item) => item.id == editCartId)
    let cartItem = carts.find((item) => item.id == editCartId)
    if (originCartItem && cartItem) {
      lastCartItemIsOver =
        originCartItem.is_checked == 1 &&
        originCartItem.product.product_promotion_info?.some(
          (promo) => promo.single_over == 1 || promo.member_over == 1
        )
      cartItemIsOver =
        cartItem.is_checked == 1 &&
        cartItem.product.product_promotion_info?.some(
          (promo) => promo.single_over == 1 || promo.member_over == 1
        )
      if (
        (!lastCartItemIsOver && cartItemIsOver) ||
        (!flashSaleOverLimitTarget.alreadyShow &&
          lastCartItemIsOver &&
          cartItemIsOver)
      ) {
        // 非超限态到超限态或者超限态到超限态未弹窗过
        flashSaleOverLimitTarget.timeFn()
      } else if (lastCartItemIsOver && !cartItemIsOver) {
        // 超限态到非超限态 清状态缓存
        flashSaleOverLimitTarget.alreadyShow = false
        flashSaleOverLimitTarget.showStatus = false
      }
    }
  }
}
