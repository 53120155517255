import { isValidCartItem } from '@/public/src/pages/cart_new/utils/index.js'

export default function isReselect({ data, constant }) {
  // 售罄商品有效（当前尺码售罄，其他尺码有货）
  const isValid = isValidCartItem(data.value, constant.value.IS_PLATFORM)
  // 是否赠品/加价购附属品
  const isGiftOrAddItem = data.value.isGiftItem || data.value.isAddItem
  
  /**
   * 商品是否可以重选
   * 商品售罄：
   *    1. 有效商品（当前尺码售罄，其他尺码有货）&& 非赠品/加价购附属品 && 非新人商品
   *    2. 付费会员赠品 && 付费会员权益存在
   */
  let showReselect = false
  if (data.value.isSoldOutItem) {
    showReselect = (isValid && !isGiftOrAddItem && !data.value.isLoseNewcomerItem) || (data.value.isSheinClubGiftItem && !data.value.isInvalid)
  } 
  return showReselect
}
