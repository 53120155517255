<template>
  <div class="cart-addItem-list">
    <div
      class="cart-addItem-list__inner j-da-event-box"
    >
      <SItemV3
        v-for="(item, index) in products"
        :key="index"
        :class="[
          'j-da-event-box',
          'cart-addItem-list__item',
          {
            'cart-addItem-list__item-hide': !item.goods_id,
          },
        ]"
        :index="index"
        :item="dealWithItem(item)"
        :goods-item-info="goodsItemInfo"
        :config="v3Config"
        :constant-data="constantData"
        data-module="module"
        :data-poskey="sellingPointData.abtest"
        data-style="popup"
        :language="language"
        :data-active-from="sellingPointData.activity_from"
        :data-abtest="sellingPointData.abtest"
        :bottom-info-style="{ minHeight: '60px' }"
        @click-item="handleItem"
        @click-add-to-bag="clickAddToBag"
        @open-quick-add="clickAddToBag"
        @add-bag-success="addBagSuccess"
      />
    </div>

    <div 
      v-if="total && products.length" 
      class="cart-addItem-list__pages"
    >
      <s-pagination
        :total-pages-lang="language.SHEIN_KEY_PC_18002"
        :label-total="language.SHEIN_KEY_PC_18002"
        :current-page="page"
        :nextmove="false"
        :total="total"
        :page-size="limit"
        align="right"
        @page-change="$emit('pageChange', $event)"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import itemMixin from '@/public/src/pages/components/productItem/mixin'
import { getSatisfyFreeShipping } from '@/public/src/pages/cart_new/utils/getSatisfyFreeShipping.js'
import { SMessage  } from '@shein-aidc/sui-message'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-7-4' })
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { getAddSourceByType } from '../utils'

let QuickViewPopover = null
const { forceHttp } = gbCommonInfo

export default {
  name: 'CartAddItemList',
  components: {
    SItemV3: defineAsyncComponent(() => import('public/src/pages/components/productItemV3/MultipleImageRowCard.vue')),
  },
  directives: { tap, expose },
  mixins: [itemMixin],
  props: {
    itemConfig: {
      type: Object,
      default: () => ({}),
    },
    products: {
      type: Array,
      default: () => [],
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    lockmall: {
      type: Boolean,
      default: false,
    },
    isClickToDetail: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    sa: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      goodsItemInfo: {},
    }
  },
  computed: {
    sellingPointData() {
      const { activity_from, style, abtest } = this.sa || {}
      return {
        abtest,
        page: this.page,
        activity_from,
        style,
      }
    },
    v3Config() {
      return {
        ...this.itemConfig,
        // style: {
        //   addBagBtnStyle: 'AddText',
        // }
      }
    }
  },
  async created() {
    const language = (await i18n.loadByQueue('item')) || {}
    this.goodsItemInfo = new GoodsItemInfo({
      listLanguage: language,
    })
  },
  methods: {
    handleItem(item) {
      if(this.isClickToDetail) {
        let url = `${forceHttp}${this.getGoodsUrl(item?.goods_url_name, item?.goods_id, item?.cat_id)}`
        url += `?imgRatio=${item.imgRatio || '3-4'}`
        if (item.mall_code) url += `mallCode=${item.mall_code}`
        window.open(url, '_blank')
      } else {
        this.quickViewPopover(item)
      }
    },

    clickAddToBag({ target, goodsItem = {}, index, imgRatio }) {
      this.quickViewPopover({ ...goodsItem, target, index, imgRatio })
    },
    addBagSuccess() {
      this.$emit('updateCart')
    },
    quickViewPopover({ goods_id, index, mall_code, target, imgRatio }) {
      const { activity_from } = this.sa || {}
      const quickViewData = {
        goodsId: goods_id,
        mallCode: mall_code,
        imgRatio,
        lockmall: this.lockmall,
        analysis: {
          pageType: 'cartPage',
          sourceTarget: target,
          index,
          sa: {
            code: 'cartAddItem',
            activity_from,
          },
        },
        extendForm: null, // 尺码配置
        config: {
          isUpdateButton: false,
          hideViewFullDetail: true, // 隐藏商详
          hideWishlistBtn: false, // 不展示收藏功能按钮
          showEstimatedPrice: false, // 凑单弹窗隐藏到手价
          customSubmitParam: {
            add_source: getAddSourceByType(this.type, this.sa), // 页面来源
          },
          isBuyNow: this.config?.isBuyNow ?? false,
          casualCheckoutNo: this.config?.casualCheckoutNo ?? ''
        },
        callback: {
          handleAddToCartDone: (res) => {
            if (res && res.code == 0) {
              SMessage({
                offset: 136,
                type: 'success',
                message: this.language.SHEIN_KEY_PC_17621,
              })
              window.scrollTo({
                top: 0,
                behavior: 'smooth'  // 可选，平滑滚动到顶部
              })
              window.cartVue?.updateInfo(res)
              window.cart_module && window.cart_module.recart(res)
              this.$emit('updateCart', res)
            }
          },
          handleOpenLogin: (addWishFn) => {
            const store = window.cartVue?.$store
            let promotion_price
            let free_shipping
            if (store) {
              if (Number(store.state.cartInfo?.total_discount_price?.amount) > 0) {
                promotion_price = store.state.cartInfo.total_discount_price.amountWithSymbol
              }
              free_shipping = store.state.cartList?.length && getSatisfyFreeShipping()
            }
            SHEIN_LOGIN?.show({
              cb: () => {
                addWishFn?.()
              },
              from: 'wishlist',
              retainData: {
                promotion_price,
                // 空购物车 不免邮
                free_shipping,
              },
            })
          },
        },
      }
      
      if (QuickViewPopover) {
        QuickViewPopover.show(quickViewData)
        return
      }

      import('public/src/pages/common/quickView').then(_ => {
        QuickViewPopover = _.default
        QuickViewPopover.show(quickViewData)
      })
    },

    getAnlysis(id, { item, index }) {
      const { page, activity_from, style, abtest } = this.sellingPointData
      if (!item || !item.goods_id) return
      return {
        id,
        code: 'cartAddItem',
        data: {
          abtStr: abtest,
          index,
          item,
          page,
          activity_from,
          style,
          behaviorText: BehaviorLabel.getSaText(item?.showBehaviorLabel, this.language),
        },
      }
    },
    getGoodsUrl,
    dealWithItem(item) {
      if (item?.showBehaviorLabel) {
        const { iconSrc } = item.showBehaviorLabel
        item.pretreatInfo.userBehaviorLabel = {
          labelLang: BehaviorLabel.getText(item.showBehaviorLabel, this.language),
          icon: iconSrc,
          fontColor: '#A86104',
        }
      }

      return item
    },
  },
  emits: ['pageChange', 'updateCart'],
}
</script>

<style lang="less">
.cart-addItem-list {
  width: calc(100% + 16px);
  margin: 16px 0 8px -8px;
  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin: 0 8px 30px 8px;
    font-size: 12px;
    width: calc(20% - 16px);
    overflow: hidden;
    a {
      text-decoration: none;
      cursor: pointer;
    }
    i {
      cursor: pointer;
    }
  }

  &__item-hide {
    opacity: 0;
  }
}
</style>

<style lang="less" scoped>
.cart-addItem-list__inner .cart-addItem-list__item {
  margin-bottom: 10px;
}
</style>
