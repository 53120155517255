import FindSimilarIcon from './FindSimilarIcon.vue'
import { getEvents, getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useFindSimilarIcon = createHelper('FindSimilarIcon', getProps, getEvents)

export {
  FindSimilarIcon,
  useFindSimilarIcon,
}
