<template>
  <div
    v-if="isShow"
    :style="styles"
    :class="classes"
  >
    <div class="goods-belt__content">
      {{ beltText }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

/**
 * 商品行腰带组件
 */

// props
const props = defineProps({
  /**
   * 是否展示遮罩
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 背景颜色
   * @type {String}
   */
  bgColor: {
    type: String,
    default: '',
  },
  /**
   * 字体颜色
   * @type {String}
   */
  fontColor: {
    type: String,
    default: '',
  },
  /**
   * 腰带文字
   * @type {String}
   */
  beltText: {
    type: String,
    default: '',
  },
  /**
   * 尺码
   * @type {String}
   */
  size: {
    type: String,
    default: 'NORMAL',
    validator(val) {
      return ['NORMAL', 'SMALL'].includes(val)
    }
  }
})

// data
// computed
const isShow = computed(() => !!props.beltText)

const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
    '--bg-color': props.bgColor,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const classes = computed(() => {
  return {
    'goods-belt': true,
    'goods-belt_normal': props.size === 'NORMAL',
    'goods-belt_mini': props.size === 'SMALL',
  }
})
</script>

<style lang="less" scoped>
.goods-belt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  color: var(--font-color);
  padding: 0 4px;
  width: 100%;
  opacity: var(--mask-opacity);
  &_normal {
    height: 20px;
    font-size: 12px;
    font-weight: 700;
  }
  &_mini {
    height: 17px;
    font-size: 10px;
  }
  &__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
