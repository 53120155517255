/**
 * 与接口相关的工具函数
 */
import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'



export const getLanguage = ({ page }) => {
  return schttp({ 
    url: '/api/common/language/get', 
    params: { page }
  })
}

// 查车
let abortController
export const getCart = ({ autoUseCoupon, autoUsePoints }) => {
  abortController?.abort()
  abortController = new SchttpAbortCon()
  const params = {
    autoUseCoupon,
    autoUsePoints,
  }
  return schttp({
    url: '/api/cart/checkcart/get',
    method: 'GET',
    params,
    signal: abortController?.signal
  })
}

// 获取券数据
export const getCouponInfo = (data) => {
  return schttp({
    url: '/api/coupon/couponCalculateDiff/get',
    method: 'POST',
    data,
  })
}

// 更新商品行数量
// let updateAbortController
export const updateCartItemQuantity = ({ id, quantity, autoUseCoupon, autoUsePoints }) => {
  // updateAbortController?.abort()
  // const AbortController = abort()
  // updateAbortController = new AbortController()

  const data = {
    id,
    quantity,
    autoUseCoupon,
    autoUsePoints,
  }
  return schttp({
    url: '/api/cart/update',
    method: 'POST',
    data,
  })
}

// 勾选/取消勾选商品行
export const modifyCartCheckStatus = ({ cart_id_list, operation_type, autoUseCoupon, autoUsePoints }) => {
  const data = {
    cart_id_list,
    operation_type,
    autoUseCoupon,
    autoUsePoints,
  }
  return schttp({
    url: `/api/cart/modifyCartCheckStatus/update`,
    method: 'POST',
    data,
  })
}

// 删除商品行
export const deleteCartItem = ({ id, autoUseCoupon, autoUsePoints }) => {
  const data = {
    id,
    autoUseCoupon,
    autoUsePoints,
  }
  return schttp({
    url: '/api/cart/delete',
    method: 'POST',
    data,
  })
}

export const getMallInfo = () => {
  return schttp({
    method: 'POST',
    url: '/api/common/mallInfo/post'
  })
}


export const getQsTimelinessInfo = async () => {
  const addressCookie = getLocalStorage('addressCookie')
  let addressInfo = {}
  
  // 优先取地址缓存的，拿不到再调查默认地址接口
  try {
    const addressJson = typeof addressCookie === 'string' ?  JSON.parse(addressCookie) : addressCookie
    if (addressJson?.countryId) {
      addressInfo = Object.assign({}, addressJson)
    } else {
      const defaultAddress = await schttp({
        url: '/api/cart/getDefaultAddress/get',
        method: 'GET',
      })
      addressInfo = Object.assign({}, defaultAddress?.info)
    }
  } catch (err) {
    console.log('[cart, getQsTimelinessInfo] 获取countryId失败: ' + err)
  }

  try {
    const qsTimelinessInfo = await schttp({
      url: '/api/common/qsTimelinessInfo/get',
      method: 'POST',
      data: { ...addressInfo }
    })
    return qsTimelinessInfo
  } catch (err) {
    console.log('[cart, getQsTimelinessInfo] 获取qs信息失败: ' + err)
    return {}
  }
}

