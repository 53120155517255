import BehaviorLabel from './BehaviorLabel.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useBehaviorLabel = createHelper('BehaviorLabel', getProps)

export {
  BehaviorLabel,
  useBehaviorLabel,
}
