<template>
  <s-dialog
    v-model:visible="show"
    :show-close="true"
    :append-to-body="true"
    type="W996"
    class="add-on-dialog coupon-add-buys"
  >
    <template #title>
      <div class="head-no-padding">
        <component 
          :is="componentName.head"
          :total="total"
          :language="language"
          :abt-info="abtInfo"
          :coupon-info="couponInfo"
          :coupon-list-info="couponListInfo"
          :primary-coupon="coupon"
          :secondary-coupon="secondaryCoupon"
          :is-sorted="isSorted"
          @selected-coupon="handleSelectCoupon"
        />
      </div>
    </template>

    <div
      da-expose-code="addonListExposeCode"
    >
      <ErrorNetwork
        v-if="couponStatus == COUPON_STATUS.error_network"
        :language="language"
        :status-msg="statusMsg"
        @vue:mounted="analysis17413('promotion_unknown_error')"
        @close="show = false"
      />
      <ErrorDisable
        v-else-if="couponStatus == COUPON_STATUS.error_disable"
        :language="language"
        :status-msg="statusMsg"
        @vue:mounted="analysis17413('promotion_disable_error')"
        @close="show = false"
      />
      <template v-else>
        <section 
          v-if="isCouponAdd"
          class="filter-area"
        >
          <FilterBar
            ref="filterBar"
            :init-sort-value="paginationParams.sort"
            :language="language"
            @on-change="changeFilter"
          />
        </section>
        <!-- 列表 -->
        <Loading v-show="loading || (!loading && firstPage && !products.length)">
          <div
            v-if="loading"
            class="la-ball-pulse"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <EmptyList
            v-if="!loading && firstPage && !products.length"
            :text="language.SHEIN_KEY_PC_22046"
            :btn-text="language.SHEIN_KEY_PC_22252"
            @reset="handleEmptyReset"
          />
        </Loading>

        <List
          v-show="!loading"
          :item-config="itemConfig"
          :products="products"
          :language="language"
          :page="paginationParams.page"
          :limit="paginationParams.limit"
          :total="total"
          :is-click-to-detail="config.isClickToDetail"
          :type="type"
          :sa="saInfo"
          :config="config"
          @page-change="pageChange"
          @update-cart="updateCart"
        />
      </template>
    </div>

    <template 
      v-if="couponInfo.coupon_code"
      #footer
    >
      <FootWithBusinessCart
        v-if="!isCouponAdd && isBusinessCartBottom"
        ref="footWithBusinessCart"
        type="coupon"
        :state="saInfo.state"
        :language="language"
        :add-on-status="addOnStatus"
        :promotion-id="couponInfo.coupon_code"
        @cart-updated="businessCartUpdated"
      >
        <!-- 没时间了 后续解耦吧 -->
        <component 
          :is="componentName.foot"
          :language="language"
          :coupon-info="couponInfo"
          :config="config"
          @back="viewCart"
        >
          <template #incentivePoint>
            <IncentivePoint
              v-if="!config.isCloseIncentivePoint"
              class="footer__incentive-point"
              :tags="tags"
              :bubble="bubble"
            />
          </template>
        </component>
      </FootWithBusinessCart>
      <!-- 没时间了 后续解耦吧 -->
      <component
        :is="componentName.foot"
        v-else
        class="addon-foot"
        :language="language"
        :coupon-info="couponInfo"
        :config="config"
        @back="viewCart"
      >
        <template #incentivePoint>
          <IncentivePoint
            v-if="!config.isCloseIncentivePoint"
            class="footer__incentive-point"
            :tags="tags"
            :bubble="bubble"
          />
        </template>
      </component>
    </template>
  </s-dialog>
</template>

<script>
import { watch } from 'vue'
import FilterBar from './components/Filter.vue'
import { daEventCenter } from '@/public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@/public/src/services/eventCenterV2'
import Loading from '@/public/src/pages/common/cartAddItem/components/Loading.vue'
import EmptyList from '@/public/src/pages/common/cartAddItem/components/EmptyList.vue'
import List from './components/List.vue'
import FootWithBusinessCart from 'public/src/pages/common/add-on-dialog/components/FootWithBusinessCart.vue'

daEventCenter.addSubscriber({ modulecode: '1-7-4' })

daEventCenter.addSubscriber({ modulecode: '2-3' }) // 商卡

const daEventExpose = daEventCenter.getExposeInstance()

import apiReport from 'public/src/pages/common/apiReport'
import { cloneDeep, preloadImgs } from '@shein/common-function'
import { getCouponInfo, getPageAbt, getLanguage, getInterfaceList } from './utils/fetcher.js'
import { formatCoupon, handleCouponSort, handleCouponFilter } from './utils/index.js'
import { setCouponDataForAddOnItem } from './utils/couponData.js'
import { COUPON_STATUS, COUPON_DISABLE_CODES, COUPON_SHOW_MODE, SECONDARY_COUPON_MAX } from './utils/constants.js'
import ErrorNetwork from './layout/ErrorNetwork.vue'
import ErrorDisable from './layout/ErrorDisable.vue'
import CouponAddHead from './components/typeCoupon/couponAdd/Head.vue'
import NormalHead from './components/typeCoupon/normal/Head.vue'
import NormalFoot from './components/typeCoupon/normal/Foot.vue'
import MultipleCouponHead from './components/typeCoupon/multiple/MultipleCouponHead.vue'
import { useIncentivePoint, AsyncIncentivePointComp as IncentivePoint } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/hooks/incentivePoint'
// import { getFreeShipping } from 'public/src/pages/common/cart_module/utils/index.js'
import { isNewUserReturnCoupon } from '@/public/src/pages/cart_new/utils/index'
import { sleep } from '@/public/src/pages/common/add-on-dialog/utils/index.js'
import { getProductConfig } from 'public/src/pages/common/add-on-dialog/utils/productConfig.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AddOnDialogV2',
  components: {
    FilterBar,
    Loading,
    EmptyList,
    List,
    ErrorNetwork,
    ErrorDisable,
    NormalHead,
    NormalFoot,
    CouponAddHead,
    IncentivePoint,
    MultipleCouponHead,
    FootWithBusinessCart,
  },
  emits: ['update:visible', 'updateCart'],
  props: {
    queryParams: { // 查询参数用于请求推荐接口
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    saInfo: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    // 券码
    coupon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    // 副券码
    secondaryCoupon: {
      type: Array,
      default: () => ([]),
      // 校验长度最大为 19
      validator: (val) => {
        if (val.length > SECONDARY_COUPON_MAX) {
          console.error('secondaryCoupon length must be less than 19')
          return false
        }
        return true
      }
    },
    // 是否已对副券码进行排序
    // @desc 用于多券场景，副券码排序后不再进行排序
    isSorted: {
      type: Boolean,
      default: false,
    },
    onUpdateCart: {
      type: Function,
      default: () => ()=> ({}),
    },
    onClose: {
      type: Function,
      default: () => ()=> ({}),
    },
    onViewCart: {
      type: Function,
      default: () => ()=> ({}),
    },
    onUpdateBusinessCart: {
      type: Function,
      default: () => ()=> ({}),
    },
  },
  data() {
    return {
      products: [],
      paginationParams: {
        sort: 0,
        page: 1,
        limit: 10,
      },
      loading: true,
      total: 10,
      // 首券加车次数
      addCartNum: 0,
      // 副券加车次数
      otherAddCartNum: 0,
      firstPage: false,

      language: {},
      abtInfo: {},
      originCouponInfo: {},
      originCouponListInfo: [],
      statusCode: '',
      statusMsg: '',
      COUPON_STATUS,
      // simpleFreeShipping: false,
      couponListInfo: [],
      couponShowMode: COUPON_SHOW_MODE.UN_INIT,
      selectedCoupon: {},
      show: false,

      itemConfig: {},
      recommendQuery: {},
    }
  },
  computed: {
    componentName() {
      if (!this.isCouponAdd) {
        return {
          head: COUPON_SHOW_MODE.INIT_MULTIPLE === this.couponShowMode ? MultipleCouponHead : COUPON_SHOW_MODE.INIT_SINGLE === this.couponShowMode ? NormalHead : '',
          foot: 'NormalFoot',
        } 
      }
      return {
        head: 'CouponAddHead',
      }
    },
    commonParams() {
      const { addOnType, sceneId, cateIds, goodsIds, mallCode, quickship_prior } = this.queryParams
      const { direct_tag, return_tag, _data } = this.couponInfo

      let include_tsp_id = direct_tag?.map((v) => v.return_tagid).join(',')
      let exclude_tsp_id = return_tag?.map(item => item.return_tagid).join(',')
      return {
        goodsIds: [...new Set(goodsIds)].join(','), // 商品ID,  
        cateIds: [...new Set(cateIds)].join(','), // 类目ID
        jsonRuleId: JSON.stringify(this.abtInfo?.addnewhotJson?.param || {}), // 实验参数
        location: 'addnewhotJson',
        goods_price: ((_data?.activeRule?.need_price?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        mall_code: mallCode || '',
        scene_id: sceneId,
        add_on_type: addOnType,
        include_tsp_id,
        exclude_tsp_id,
        quickship_prior,
      }
    },
    isShipCoupon() { // apply_for: 5/6/9为邮费券，其他都是商品券
      return [5, 6, 9].includes(+this.couponInfo?.apply_for)
    },
    couponStatus() {
      if (this.statusCode != 0) {
        return COUPON_DISABLE_CODES.includes(+this.statusCode) ? COUPON_STATUS.error_disable : COUPON_STATUS.error_network
      }
      return COUPON_STATUS.normal
    },
    couponInfo() {
      if (COUPON_SHOW_MODE.INIT_MULTIPLE === this.couponShowMode) {
        return this.couponListInfo.find(item => item.coupon_code === this.selectedCoupon.coupon_code) ?? {}
      } else if (COUPON_SHOW_MODE.INIT_SINGLE === this.couponShowMode){
        return this.couponListInfo[0] ?? {}
      }
      return {}
    },
    isCouponAdd() {
      return this.saInfo?.state === 'coupon_add'
    },
    addOnStatus() {
      return this.couponInfo?._data?.is_satisfied
    },
    isBusinessCartBottom() {
      const { cartadd_coupon_bottom_style } = this.abtInfo?.CartaddCouponBottomStyle?.param || {}
      return ['A'].includes(cartadd_coupon_bottom_style)
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.show = val
        }
      },
      immediate: true,
    },
    show: {
      handler(val) {
        if (val) {
          this.handleShow()
        } else {
          this.handleClose()
          this.analysis17414()
          this.$emit('update:visible', false)
        }
      },
      immediate: true,
    },
  },

  setup(props) {
    const { tags, bubble, init, destroy, update: updateIncentivePoint } = useIncentivePoint({
      addOnType: props.type,
      saInfo: props.saInfo,
    })
    if (props.type && props.type !== 'freeShipping') {
      watch(() => props.visible, (visible) => {
        if (visible) {
          init()
        } else {
          destroy()
        }
      }, {
        immediate: true,
      })
    }
    return {
      tags,
      bubble,
      updateIncentivePoint,
    }
  },

  created() {
    this.preloadImgs()
  },
  mounted() {
    apiReport.report({
      apiPath: 'add_popup/bi_parameter_empty',
      // !!!! 产品要求, 埋点传全部外部组件传入参数 !!!!!
      parameter_detail: { ...this.$props },
    })
    // this.getSimpleFreeShipping()
  },

  methods: {
    handleClose() {
      if (this.type === 'coupon') {
        this.onClose(this.couponInfo)
        return
      }
      return this.onClose()
    },
    /**
     * 预加载样式里面的图片
     */
    preloadImgs() {
      if (typeof window === 'undefined') return
      if (!this.isCouponAdd) {
        preloadImgs({
          imgs: [
            'https://img.ltwebstatic.com/images3_acp/2024/03/07/4b/17097929553fe97a73d56de1aed20706fc26417a9c.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/07/13/1709792922257c678dd2460e7c08bd504ace6a769f.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/76/1709967509cb660c420cbc70fbd2e0b83b857d07b6.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/aa/1709967443b9e064321ff1e0ee9fb7fe4274087dc8.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/ba/1709967501c59d5739257b892ebc9826ce02cff11e.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/50/1709967482911b11356adb5543b59b4b9cbc7f48e8.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/5d/1709967467dabf90e3add0513c087911850aea1345.png',
          ],
        })
      }
    },
    async handleSelectCoupon(couponInfo) {
      const hasEmpty = function(obj) {
        for (let prop in obj) if (!prop.hasOwnProperty(obj)) return false
        return true
      }
      const hasSelected = !hasEmpty(this.selectedCoupon)
      this.selectedCoupon = couponInfo

      if (hasSelected) {
        await this.fetchCouponInfo({ isUpdate: true })
        await this.fetchList()
        this.$toast(this.language.SHEIN_KEY_PC_30147)
        this.analysis17413()
      }
      this.analysis17417()
    },
    viewCart() {
      this.analysis17410()
      if (this.config?.continueBtnToClose) {
        this.show = false
        return
      }
      if (window.location.href.includes('/cart')) {
        this.show = false
        this.onViewCart({ couponInfo: this.couponInfo })
        return
      }
      setCouponDataForAddOnItem({ couponInfo: this.couponInfo })

      // 等待埋点事件触发上报后再进行跳转
      setTimeout(() => {
        window.location.href = '/cart'
      })
    },  
    async handleShow() {
      this.firstPage = true
      this.addCartNum = 0
      this.otherAddCartNum = 0
      this.products = []
      this.fecthLanguage()
      await this.fetchPageAbt() // 请求jsonRuleId
      const res = await getProductConfig({ 
        code: 'addonListExposeCode',
        style: {
          addBagBtnStyle: ['freeShipping', 'coupon'].includes(this.type) && ['checkout_shipping_add', 'checkout_shipping_coupon_add'].includes(this.saInfo?.activity_from) ? 'AddText' : 'default'
        }
      })
      this.itemConfig = res?.itemConfig
      this.recommendQuery = res?.recommendQuery

      if (this.coupon || this.secondaryCoupon.length) {
        await this.fetchCouponInfo() // 请求券数据
        this.originCouponInfo = cloneDeep(this.couponInfo)
        this.originCouponListInfo = cloneDeep(this.couponListInfo)

        if (this.couponStatus != COUPON_STATUS.normal) {
          return
        }
      }

      await this.fetchList(true)
    },
    async fecthLanguage() {
      const { language } = await getLanguage()
      this.language = language
    },
    async fetchPageAbt() {
      this.abtInfo = await getPageAbt()
    },

    /**
     * 获取优惠券信息
     * @param {Boolean} isUpdate 是否是更新
     */
    async fetchCouponInfo({ isUpdate = false } = {}) {
      if (!(this.coupon || this.secondaryCoupon.length)) return

      const getCouponCode = () => {
        const res = this.secondaryCoupon.slice(0, SECONDARY_COUPON_MAX)
        if (this.coupon) {
          res.unshift(this.coupon)
        }
        return [...new Set(res)]
      }
      const couponCodes = getCouponCode()
      const errorType = this.secondaryCoupon.length ? 1 : 0
      const res = await getCouponInfo({
        coupon_codes: couponCodes,
        error_type: errorType,
      })

      const coupon_diff = res?.info?.coupon_diff ?? []

      // 更新的时要保持上次的排序过滤不存在的券
      if (isUpdate) {
        this.couponListInfo = handleCouponFilter({
          couponListInfo: coupon_diff.map(formatCoupon),
          prevCouponListInfo: this.couponListInfo,
        })
      } else {
        this.couponListInfo = handleCouponSort({
          primaryCoupon: this.coupon,
          secondaryCoupon: this.secondaryCoupon,
          isSorted: this.isSorted,
          couponListInfo: coupon_diff.map(formatCoupon),
        })
      }
      this.couponShowMode = this.couponListInfo.length > 1 ? COUPON_SHOW_MODE.INIT_MULTIPLE : COUPON_SHOW_MODE.INIT_SINGLE
      
      const getStatusCode = (errorType, res) => {
        if (errorType === 1 && !this.couponListInfo.length) {
          return '500110'
        }
        return res?.code
      }
      const getStatsuMsg = (errorType, res) => {
        if (errorType === 1 && !this.couponListInfo.length) {
          return this.language.SHEIN_KEY_PC_30083
        }
        return res?.tips || res?.msg
      }
      this.statusCode = getStatusCode(errorType, res)
      this.statusMsg = getStatsuMsg(errorType, res)
    },
    handleExpose({ code, total }) {
      let service_error
      if (code == '0' && total == 0) {
        service_error = 'goods_is_empty'
      } else if (code != '0') {
        service_error = code ? code : 'recommend_unknown_error'
      }
      this.analysis17413(service_error)
    },
    async fetchList(isFirst) {
      try {
        this.loading = true
        await this.getList(isFirst)
      } finally {
        this.loading = false
        this.$nextTick(() => {
          this.initExpose()
        })
      }
    },
    async getList(isFirst) {
      const { page, sort, limit } = this.paginationParams
      const { adp, mainGoodsIds } = this.queryParams

      const contextParams = {
        cate_ids: this.commonParams.cateIds,
        goods_ids: this.commonParams.goodsIds,
        goods_price: this.commonParams.goods_price,
        mall_code: this.commonParams.mall_code,
        add_on_type: this.commonParams.add_on_type,
        quickship_prior: this.commonParams.quickship_prior,
        stock_enough: 1, // 是否有库存
        adp, // TR-27530支持外围传入指定商品id
        main_goods_id: [...new Set(mainGoodsIds)].slice(0, 20).join(','),
      }

      // 不能传空字符串
      if (this.commonParams.include_tsp_id) contextParams.include_tsp_id = this.commonParams.include_tsp_id
      if (this.commonParams.exclude_tsp_id) contextParams.exclude_tsp_id = this.commonParams.exclude_tsp_id

      const params = {
        ...this.commonParams,
        ...this.recommendQuery,
        pageEnable: 1,
        req_num: 300,
        pageNum: page, // 页数
        sort,
        limit,
        contextParams,
      }

      this.products = []
      const { products, total, code } = await getInterfaceList(params, this.itemConfig).catch(() => null)

      if (products?.length) {
        // 填充不满10个时为数据能展示下空数据
        if (products.length < limit) {
          for (let i = 0; i < limit - products.length; i++) {
            products.push({})
          }
        }
        this.products = products
        this.total = total
      }

      if (isFirst) {
        this.handleExpose({ total, code })
      }
    },

    async changeFilter({ type, item = {} } = {}) {
      const { value } = item

      if (type === 'sort') {
        this.paginationParams.sort = value
      }

      this.paginationParams.page = 1
      await this.fetchList()
    },

    pageChange(nextPage) {
      this.paginationParams.page = nextPage
      this.fetchList()

      document.querySelector('.coupon-add-buys .sui-dialog__body')?.scrollTo({ top: 0 })
    },

    reset() {
      this.firstPage = false
      this.loading = false
      this.paginationParams = {
        sort: 0,
        page: 1,
        limit: 10,
      }
      this.total = 0
      this.products = []
      this.addCartNum = 0
      this.otherAddCartNum = 0
    },

    handleEmptyReset() {
      this.reset()
      this.handleShow()
    },

    // 加车完成后事件
    updateCart(payload) {
      if (this.couponInfo.coupon_code === this.originCouponInfo) {
        this.addCartNum += 1
      } else {
        this.otherAddCartNum += 1
      }
      // 更新券数据
      this.fetchCouponInfo({
        isUpdate: true,
      })
      this.updateIncentivePoint(true)
      // this.getSimpleFreeShipping()
      this.onUpdateCart(payload)
      this.$refs?.footWithBusinessCart?.refetchCarts?.()
    },
    // 非标车加车后事件
    businessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        // 更新券数据
        this.fetchCouponInfo({ isUpdate: true })
        this.updateIncentivePoint(true)

        this.onUpdateBusinessCart()
        window.cart_module && window.cart_module.reload()
      }
    },

    analysis17413(service_error) {
      const data = {
        state: this.saInfo.state,
        service_error: service_error || '-',
        is_satisfied: this.couponInfo?._data?.is_satisfied,
        parameter_detail: this.$props,
        is_multiple_coupons: this.couponListInfo.length > 1 ? 'multiple' : 'single',
        promotion_code: this.couponListInfo.length ? this.couponListInfo.map(item => item.coupon_code).join(',') : '-',
        newuser_label: isNewUserReturnCoupon(this.promotion) ? 1 : 0,
      }

      daEventCenter.triggerNotice({
        daId: '1-7-4-13',
        extraData: data,
      })

      daEventCenterV2.triggerNotice({
        id: 'expose_cart_add.comp_add-item',
        data,
      })
    },
    analysis17414() {
      const data = this.getCLoseAndViewCartAnalysisData()

      daEventCenter.triggerNotice({
        daId: '1-7-4-14',
        extraData: data,
      })

      daEventCenterV2.triggerNotice({
        id: 'click_cart_add_close.comp_add-item',
        data,
      })
    },
    analysis17410() {
      const data = this.getCLoseAndViewCartAnalysisData()
      const _eventId = uuidv4()

      daEventCenter.triggerNotice({
        daId: '1-7-4-10',
        extraData: {
          _scene: 1,
          _eventId,
          ...data
        },
      })

      daEventCenterV2.triggerNotice({
        id: 'click_back_to_cart.comp_add-item',
        data: {
          _scene: 1,
          _eventId,
          ...data
        },
      })

      SILogger.logInfo({
        tag: 'CLICK_BACK_TO_CART',
        message: 'click_back_to_cart_stats',
        params: {
          _scene: 1,
          _eventId
        }
      }, {
        immediate: true
      })
    },
    analysis17417() {
      daEventCenter.triggerNotice({
        daId: '1-7-4-17',
        extraData: {
          position: 'top',
          promotion_code: this.couponInfo.coupon_code,
          is_satisfied: this.couponInfo._data.is_satisfied,
        }
      })
    },
    getCLoseAndViewCartAnalysisData() {
      let coupon_change
      let other_coupon_change = '-'
      let other_add_cart_number = '-'
      let is_satisfied_all = '-'
      const isUpgrade = (origin, curr)=> {
        let originLevel = origin?._data?.activeRuleIndex || 0
        let currLevel = curr?._data?.activeRuleIndex || 0
        // 当值为 -1 时，代表券已凑满，改到无限大
        if (originLevel === -1) originLevel = 999
        if (currLevel === -1) currLevel = 999
        return currLevel > originLevel ? 1 : 0
      }
      // 多券
      if (this.couponListInfo.length > 1) {
        const currFirstCoupon = this.couponListInfo.find(item => item.coupon_code === this.originCouponInfo.coupon_code)
        const currOtherCoupon = this.couponListInfo.filter(item => item.coupon_code !== this.originCouponInfo.coupon_code)
        coupon_change = isUpgrade(this.originCouponInfo, currFirstCoupon)
        other_coupon_change = currOtherCoupon.some(curr => isUpgrade(this.originCouponListInfo.find(origin => origin.coupon_code === curr.coupon_code), curr)) ? 1 : 0
        other_add_cart_number = this.otherAddCartNum
        is_satisfied_all = this.couponListInfo.map((v) => `${v.coupon_code}_${v._data?.is_satisfied}`)?.join('`')
      } else {
        // 单券
        coupon_change = isUpgrade(this.originCouponInfo, this.couponInfo)
      }
      return {
        coupon_change,
        other_add_cart_number,
        other_coupon_change,
        add_cart_number: this.addCartNum,
        state: this.saInfo.state,
        is_satisfied: this.couponInfo?._data?.is_satisfied,
        is_satisfied_all,
      }
    },
    async initExpose() {
      await sleep(500)
      daEventExpose && daEventExpose.subscribe({
        keycode: `addonListExposeCode\`${
          this.itemConfig.daEventExposeId || ''
        }|2-10-2`,
      })
    },
    // async getSimpleFreeShipping() {
    //   getFreeShipping().then(({ showFreeShipping }) => {
    //     this.simpleFreeShipping = showFreeShipping
    //   })
    // },
  }
}
</script>

<style lang="less" scoped>
.coupon-add-buys {
  .sui-dialog__title-wrap{
    padding: 32px 48px 16px;
  }
  .la-ball-pulse {
    div {
      width: 16px;
      height: 16px;
    }
  }

  :deep(.sui-dialog__title-wrap) {
    padding: 32px 48px 16px;
  }
  :deep(.sui-dialog__body) {
    min-height: 50vh;
    overflow-x: hidden !important; /* stylelint-disable-line declaration-no-important */
  }
  :deep(.sui-dialog__footer-holder) {
    padding-top: 30px;
  }

  .filter-area {
    padding: 16px 0 0;
  }
}
.add-on-dialog {
  :deep(.sui-dialog__closebtn) {
    // background-color: rgba(255, 255, 255, 0.20);
    background-color: rgba(0, 0, 0, 0.30);
    border-radius: 50%;
    top: 4px;
    right: 7px;
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-icons {
      fill: #fff;
    }
  }
}
.addon-foot {
  position: relative;
  height: 56px;
  padding: 0 48px;
  border-top: 1px solid #EAEAEA;
  display: flex;
  align-items: center;
}
</style>

<style lang="less" scoped>
.coupon-add-buys {

  .filter-area {
    padding: unset;
  }
  .head-no-padding {
    margin: -32px -48px -16px
  }

  /deep/ .sui-dialog__title-wrap {
    padding: 32px 48px 16px;
  }

  /deep/ .sui-dialog__footer {
    padding: 0px;
  }
}
.footer__incentive-point {
  position: absolute;
  right: -1px;
  bottom: calc(100% - 5px);
  z-index: @zindex-hack;
}
</style>
