/**
 * GoodsDiscountFlag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant }) {
  // 限时折扣
  const isLimitedTimeDiscount = data.value.promotionTypeIds?.includes(3) && data.value.product?.sale_discount && data.value.product?.sale_discount != data.value.product?.unit_discount
  // 折扣格式化
  const discountFormatter = (discount, lang) => (lang !== 'il' ? `-${discount}%` : `${discount}%`)
  // 是否 VIP
  const isVIP = data.value.isSheinClubGiftItem || data.value.promotionTypeIds?.includes(29)
  // 是否闪购
  const isFlash = data.value.promotionTypeIds?.includes(10) && [1, 4, 5].includes(data.value.flashType)
  // 是否新人闪购
  const isNewPersonFlash = data.value.promotionTypeIds?.includes(10) && ![1, 4, 5].includes(data.value.flashType)
  // 单项折扣
  const unitDiscount = discountFormatter(data.value.product?.unit_discount, constant.value.lang)
  // 特价折扣
  const saleDiscount = discountFormatter(data.value.product?.sale_discount, constant.value.lang)
  // 一口价
  const isUnderPrice = data.value.isUnderPriceAbtValue && data.value.promotionTypeIds?.includes(30) && data.value.product?.unit_discount
  // 券后价
  const isAfterCouponPrice = data.value.promotionTypeIds?.includes(32) && data.value.product?.unit_discount
  // 折扣活动
  const isDiscount = isNewPersonFlash || isUnderPrice || isAfterCouponPrice || isLimitedTimeDiscount
  // 是否显示特价折扣
  const isShowSaleDiscount = isLimitedTimeDiscount

  // 是否展示
  let isShow = +data.value.product?.unit_discount > 0 && !data.value.isGiftItem
  if (constant.value.IS_SUGGESTED) {
    // 合规站点
    if (constant.value.IS_SUGGESTED === 'special_de') {
      // 德国合规 特价类型为 1、3、5
      isShow = [1, 3, 5].includes(data.value.suggestedPriceType) && isShow
    } else {
      // 法国/波兰合规 非倒挂
      isShow = data.value.is_inversion !== 1 && isShow
    }
  } else {
    // 非合规站点
    // 没有命中新的折扣标签样式
    isShow = !data.value.isNewDiscountStyle && isShow
  }

  const isShowMask = ((data.value.isGiftItem || data.value.isSheinClubGiftItem) && !data.value.is_checked) || data.value.isSoldOutItem

  const OLD_BG_COLOR = {
    VIP: [
      '#FFE2D3',
      '#FFBFA5'
    ],
    FLASH: [
      '#FACF19',
      '#FF4C3A'
    ],
    DISCOUNT: [
      '#FA6338',
      '#FC4070'
    ],
    OTHER_ACTIVITY: [
      '#222222',
      '#FC4070'
    ],
  }
  const OLD_FONT_COLOR = {
    VIP: [
      '#873C00',
      '#873C00',
    ],
    FLASH: [
      '#222222',
      '#FFFFFF'
    ],
    DISCOUNT: [
      '#FFFFFF',
      '#FFFFFF'
    ],
    OTHER_ACTIVITY: [
      '#FFFFFF',
      '#FFFFFF'
    ],
  }

  const NEW_BG_COLOR = {
    VIP: [
      '#873C00',
      '#873C00',
    ],
    OTHER_ACTIVITY: [
      '#FA6338',
      '#FC4070'
    ],
  }
  const NEW_FONT_COLOR = {
    VIP: [
      '#FFFFFF',
      '#FFFFFF'
    ],
    OTHER_ACTIVITY: [
      '#FFFFFF',
      '#FFFFFF'
    ],
  }

  const getTagColor = (colors) => {
    return constant.value.IS_RW ? colors[1] : colors[0]
  }

  // 旧版本颜色
  const getOldColor = () => {
    if (isVIP) {
      return {
        bgColor: getTagColor(OLD_BG_COLOR.VIP), 
        fontColor: getTagColor(OLD_FONT_COLOR.VIP), 
      }
    }
    if (isFlash) {
      return {
        bgColor: getTagColor(OLD_BG_COLOR.FLASH),
        fontColor: getTagColor(OLD_FONT_COLOR.FLASH),
      }
    }
    if (isDiscount) {
      return {
        bgColor: getTagColor(OLD_BG_COLOR.DISCOUNT),
        fontColor: getTagColor(OLD_FONT_COLOR.DISCOUNT),
      }
    }
    const isOtherActivity = !isVIP && !isFlash && !isDiscount && data.value.product?.unit_discount != '0'
    if (isOtherActivity) {
      return {
        bgColor: getTagColor(OLD_BG_COLOR.OTHER_ACTIVITY),
        fontColor: getTagColor(OLD_FONT_COLOR.OTHER_ACTIVITY),
      }
    }
  }

  // 新版本颜色
  const getNewColor = () => {
    if (isVIP) {
      return {
        bgColor: getTagColor(NEW_BG_COLOR.VIP),
        fontColor: getTagColor(NEW_FONT_COLOR.VIP),
      }
    }
    const isOtherActivity = !isVIP && +data.value.product?.unit_discount != '0'
    if (isOtherActivity) {
      return {
        bgColor: getTagColor(NEW_BG_COLOR.OTHER_ACTIVITY),
        fontColor: getTagColor(NEW_FONT_COLOR.OTHER_ACTIVITY),
      }
    }
  }

  const getColor = () => {
    if (data.value.isDiscountLogoA) {
      return getNewColor()
    }
    return getOldColor()
  }

  return {
    isShow,
    isShowMask,
    unitDiscount,
    saleDiscount,
    isShowSaleDiscount,
    ...getColor(),
  }
}

