import { provide, inject } from 'vue'

/**
 * 提供全局通用的一些常量
 * @param {Object} constant 
 */
export const useProvideConstants = (constant) => {
  provide('constant', constant)
}

/**
 * 注入全局通用的一些常量
 */
export const useInjectConstants = () => {
  return inject('constant')
}
