<template>
  <AddOnDialogV2
    v-if="$attrs.visible"
    v-bind="$attrs"
  />
</template>

<script>
import AddOnDialogV2 from './addOnDialog-v2/index.vue'

export default {
  name: 'AddForCouponV2',
  components: {
    AddOnDialogV2
  },
}
</script>
