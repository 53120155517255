<template>
  <div
    v-if="isShow"
    :class="[
      'goods-sale-attr',
      {
        'goods-sale-attr_mask': isShowMask,
        'goods-sale-attr_click': isClick,
        'goods-sale-attr_capsule': type === 'capsule',
      }
    ]"
    :style="styles"
    @click="handleClick"
  >
    <BallLoading v-if="loading" />
    <template v-else>
      <div
        v-if="mode === 'attr' && (colorImage || goodsAttr)"
        class="goods-sale-attr__content"
      >
        <img
          v-if="colorImage"
          :src="colorImage"
          alt=""
          class="goods-sale-attr__img"
        />
        <span
          v-if="goodsAttr"
          class="goods-sale-attr__text"
        >{{ goodsAttr }}</span>
      </div>
      <span v-if="mode === 'reselect'">{{ reselectText }}</span>
      <sui_icon_more_down_12px_1
        v-if="!hideArrow && type === 'capsule'"
        class="goods-sale-attr__icon"
        size="12px"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import BallLoading from '../../components/BallLoading'
import { sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'

/**
 * 商品销售属性组件
 */

// events
const emit = defineEmits(['click', 'report'])

// props
const props = defineProps({
  // 类型：capsule胶囊型、text文字型
  type: {
    type: String,
    default: 'capsule'
  },
  isShow: {
    type: Boolean,
    default: false
  },
  // 展示的形式
  mode: {
    type: String,
    default: 'attr'
  },
  // 重选文案
  reselectText: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: true,
  },
  // 是否隐藏下箭头
  hideArrow: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: '#666',
  },
  size: {
    type: String,
    default: '13px',
  },
  fontWeight: {
    type: String,
    default: 'normal',
  },
  maxWidth: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
    default: '24px',
  },
  padding: {
    type: String,
    default: '0 10px',
  },
  colorImage: {
    type: String,
    default: '',
  },
  goodsAttr: {
    type: String,
    default: '',
  }
})

// computed
const styles = computed(() => {
  return {
    '--color': props.color,
    '--size': props.size,
    '--font-weight': props.fontWeight,
    '--max-width': props.maxWidth,
    '--height': props.height,
    '--padding': props.padding,
  }
})

// events
const handleClick = () => {
  emit('report', { event: props.mode })
  if (props.isClick) {
    emit('click', { event: props.mode })
  }
}
</script>

<style lang="less" scoped>
.goods-sale-attr {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  max-width: var(--max-width);
  font-size: var(--size);
  color: var(--color);
  font-weight: var(--font-weight);
  height: var(--height);
  line-height: var(--height);
  cursor: default;
  &.goods-sale-attr_mask {
    opacity: .3;
  }
  &.goods-sale-attr_click {
    cursor: pointer;
    &:hover {
      border-color: #222;
    }
  }
  &.goods-sale-attr_capsule {
    padding: var(--padding);
    border-radius: var(--height);
    background-color: #F2F2F2;
    border: 1px solid #F2F2F2;
  }
  .goods-sale-attr__content {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .goods-sale-attr__icon {
    margin-left: 4px;
  }
  .goods-sale-attr__img {
    box-sizing: border-box;
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border: 0.5px solid rgba(0, 0, 0, 0.10);
    border-radius: 100%;
  }
  .goods-sale-attr__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
