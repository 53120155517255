<template>
  <div
    v-if="isShow"
    class="voucher-tag"
    :style="style"
  >
    <div class="voucher-tag__left">
      <div class="voucher-tag__text">
        {{ text }}
      </div>
      <template v-if="tipDisabled">
        <sui_icon_info_12px_1 size="12px" />
      </template>
      <template v-else>
        <SPopover
          show-close-icon
          theme="dark"
          trigger="hover"
          :append-to-body="tipAppendToBody"
          :prop-style="{
            width: '240px'
          }"
        >
          <template #reference>
            <sui_icon_info_12px_1
              size="12px"
              class="voucher-tag__icon"
            />
          </template>
          <div class="voucher-tag__popover-content">
            {{ tip }}
          </div>
        </SPopover>
      </template>
    </div>
    <div
      v-if="subText"
      class="voucher-tag__right"
    >
      <div class="voucher-tag__line"></div>
      <div class="voucher-tag__subtext">
        {{ subText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  tip: {
    type: String,
    default: '',
  },
  tipDisabled: {
    type: Boolean,
    default: false,
  },
  tipAppendToBody: {
    type: Boolean,
    default: true
  },
  subText: {
    type: String,
    default: '',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less" scoped>
.voucher-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  max-width: 100%;
  height: 20px;
  font-size: 12px;
  color: #ff3f3f;
  background: #ffe6e6;
  border-radius: 2px;
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__left {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 2px;
  }

  &__right {
    flex-shrink: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
  }

  &__text {
    font-weight: 600;
  }

  &__subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__line {
    margin: 0 4px;
    height: 10px;
    border-left: 1px solid rgba(@sui_color_guide, .4);
  }

  &__icon {
    cursor: pointer;
  }

  &__popover-content {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
}
</style>
