<script setup>
import { ref, inject, computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import MainCartItem from 'public/src/pages/cart_new/components/cartItem/template/MainCartItem.vue'
import BusinessCartMask from 'public/src/pages/common/business-cart/components/BusinessCartMask.vue'
import { deleteItemReport } from '../utils/analysis'

const constant = inject('constant')

/**
 * 非标商品行删除弹窗
 */

const emit = defineEmits(['update:visible', 'cancel', 'confirm', 'close'])

// props
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  constant: {
    type: Object,
    default: () => ({})
  },
  uiConfig: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: false
  }
})

// data
const isShow = computed({
  get: () => props.visible,
  set: val => emit('update:visible', val)
})
const config = ref({
  // 黑五角标
  GoodsBlackFridayFlag: {
    isShow: false,
  },
  GoodsImg: {
    props() {
      return {
        isClick: false,
        isLazyLoad: false,
      }
    }
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props({ data, constant }) {
      const DISCOUNT_BG_COLOR = ['#FA6338', '#FC4070']
      const NORMAL_BG_COLOR = ['#000', '#000']
      const SHEIN_CLUB = ['#873C00', '#873C00']
      const getColor = colors => constant.value.IS_RW ? colors[1] : colors[0]

      const cartDiscountShow = data.value.product.sale_discount && data.value.promotionTypeIds?.includes(3) && data.value.product.sale_discount != data.value.product.unit_discount
      const cartPromotionDiscount = data.value.isUnderPriceAbtValue && data.value.promotionTypeIds?.includes(30) && data.value.product.unit_discount
      const specialCouponDiscount = data.value.promotionTypeIds?.includes(32) && data.value.product.unit_discount
      const flashSaleType = data.value.promotionTypeIds?.includes(10) ? data.value.flashType : data.value.promotionTypeIds?.includes(11) ? -1 : 0
      const isNewComerItem4App = data.value.isNewComerItem4App
      const notSpecialDiscount = data.value.promotionTypeIds?.some((id) => [1, 3, 9, 10, 11, 14, 15, 16, 17, 24, 25, 26, 29, 30, 31, 32].includes(id)) && data.value.product.unit_discount
      const isVip = data.value.isGiftItem && !data.value.isClubGiftItem
      const isSheinClub = data.value.isSheinClubItem

      // 是否展示
      let isShow = +data.value.product.unit_discount > 0 && !data.value.isGiftItem
      if (constant.value.IS_SUGGESTED) {
        // 合规站点
        if (constant.value.IS_SUGGESTED === 'special_de') {
          // 德国合规 特价类型为 1、3、5
          isShow = [1, 3, 5].includes(data.value.suggestedPriceType) && isShow
        } else {
          // 法国/波兰合规 非倒挂
          isShow = data.value.is_inversion !== 1 && isShow
        }
      }

      let bgColor
      if (cartDiscountShow || cartPromotionDiscount || specialCouponDiscount || flashSaleType || isNewComerItem4App || notSpecialDiscount || isVip) {
        bgColor = getColor(DISCOUNT_BG_COLOR)
      } else if (isSheinClub) {
        bgColor = getColor(SHEIN_CLUB)
      } else {
        bgColor = getColor(NORMAL_BG_COLOR)
      }

      return {
        isShow,
        bgColor,
        fontColor: '#FFF',
      }
    }
  },
  // 商品腰带
  GoodsBelt: {
    props({ data }) {
      const BG_COLOR = {
        GIFT_BELT: [
          'rgba(250, 99, 56, 0.8)',
          'rgba(255, 105, 110, 0.8)',
        ],
        VIP_GIFT_BELT: [
          'rgba(135, 60, 0, 0.8)',
          // RW 没有
          'rgba(135, 60, 0, 0.8)',
        ],
        FLASH: [
          'rgba(0, 0, 0, 0.6)',
          'rgba(255, 80, 120, 0.8)'
        ],
        FLASH_NEW_PERSON: [
          'rgba(0, 0, 0, 0.6)',
          'rgba(255, 105, 110, 0.8)',
        ],
        INVENTORY_BELT: [
          'rgba(0, 0, 0, 0.6)',
          'rgba(0, 0, 0, 0.6)',
        ],
        ADD_ON_BELT: [
          'rgba(255, 248, 235, 0.8)',
          'rgba(255, 248, 235, 0.8)',
        ]
      }

      const FONT_COLOR = {
        GIFT_BELT: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        VIP_GIFT_BELT: [
          '#FFFFFF',
          // RW 没有
          '#FFFFFF',
        ],
        FLASH: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        FLASH_NEW_PERSON: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        INVENTORY_BELT: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        ADD_ON_BELT: [
          '#A86104',
          '#A86104',
        ]
      }

      // 转换闪购类型, 0: 非闪购, 1: 普通闪购, 2: 新人闪购, 3: 特殊闪购, 4: 分人群专区闪购, 5: 不分人群专区闪购, -1: 直播闪购(PC无直播闪购)
      const flashSaleType = data.value.promotionTypeIds?.includes(10) ? data.value.flashType : (data.value.promotionTypeIds?.includes(11) ? -1 : 0)
      // 赠品腰带
      const isGiftBelt = data.value.isGiftItem && !data.value.isSheinClubGiftItem
      // 会员赠品腰带
      const isVipGiftBelt = data.value.isGiftItem && data.value.isSheinClubGiftItem
      // 库存腰带
      const isInventoryBelt = data.value.stock_show_type == 1 && constant.value.CART_STOCK_TIPS_SWITCH
      // 闪购腰带
      const isFlashBelt = data.value.stock_show_type == 0 && data.value.realTimeInventory > 0 && [-1, 1, 4, 5].includes(flashSaleType)
      // 新人腰带
      const isFlashNewPersonBelt = data.value.stock_show_type == 0 && data.value.realTimeInventory > 0 && [0, 2, 3, 6].includes(flashSaleType)
      // 换购品腰带
      const isAddItem = data.value.isAddItem
      // 获取不同站点腰带颜色
      const getColor = (colors) => constant.value.IS_RW ? colors[1] : colors[0]

      // 获取闪购腰带数量
      const getFlashNum = () => {
        const promotionFlash = data.value.product.product_promotion_info.filter(i => [10].includes(i.type_id))
        const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
        const realTimeInventory = +data.value.realTimeInventory || 0
        const flashNum = stock - sold_num
        const minNum = Math.min(realTimeInventory, flashNum)
        const maxNum = Math.max(realTimeInventory, flashNum)
        return minNum > 0 ? minNum : maxNum
      }

      if (isAddItem) {
        return {
          bgColor: getColor(BG_COLOR.ADD_ON_BELT),
          fontColor: getColor(FONT_COLOR.ADD_ON_BELT),
          beltText: props.language.SHEIN_KEY_PC_29518,
        }
      } else if (isGiftBelt) {
        return {
          bgColor: getColor(BG_COLOR.GIFT_BELT),
          fontColor: getColor(FONT_COLOR.GIFT_BELT),
          beltText: props.language.SHEIN_KEY_PC_30158,
        }
      } else if (isVipGiftBelt) {
        return {
          bgColor: getColor(BG_COLOR.VIP_GIFT_BELT),
          fontColor: getColor(FONT_COLOR.VIP_GIFT_BELT),
          beltText: props.language.SHEIN_KEY_PC_30158,
        }
      } else if (isInventoryBelt) {
        return {
          bgColor: getColor(BG_COLOR.INVENTORY_BELT),
          fontColor: getColor(FONT_COLOR.INVENTORY_BELT),
          // 非标不展示
          beltText: '',
        }
      } else if (isFlashBelt) {
        return {
          bgColor: getColor(BG_COLOR.FLASH),
          fontColor: getColor(FONT_COLOR.FLASH),
          beltText: props.language?.SHEIN_KEY_PC_25797?.replace('{0}', flashSaleType ? getFlashNum() : data.value.realTimeInventory)
        }
      } else if (isFlashNewPersonBelt) {
        return {
          bgColor: getColor(BG_COLOR.FLASH_NEW_PERSON),
          fontColor: getColor(FONT_COLOR.FLASH_NEW_PERSON),
          beltText: props.language?.SHEIN_KEY_PC_25797?.replace('{0}', flashSaleType ? getFlashNum() : data.value.realTimeInventory),
        }
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        fontSize: '12px',
        isClick: false,
        lineHeight: '14px',
      }
    }
  },
  // 标题图标
  GoodsTitleIcon: {
    isShow: false,
  },
  // 行内店铺头
  GoodsStore: {
    isShow: false,
  },
  // 找同款按钮版
  FindSimilarText: {
    isShow: false,
  },
  // 商品主图定制
  GoodsCustomContent: {
    isShow: false,
  },
  // 营销标签
  PromotionTag: {
    isShow: false,
  },
  // 代金券标签
  VoucherTag: {
    isShow: false,
  },
  // 快速发货标签
  QuickShipTag: {
    isShow: !props.uiConfig.disableQuickShip,
    props({ data }) {
      return {
        timeText: data.value.quickShipTime,
      }
    }
  },
  // 免邮标签
  FreeShippingTag: {
    isShow: !props.uiConfig.disableFreeShipping,
  },
  MorePromotionTag: {
    props() {
      return {
        disabledIcon: true,
      }
    },
  },
  // 用户行为标签
  BehaviorLabel: {
    isShow: false,
  },
  // 商品价格
  GoodsPrice: {
    props({ data, constant }) {
      const getSalePriceColor = () => {
        const FONT_COLOR = {
          SHEIN_CLUB: [
            '#873C00',
            '#873C00'
          ],
          DISCOUNT: [
            '#FA6338',
            '#FC4070'
          ],
          NORMAL: [
            '#222',
            '#222',
          ]
        }
        const getColor = colors => constant.value.IS_RW ? colors[1] : colors[0]
        const promotionCutPriceShow = data.value.reduce_price && data.value.is_checked != 1 && data.value.reduce_price.siteFrom == constant.value.SiteUID && data.value.reduce_price.reduceAmount.amount > 0
        const isVipItem = data.value.promotionTypeIds?.includes(12)
        const isPremiumMember = data.value.promotionTypeIds?.includes(29) || data.value.isSheinClubGiftItem
        const isDiscountProduct = !isVipItem && !isPremiumMember && (promotionCutPriceShow || data.value.product.insurable == 1 || data.value.product?.feSalePrice.amount !== data.value.product?.feOriginalPrice.amount || (constant.value.IS_SUGGESTED === 'special_de' && [5].includes(data.value.suggestedPriceType) && data.value.product?.feSalePrice.amount !== data.value.product?.suggested_sale_discount.amount))

        if (constant.value.IS_SUGGESTED && constant.value.IS_SUGGESTED != 'special_de' && data.value.is_inversion === 1 && !data.value.isPromotionB && !data.value.isSheinClubItem) {
          return getColor(FONT_COLOR.NORMAL)
        }
        if (constant.value.IS_SUGGESTED == 'special_de' && ![1, 3, 5].includes(data.value.suggestedPriceType)) {
          return getColor(FONT_COLOR.NORMAL)
        }
        if (isDiscountProduct) {
          return getColor(FONT_COLOR.DISCOUNT)
        } else if (isPremiumMember) {
          return getColor(FONT_COLOR.SHEIN_CLUB)
        }
        return getColor(FONT_COLOR.NORMAL)
      }
      return {
        salePriceColor: getSalePriceColor(),
        salePriceFont: 12,
        isShowSalePrice: true,
        isShowTimeIcon: false,
        isShowAppOnlyIcon: false,
        isShowBrandSaleIcon: false,
        isShowFlashSaleIcon: false,
        premiumMemberIconColor: '#873C00',
      }
    }
  },
  // 找同款图标版
  FindSimilarIcon: {
    isShow: false,
  },
  // 商品删除
  GoodsDeleteButton: {
    isShow: false,
  },
  GoodsAddWish: {
    isShow: false,
  },
  GoodsQty: {
    isShow: false,
  },
  EstimatedPrice: {
    props({ data }) {
      const isShowEstimated = data.value.coupon_flag == 1 || data.value.is_auto_use_points_flag == 1
      const isShowDiscount = false
      return {
        isShow: isShowEstimated || isShowDiscount,
        isShowEstimated,
        isShowDiscount,
        isHover: false,
      }
    }
  },
  GoodsSaleAttr: {
    props() {
      return {
        type: 'text',
        size: '12px',
        height: '15px',
        isClick: false,
      }
    },
  },
})

// methods
const handleCancel = () => {
  emit('cancel', props.data)
  isShow.value = false
}
const handleConfirm = () => {
  emit('confirm', props.data)
  isShow.value = false
}
const handleClose = () => {
  emit('close', props.data)
  isShow.value = false
}
const handleExpose = () => {
  deleteItemReport({ item: props.data })
}
</script>

<template>
  <div
    v-if="isShow"
    class="bc-item-del"
  >
    <BusinessCartMask
      class="bc-item-del__mask"
      @click="handleClose"
    />
    <SDialog
      v-model:visible="isShow"
      :show-close="false"
      :modal="false"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_30038 }}
      </template>
      <div
        class="bc-item-del__item"
      >
        <MainCartItem
          :config="config"
          :data="data"
          :language="language"
          :constant="constant"
          @expose="handleExpose"
        />
      </div>
      <template #footer>
        <SButtonGroup
          :width="'100%'"
          :num="2"
          :hor="true"
        >
          <SButtonGroupItem
            @click="handleCancel"
          >
            {{ language.SHEIN_KEY_PC_30061 }}
          </SButtonGroupItem>
          <SButtonGroupItem
            :type="['primary']"
            @click="handleConfirm"
          >
            {{ language.SHEIN_KEY_PC_30060 }}
          </SButtonGroupItem>
        </SButtonGroup>
      </template>
    </SDialog>
  </div>
</template>

<style lang="less" scoped>
.bc-item-del {
  &__item {
    width: 416px;
    background-color: #fff;
    padding: 12px;
  }
  &__mask {
    z-index: 2;
    border-radius: 12px 12px 0 0;
  }
  /deep/ .sui-dialog .sui-dialog__wrapper {
    padding: 19px 32px 22px 32px;
    width: 480px;
    min-height: 258px;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/20/a3/17188757422c7205911d9b042d667710ac81046e0c.png);
    background-size: cover;
  }
  /deep/ .sui-dialog .sui-dialog__title-wrap {
    padding: 0 32px;
    background: transparent;
  }
  /deep/ .sui-dialog .sui-dialog__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 17px;
    color: #fff;
    overflow: hidden;
  }
  /deep/ .sui-dialog .sui-dialog__body {
    padding: 0;
    min-height: 114px;
    margin-bottom: 21px;
    text-align: left;
  }
  /deep/ .main-carts__left {
    margin-right: 10px;
    width: 90px;
    height: 90px;
  }
  /deep/ .main-carts__right-top {
    margin-bottom: 6px;
  }
  /deep/ .main-carts__right {
    min-height: 80px;
  }
  /deep/ .main-carts__info {
    margin-bottom: 6px;
  }
}
</style>
