import FindSimilarText from './FindSimilarText.vue'
import { getEvents, getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useFindSimilarText = createHelper('FindSimilarText', getProps, getEvents)

export {
  FindSimilarText,
  useFindSimilarText,
}
