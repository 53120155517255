import isReselect from '../../utils/isReselect'

/**
 * GoodsAlert 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant, language }) {
  // 新人专享商品提示文案
  const newcomerItemText = data.value.isNewcomerItem ? language.value.SHEIN_KEY_PC_27125 : ''
  // 新人专享商品售罄提示文案
  const newcomerItemSoldOutText = data.value.isLoseNewcomerItem ? language.value.SHEIN_KEY_PC_27124 : ''
  // 售罄商品重新选择提示文案
  const reselectText = getRelectText({ data, constant, language })

  return {
    isShow: newcomerItemText || newcomerItemSoldOutText || reselectText,
    newcomerItemText,
    newcomerItemSoldOutText,
    reselectText
  }
}

function getRelectText({ data, constant, language }) {
  /**
   * 是否展示重新选择按钮
   * 商品售罄：
   *    1. 有效商品（当前尺码售罄，其他尺码有货）&& 非赠品/加价购附属品 && 非新人商品
   *    2. 付费会员赠品 && 付费会员权益存在
   */
  const showReselect = isReselect({ data, constant })

  let otherMallProductOnSale = false
  const mall_code_list = data.value.mall_code_list?.filter(mallItem => mallItem != data.value.mall_code) || []
  if(constant.value.IS_PLATFORM && mall_code_list.length) {
    otherMallProductOnSale = true
  }

  let mallSaleOutStatus = otherMallProductOnSale && (data.value.status == 1 || data.value.status == -1)


  if (data.value.isSoldOutItem && data.value.isSheinClubGiftItem) {
    return data.value.isInvalid ? language.value.SHEIN_KEY_PC_31013 : language.value.SHEIN_KEY_PC_30889
  }
  if (showReselect) return mallSaleOutStatus ? language.value.SHEIN_KEY_PC_22079 : language.value.SHEIN_KEY_PC_16775
}
