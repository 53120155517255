import GoodsQty from './GoodsQty.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsGoodsQty = createHelper('GoodsQty', getProps)

export {
  GoodsQty,
  useGoodsGoodsQty,
}
