/**
 * VoucherTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }) {
  const tag = data.value.multiPromotionTags?.find(tag => tag?.typeId === 33)
  const isShowMask = data.value.isSoldOutItem || data.value.is_checked !== 1 && data.value.isGiftItem

  return {
    isShow: !!tag,
    isShowMask,
    text: language.value.SHEIN_KEY_PC_30873,
    tip: language.value.SHEIN_KEY_PC_30875,
    tipDisabled: isShowMask,
    tipAppendToBody: true,
    subText: tag?.subText,
    maxWidth: '100%',
  }
}

export function getEvents() {
  return {}
}
