import GoodsStore from './GoodsStore.vue'
import { getProps, getEvents } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsStore = createHelper('GoodsStore', getProps, getEvents)

export {
  GoodsStore,
  useGoodsStore,
}
