import { provide, inject } from 'vue'

/**
 * 提供多语言
 * @param {Object} language 
 */
export const useProvideLanguage = (language) => {
  provide('language', language)
}

/**
 * 注入多语言
 */
export const useInjectLanguage = () => {
  return inject('language')
}
