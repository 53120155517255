import { ref } from 'vue'
import useLanguage from './useLanguage'
import useAbt from './useAbt'
import { getCart, updateCartItemQuantity, modifyCartCheckStatus, deleteCartItem, getMallInfo, getQsTimelinessInfo } from '../utils/fetcher'
import { promotionCenterNew } from '@/public/src/pages/common/promotion/indexNew.js'
import { getGroupByCoupon } from '../utils/group'
import { getPromoGroupList, getPromotionDiscounts, getCouponDiscounts, promotionGroupInfo, appendTag, handleFlashSaleOverLimit } from '../utils/promotion'
import PROMOTION_CONFIG from '@/public/src/pages/common/promotion/config.js'
import { UPDATE_CART_INFO_ACTIONS } from '../constants'
import { cloneDeep } from 'lodash'
import { handleFlashSaleOverScene } from 'public/src/pages/common/business-cart/utils/promotion.js'
import { SMessage } from '@shein-aidc/sui-message'
import { handleUpdateAndUpdateAttrErr } from 'public/src/pages/cart_new/utils/index.js'
import getQtyMax from '../utils/getQtyMax'

export default function useBusinessCart(option) {
  const extraParams = {
    autoUseCoupon: option.value.autoUseCoupon || false,
    autoUsePoints: option.value.autoUsePoints || false
  }
  const cartInfo = ref({})
  const integrityPromotionInfo = ref({})
  const groups = ref([])
  const flashSaleOverLimit = ref({})
  const flashSaleGroupList = ref([])
  const underPriceGroupList = ref([])
  const pageLoading = ref(true)
  let finished = false
  const { language, getLanguageData } = useLanguage()
  const { ABTParams, getAbtData } = useAbt()

  const loading = ref(false)
  const showLoading = () => loading.value = true
  const hideLoading = () => loading.value = false

  const isOpenDelete = ref(false)
  const deleteItemData = ref(null)
  const openDelete = (item) => {
    deleteItemData.value = item
    isOpenDelete.value = true
  }
  const closeDelete = () => {
    isOpenDelete.value = false
    deleteItemData.value = null
  }

  const constant = ref({
    PUBLIC_CDN: window.gbCommonInfo.PUBLIC_CDN,
    EVOLU_SHEIN_ID: window.gbCommonInfo.EVOLU_SHEIN_ID,
    RESOURCE_SDK: window.gbCommonInfo.RESOURCE_SDK,
    IS_RW: window.gbCommonInfo.IS_RW,
    LAZY_IMG: window.gbCommonInfo.LAZY_IMG,
    forceHttp: window.gbCommonInfo.forceHttp,
    GBCssRight: window.gbCommonInfo.GB_cssRight,
    lang: window.gbCommonInfo.lang,
    IS_SUGGESTED: window.gbCommonInfo.IS_SUGGESTED,
    IS_PLATFORM: false,
    SiteUID: window.gbCommonInfo.SiteUID,
    CART_STOCK_TIPS_SWITCH: window.gbCommonInfo.CART_STOCK_TIPS_SWITCH,
  })

  const quickShipTime = ref(null)

  const handleData = async ({ cartRes, action }) => {
    if (cartRes.code != 0 && !cartRes.info) return
    cartRes.info.carts = cartRes.info.carts.filter(item => !item.isSoldOutItem && !item.isNewComerItem4App)
    cartInfo.value = cartRes.info
    // 查促销
    const promotionData = await promotionCenterNew.preHandlePromotion({ goods: cartInfo.value.carts || [], promotion_range: cartInfo.value.promotion_range, isSuggested: window.gbCommonInfo.IS_SUGGESTED })
    integrityPromotionInfo.value = promotionData.integrityPromotionInfo
    // 处理数据
    const { cartNeedGroupPromotionTypeIds } = PROMOTION_CONFIG
    flashSaleGroupList.value = getPromoGroupList(cartInfo.value.carts || [], [10])
    underPriceGroupList.value = getPromoGroupList(cartInfo.value.carts || [], [30])
    cartInfo.value.carts?.forEach((item) => {
      item.promotionDiscounts = getPromotionDiscounts(item, language.value, integrityPromotionInfo.value,  flashSaleGroupList)
      item.couponDiscounts = getCouponDiscounts(item, language.value)

      item.product.product_promotion_info?.forEach(promotion => {
        // 追加特殊商品tag
        appendTag(item, promotion)
      })
    })
    groups.value = await getGroupByCoupon({ language: language.value, showCartList: cartRes?.info?.carts || [], promotionId: option.value.promotionId })

    groups.value.forEach(group => {
      group.list.forEach(item => {
        item.promotionGroupInfo = promotionGroupInfo({
          item,
          cartList: cartInfo.value.carts,
          integrityPromotionInfo: integrityPromotionInfo.value,
          itemPromotionGroupInfo: group,
          cartNeedGroupPromotionTypeIds,
          flashSaleGroupList: flashSaleGroupList.value,
          underPriceGroupList: underPriceGroupList.value,
        })
        item.maxQty = getQtyMax({ item, integrityPromotionInfo: integrityPromotionInfo.value })
        item.quickShipTime = quickShipTime.value
      })
    })
    flashSaleOverLimit.value = handleFlashSaleOverLimit({
      flashSaleOverShowType: 'toast',
      flashSaleOverLimit: flashSaleOverLimit.value,
      flashSaleGroupList: flashSaleGroupList.value,
      integrityPromotionInfo: integrityPromotionInfo.value,
      language: language.value,
    })
    option.value.cartUpdateCallBack && option.value.cartUpdateCallBack({
      cartInfo: cartInfo.value,
      groups: groups.value,
      action,
    })
  }

  const getData = async () => {
    pageLoading.value = true
    const promiseList = [getCart(extraParams)]
    if (!finished) {
      promiseList.push(getMallInfo(), getQsTimelinessInfo(), getLanguageData(), getAbtData())
    }
    try {
      const [cartRes, mallRes, qsRes] = await Promise.all(promiseList)
      if (mallRes?.mallInfoForSite?.length > 1) {
        constant.value.IS_PLATFORM = true
      }
      if (qsRes?.info) {
        quickShipTime.value = qsRes.info
      }
      await handleData({ cartRes, action: UPDATE_CART_INFO_ACTIONS.FETCH }).finally(() => {
        pageLoading.value = false
        finished = true
      })
    } catch {
      // empty
    }
  }

  const autoChecked = (item, modify) => {
    let reqParam = {
      item,
      operation_type: 1,
      cart_id_list: []
    }
    if(item.is_checked == 0) {
      reqParam.cart_id_list.push(item.id)
      reqParam.editCartId = item.id
    }
    reqParam.cart_id_list = reqParam.cart_id_list.concat(item.appendIds || [])
    reqParam.cart_id_list.length && modify && modify(reqParam)
  }

  const modify = async ({ cart_id_list, operation_type, item, onError }) => {
    showLoading()
    try {
      let originCarts = cloneDeep(cartInfo.value.carts)
      const cartRes = await modifyCartCheckStatus({ cart_id_list, operation_type, ...extraParams })
      if (cartRes.code == 0) {
        await handleData({ cartRes, action: UPDATE_CART_INFO_ACTIONS.MODIFY })
        handleFlashSaleOverScene({
          editCartId: item.id,
          carts: cartInfo.value.carts,
          originCarts: originCarts,
          flashSaleOverLimit: flashSaleOverLimit.value,
        })
      } else {
        onError && onError()
        cartRes.tips && SMessage({
          message: cartRes.tips,
          type: 'error',
          offset: 180,
          duration: 3000
        })
      }
    } finally {
      hideLoading()
    }
  }

  const updateQty = async ({ id, quantity, item, onError, onSuccess }) => {
    showLoading()
    try {
      let originCarts = cloneDeep(cartInfo.value.carts)
      const cartRes = await updateCartItemQuantity({ id, quantity, ...extraParams })
      if (cartRes.code == 0) {
        onSuccess && onSuccess()
        await handleData({ cartRes: cartRes.info, action: UPDATE_CART_INFO_ACTIONS.UPDATE_QUANTITY })
        handleFlashSaleOverScene({
          editCartId: item.id,
          carts: cartInfo.value.carts,
          originCarts: originCarts,
          flashSaleOverLimit: flashSaleOverLimit.value,
        })
      } else {
        onError && onError()
        let errorMessage = handleUpdateAndUpdateAttrErr(cartRes, language, item)
        errorMessage.msg && SMessage({
          message: errorMessage.msg,
          type: errorMessage.times ? 'error' : 'warning',
          offset: 180,
          duration: errorMessage.times || 3000
        })
        // 接入 apollo 控制是否调用 check 接口
        if (cartRes.CART_AUTO_CHECKOUT !== 'ON') autoChecked(item, modify)
      }
    } finally {
      hideLoading()
    }
  }

  const del = async ({ id }) => {
    showLoading()
    try {
      const cartRes = await deleteCartItem({ id, ...extraParams })
      await handleData({ cartRes, action: UPDATE_CART_INFO_ACTIONS.DELETE })
    } finally {
      hideLoading()
    }
  }

  const refetchData = async () => {
    showLoading()
    try {
      const cartRes = await getCart(extraParams)
      await handleData({ cartRes, action: UPDATE_CART_INFO_ACTIONS.UPDATE_ATTR })
    } finally {
      hideLoading()
    }
  }

  const publicRefetchData = async () => {
    showLoading()
    try {
      const cartRes = await getCart(extraParams)
      await handleData({ cartRes })
    } finally {
      hideLoading()
    }
  }
  

  return {
    // variables
    language,
    integrityPromotionInfo,
    groups,
    pageLoading,
    cartInfo,
    loading,
    constant,
    flashSaleOverLimit,
    isOpenDelete,
    deleteItemData,
    ABTParams,

    // methods
    getData,
    updateQty,
    modify,
    del,
    refetchData,
    autoChecked,
    openDelete,
    closeDelete,
    publicRefetchData,
  }
}
