<template>
  <div class="preview-goods">
    <div 
      v-for="(item, i) in products"
      :key="i"
      class="goods"
    >
      <img :src="item.goods_img" />
      <div
        v-show="!IS_SUGGESTED && item.unit_discount != 0"
        class="unit-discount"
      >
        <span>{{ (lang != 'il' ? '-' : '') + item.unit_discount + '%' }}</span>
      </div>
      <div 
        v-if="i == 2 && modalText"
        class="modal"
      >
        <span v-if="modalText">{{ modalText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const { lang, IS_SUGGESTED } = gbCommonInfo
const SHOW_LENGTH = 3

export default {
  name: 'PreviewGoods',
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang,
      IS_SUGGESTED
    }
  },
  computed: {
    products() {
      return (this.list || []).slice(0, SHOW_LENGTH).map(v => v.product || {})
    },
    modalText() {
      const length = this.list?.length
      if (length >= 4 && length < 100) return `+${length - 2}`
      if (length >= 100) return '99+'
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
.preview-goods {
  display: flex;
  .goods {
    position: relative;
    width: 52px;
    height: 52px;
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
    }
    .unit-discount {
      position: absolute;
      right: auto;
      text-align: center;
      font-size: 10px;
      color: #fff;
      direction: ltr /* rtl:ignore */;
      /* sh:begin */
      min-width: 2.75em;
      line-height: 1.35;
      top: 0;
      left: 0 /* rtl:ignore */;
      right: unset /* rtl:ignore */;
      background: @sui_color_discount;
    }
    .modal {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.70);
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
