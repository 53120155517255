/**
 * 滚动到指定位置
 * @param {*} cartItemId 滚到到的购物车商品id，没有则滚动到顶部
 * @param {*} immediate 是否瞬间滚动到顶部
 */
export const scroll = (cartItemId, immediate = false) => {
  const scrollEl = document.querySelector('.j-bc-content__groups')
  if (cartItemId) {
    const targetEl = document.querySelector(`.j-business-cart__item-${cartItemId}`)
    if (scrollEl && targetEl) {
      const { top: scrollElTop } = scrollEl.getBoundingClientRect()
      const { top: targetElTop } = targetEl.getBoundingClientRect()
      const diff = targetElTop - scrollElTop + scrollEl.scrollTop - 48
      if (scrollEl.scrollTo && !immediate) {
        scrollEl.scrollTo({ top: diff, behavior: 'smooth' })
      } else {
        scrollEl.scrollTop = diff
      }
    }
  } else {
    if (scrollEl && scrollEl.scrollTo  && !immediate) {
      scrollEl.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      scrollEl.scrollTop = 0
    }
  }
}
