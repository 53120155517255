<template>
  <div
    v-if="isShow"
    :class="classes"
    @click="handleClick"
  >
    {{ language.SHEIN_KEY_PC_16963 }}
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useInjectLanguage } from '../../hooks/useLanguage'

/**
 * 商品行找同款文案组件
 */

// hooks
const language = useInjectLanguage()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否禁用
   * @type {Boolean}
   */
  isDisabled: {
    type: Boolean,
    default: false,
  },
})

// data
// computed

const classes = computed(() => {
  return {
    'find-similar-text': true,
    'find-similar-text_disabled': props.isDisabled,
  }
})

// events
const handleClick = () => {
  emit('report')
  if (!props.isDisabled) {
    emit('click')
  }
}
</script>

<style lang="less" scoped>
.find-similar-text {
  display: inline-block;
  padding: 4.5px 10px;
  color: @sui_color_gray_dark1;
  font-weight: bold;
  cursor: pointer;
  background: #F2F2F2;
  border-radius: 14px;
  font-size: 13px;
  border: 1px solid #F2F2F2;
  text-decoration: none;
  &:hover:not(.find-similar-text_disabled) {
    border: 1px solid #222222;
  }
  &_disabled {
    opacity: .3;
    cursor: default;
    cursor: not-allowed
  }
}
</style>
