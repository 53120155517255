<script setup>
import { sui_icon_closed_14px_1 } from '@shein-aidc/icon-vue3'

const emit = defineEmits(['close'])
const handleClickCloseIcon = () => {
  emit('close')
}
</script>

<template>
  <section class="bc-content">
    <slot name="header"></slot>
    <sui_icon_closed_14px_1
      class="bc-content__close"
      size="16px"
      color="#fff"
      @click="handleClickCloseIcon"
    />
    <div class="bc-content__groups j-bc-content__groups">
      <slot></slot>
    </div>
  </section>
</template>

<style lang="less" scoped>
.bc-content {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 13px 13px 0 0;
  background: var(--main-background-image) no-repeat center top / 100% auto, var(--main-background-color);
  .bc-content__close {
    position: absolute;
    top: 12px;
    right: 11px;
    cursor: pointer;
  }
  .bc-content__groups {
    flex: 1;
    overflow-y: auto;
    margin: 0 16px 0;
    padding-bottom: 20px;
    border-radius: 10px 10px 0 0;
    scrollbar-width: none; // 火狐
    -ms-overflow-style: none; // ie
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
