import FreeShippingTag from './FreeShippingTag.vue'
import { getProps, getEvents } from './helper.js'
import { createHelper } from '../../../hooks/useHelper'

const useFreeShippingTag = createHelper('FreeShippingTag', getProps, getEvents)

export {
  FreeShippingTag,
  useFreeShippingTag,
}
