/**
 * GoodsSeriesFlag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant }) {
  const getSeriesFlagUrl = () => {
    let { series_info_list = [], corner_brand_right, corner_brand_left, corner_series_right, corner_series_left } = data.value?.product ?? {}
    // 1.没有系列角标，返回普通品牌角标
    if (!series_info_list || !series_info_list.length) {
      return constant.value.GBCssRight ? corner_series_right || corner_brand_right : corner_series_left || corner_brand_left
    }
    // 2.针对系列角标排序
    const res = [...series_info_list].sort((a, b) => {
      if (a.series_type === b.series_type) {
        return b.logo_show_position - a.logo_show_position
      }
      return b.series_type - a.series_type
    })
    
    return constant.value.GBCssRight ? res[0].corner_series_right : res[0].corner_series_left
  }
  const imgUrl = getSeriesFlagUrl()

  return {
    isShow: !!imgUrl,
    imgUrl: imgUrl,
  }
}
