<template>
  <span
    class="goods-delete-button"
    role="button"
    :title="title"
    @click="handleClick"
  >
    <sui_icon_delete_16px_1 size="16px" />
  </span>
</template>

<script setup>
import { sui_icon_delete_16px_1 } from '@shein-aidc/icon-vue3'

/**
 * 商品删除组件V2
 */

// events
const emit = defineEmits(['click', 'report'])

// props
defineProps({
  title: {
    type: String,
    default: '',
  },
})

const handleClick = () => {
  emit('report')
  emit('click')
}
</script>

<style lang="less" scoped>
.goods-delete-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  color: @sui_color_gray_dark1;
  text-decoration: none;
  font-size: 13px;
  &:hover {
    background: #f6f6f6;
  }
}
</style>
