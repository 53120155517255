import ReducePriceHint from './ReducePriceHint.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useReducePriceHint = createHelper('ReducePriceHint', getProps)

export {
  ReducePriceHint,
  useReducePriceHint,
}
