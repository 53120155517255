<template>
  <div
    :class="[
      'goods-qty-select',
      {
        'goods-qty-select_selected': isSelected,
        'goods-qty-select_disabled': disabled,
      },
    ]"
  >
    <span class="goods-qty-select__label">{{ label }}</span>
    <sui_icon_selected_16px
      v-if="isSelected"
      size="14px"
      :class="[
        'goods-qty-select__icon',
        {
          'goods-qty-select__icon_disabled': disabled,
        },
      ]"
    />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { sui_icon_selected_16px } from '@shein-aidc/icon-vue3'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  selectValue: {
    type: [String, Number],
    default: '',
  },
  maxOptionNumber: {
    type: Number,
    default: 10,
  }
})
const isSelected = computed(() => props.value == props.selectValue || (props.value == '' && props.selectValue > props.maxOptionNumber))
</script>

<style lang="less" scoped>
.goods-qty-select {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  font-family: Arial, system-ui;
  color: #222;
  cursor: pointer;
  z-index: @zindex-hack;
  &:hover {
    background-color: #F2F2F2;
  }
  &.goods-qty-select_selected {
    .goods-qty-select__label {
      font-weight: 700;
    }
  }
  &.goods-qty-select_disabled {
    cursor: not-allowed;
    background-color: #F6F6F6;
    &:hover {
      background-color: #F6F6F6;
    }
    .goods-qty-select__label {
      color: #CCC;
    }
  }

  .goods-qty-select__icon {
    /* stylelint-disable-next-line declaration-no-important */
    color: #000!important;
  }
  .goods-qty-select__icon_disabled {
    /* stylelint-disable-next-line declaration-no-important */
    color: #CCC!important;
  }
}
</style>
