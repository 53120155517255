/**
 * GoodsPrice 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant, language }) {
  const OLD_FONT_COLOR = {
    VIP: [
      '#A78A45',
      // RW 没有
      ''
    ],
    SHEIN_CLUB: [
      '#C96E3F',
      // RW 没有
      ''
    ],
    DISCOUNT: [
      '#FA6338',
      '#FC4070'
    ],
    NORMAL: [
      '#222',
      '#222',
    ]
  }
  const NEW_FONT_COLOR = {
    SHEIN_CLUB: [
      '#873C00',
      // RW 没有
      ''
    ]
  }
  const getColor = (colors) => constant.value.IS_RW ? colors[1] : colors[0]
  const getSalePriceColor = () => {
    const isVipItem = data.value.promotionTypeIds?.includes(12)
    const promotionCutPriceShow = data.value.reduce_price && data.value.is_checked != 1 && data.value.reduce_price?.siteFrom == constant.value.SiteUID && data.value.reduce_price?.reduceAmount?.amount > 0
    const isPremiumMember = data.value.promotionTypeIds?.includes(29) || data.value.isSheinClubGiftItem
    const isDiscountProduct = !isVipItem && !isPremiumMember && (promotionCutPriceShow || data.value?.product?.insurable == 1 || data.value.product?.feSalePrice?.amount !== data.value.product?.feOriginalPrice?.amount || (constant.value.IS_SUGGESTED === 'special_de' && [5].includes(data.value.suggestedPriceType) && data.value.product?.feSalePrice?.amount !== data.value.product?.suggested_sale_discount?.amount))

    if (constant.value.IS_SUGGESTED && constant.value.IS_SUGGESTED != 'special_de' && data.value.is_inversion === 1 && !data.value.isPromotionB && !data.value.isSheinClubItem) {
      return getColor(OLD_FONT_COLOR.NORMAL)
    }
    if (constant.value.IS_SUGGESTED == 'special_de' && ![1, 3, 5].includes(data.value.suggestedPriceType)) {
      return getColor(OLD_FONT_COLOR.NORMAL)
    }
    if (isDiscountProduct) {
      return getColor(OLD_FONT_COLOR.DISCOUNT)
    } else if (isVipItem) {
      return getColor(OLD_FONT_COLOR.VIP)
    } else if (isPremiumMember) {
      return data.value.isDiscountLogoA ? getColor(NEW_FONT_COLOR.SHEIN_CLUB) : getColor(OLD_FONT_COLOR.SHEIN_CLUB)
    }

    return getColor(OLD_FONT_COLOR.NORMAL)
  }
  const getIsSuggestedInversionPromotionA = () => {
    if(constant.value.IS_SUGGESTED == 'special_de') {
      return ![1, 3, 5].includes(data.value.suggestedPriceType)
    }
    if (constant.value.IS_SUGGESTED && +data.value.is_inversion === 1) {
      // 如果此时商品未参与B类活动，且未参与SHEIN CLUB，则此时展示的价格为黑色
      // 如果此时商品参与了B类活动，或参与了SHEIN CLUB，则此时可以展示B类活动和SHEIN CLUB的价格颜色、活动标签、价格前的icon，和线上保持一致
      return !(data.value.isPromotionB || data.value.isSheinClubItem)
    }
    return false
  }
  const isShowPremiumMemberIcon = data.value.promotionTypeIds?.includes(29) || data.value.isSheinClubGiftItem
  const isShowTimeIcon = (data.value.promotionTypeIds?.includes(3) && !getIsSuggestedInversionPromotionA()) || (data.value.promotionTypeIds?.includes(31) && data.value?.promotionLogoTypeList?.includes(3) && !getIsSuggestedInversionPromotionA())
  const isShowAppOnlyIcon = ((data.value.promotionTypeIds?.includes(3) && !getIsSuggestedInversionPromotionA()) || (data.value.promotionTypeIds?.includes(31) && data.value?.promotionLogoTypeList?.includes(3) && !getIsSuggestedInversionPromotionA()))
  const isShowBrandSaleIcon = Number(data.value?.flashType) === 6 && !getIsSuggestedInversionPromotionA()
  const isShowFlashSaleIcon = data.value.promotionTypeIds?.includes(10) && [1, 4, 5].includes(Number(data.value?.flashType)) && !getIsSuggestedInversionPromotionA()

  return {
    // price props
    isShowSalePrice: !(data.value.promotionTypeIds?.includes(12) && constant.value.IS_RW),
    salePrice: data.value.product?.feSalePrice?.amountWithSymbol,
    salePriceColor: getSalePriceColor(),
    // 划线价配置
    isShowOriginalPrice: constant.value.IS_SUGGESTED === 'special_de'
      ? [1, 3].includes(data.value.suggestedPriceType)
      : (data.value.promotionTypeIds?.includes(12) || +data.value.product?.feSalePrice?.amount < +data.value.product?.feOriginalPrice?.amount || data.value.product?.insurable == 1)
      && (!constant.value.IS_SUGGESTED || data.value.is_inversion !== 1),
  
    isShowOriginalPriceText: constant.value.IS_SUGGESTED === 'special',
    isShowOriginalPricePopover: constant.value.IS_SUGGESTED === 'special_de' && data.value.suggestedPriceType === 3,

    originalPrice: constant.value.IS_SUGGESTED === 'special_de'
      ? data.value.product?.retailPrice?.amountWithSymbol
      : data.value.product?.insurable == 1 && data.value.product?.sale_price_before_insured
        ? data.value.product?.sale_price_before_insured?.amountWithSymbol
        : data.value.product?.feOriginalPrice?.amountWithSymbol,
  
    originPriceText: language.value.SHEIN_KEY_PC_30549,
    originPricePopoverText: language.value.SHEIN_KEY_PC_31070,
  
    // 30天最低价配置
    isShowSuggestPrice: constant.value.IS_SUGGESTED === 'special_de' && [1, 5].includes(data.value.suggestedPriceType),
    suggestPrice: data.value.product?.suggested_sale_price?.amountWithSymbol,
    suggestPriceText: language.value.SHEIN_KEY_PC_31069,

    // promotion props
    isShowPremiumMemberIcon,
    isShowTimeIcon,
    isShowAppOnlyIcon,
    isShowBrandSaleIcon,
    isShowFlashSaleIcon,
    isShowPromotionIcon: isShowPremiumMemberIcon || isShowTimeIcon || isShowAppOnlyIcon || isShowBrandSaleIcon || isShowFlashSaleIcon,
    premiumMemberIconColor: data.value.isDiscountLogoA ? '#873C00' : '#C96E3F',
    timeIconColor: '#FA6338',
    appOnlyIconColor: '#FA6338',
    brandSaleIconColor: '#FFCD94',
    flashSaleIconColor: '#FFCD94',

    isShowRomweVipIcon: constant.value.IS_RW && data.value.promotionTypeIds?.includes(12),
    isShowSheinVipIcon: !constant.value.IS_RW && data.value.promotionTypeIds?.includes(12),
    isShowMask: ((data.value.isGiftItem || data.value.isSheinClubGiftItem) && !data.value.is_checked) || data.value.isSoldOutItem
  }
}
