import { toRefs, ref, defineAsyncComponent } from 'vue'
import { IncentivePointForFree, IncentivePointForPromo } from '../utils/incentivePoint'
import { daEventCenter } from 'public/src/services/eventCenter'
import { getPageAbt } from '../utils/fetcher.js'

daEventCenter.addSubscriber({ modulecode: '1-7-4' })

/**
 * 凑单利诱初始化逻辑
 */
export function useIncentivePoint(state) {
  const { addOnType, saInfo } = toRefs(state)

  // 用于存储标签
  const tags = ref([]) 

  // 用于存储气泡
  const bubble = ref({})

  // 当前利诱点实例
  let incentivePoint = null

  /**
   * 初始化利诱
   */
  async function init() {
    const params = {
      abtInfo: await getPageAbt(),
      saInfo: saInfo.value
    }
    incentivePoint = addOnType.value === 'freeShipping' ? new IncentivePointForFree(params) : new IncentivePointForPromo(params)
    // 初始化标签数据
    tags.value = (await incentivePoint.getTags({
      showBubble: false,
    })).tags ?? []

    triggerNotice()
  }

  /**
   * 更新
   * @param {Boolean} showBubble 是否展示气泡
   */
  async function update(showBubble = true) {
    const data = await incentivePoint.getTags({ showBubble })
    tags.value = data.tags ?? []
    // 线上气泡优先级最大
    if (showBubble) {
      bubble.value = data.bubble[0] ?? {}
    } else {
      bubble.value = {}
    }

    // 上报埋点数据
    triggerNotice()
  }

  /**
   * 销毁
   */
  function destroy() {
    incentivePoint?.destroy()
    tags.value = []
    bubble.value = {}
  }

  /**
   * 上报埋点数据
   */
  function triggerNotice() {
    daEventCenter.triggerNotice({
      daId: '1-7-4-16',
      extraData: incentivePoint.getExposeData()
    })
  }
  
  
  return {
    // methods
    init,
    destroy,
    update,
    // data
    tags,
    bubble,
  }
}

/**
 * 组凑单利诱组件
 */
export const AsyncIncentivePointComp = defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/IncentivePoint.vue'))
