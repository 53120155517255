<template>
  <div
    ref="tempRef"
    class="main-carts__wrap"
  >
    <!-- 主图、标签、腰带 -->
    <div class="main-carts__left">
      <GoodsImg
        v-if="goodsImgData.isShow"
        v-bind="goodsImgData.attrs"
      >
        <template #left>
          <GoodsDiscountFlag
            v-if="goodsDiscountFlagData.isShow"
            v-bind="goodsDiscountFlagData.attrs"
          />
        </template>
        <template #right>
          <GoodsBlackFridayFlag
            v-if="goodsBlackFridayFlagData.isShow"
            v-bind="goodsBlackFridayFlagData.attrs"
          />
          <GoodsSeriesFlag
            v-if="goodsSeriesFlagData.isShow && !goodsBlackFridayFlagData.attrs.isShow"
            v-bind="goodsSeriesFlagData.attrs"
          />
        </template>
        <template #bottom>
          <GoodsBelt
            v-if="goodsBeltData.isShow"
            v-bind="goodsBeltData.attrs"
          />
        </template>
      </GoodsImg>
    </div>
    <div class="main-carts__right">
      <!-- 标题 -->
      <div class="main-carts__right-top">
        <GoodsTitle
          v-if="goodsTitleData.isShow"
          v-bind="goodsTitleData.attrs"
        >
          <template #left>
            <GoodsTitleIcon
              v-if="goodsTitleIconData.isShow"
              v-bind="goodsTitleIconData.attrs"
            />
          </template>
        </GoodsTitle>
        <GoodsStore
          v-if="goodsStoreData.isShow"
          v-bind="goodsStoreData.attrs"
        />
      </div>
      <div class="main-carts__right-middle">
        <!-- 尺码、找同款、商品主图定制、用户行为标签 -->
        <div class="main-carts__info">
          <GoodsSaleAttr
            v-if="goodsSaleAttrData.isShow"
            class="main-carts__sale-attr"
            v-bind="goodsSaleAttrData.attrs"
          />
          <FindSimilarText
            v-if="findSimilarTextData.isShow"
            class="main-carts__find-similar-text"
            v-bind="findSimilarTextData.attrs"
          />
          <GoodsCustomContent
            v-if="goodsCustomContentData.isShow"
            class="main-carts__custom-content"
            v-bind="goodsCustomContentData.attrs"
          />
          <ClientOnly>
            <BehaviorLabel
              v-if="behaviorLabelData.isShow"
              class="main-carts__behavior-label"
              v-bind="behaviorLabelData.attrs"
            />
          </ClientOnly>
        </div>
        <!-- 活动标签 -->
        <div
          v-if="quickShipTagData.attrs.isShow || freeShippingTagData.attrs.isShow || evoluTagData.attrs.isShow || voucherTagData.attrs.isShow || promotionTagData.attrs.isShow || morePromotionTagData.attrs.isShow"
          class="main-carts__promotion"
        >
          <QuickShipTag
            v-if="quickShipTagData.isShow"
            class="main-carts__promotion-item"
            v-bind="quickShipTagData.attrs"
          />
          <FreeShippingTag
            v-if="freeShippingTagData.isShow"
            class="main-carts__promotion-item"
            v-bind="freeShippingTagData.attrs"
          />
          <EvoluTag
            v-if="evoluTagData.isShow"
            class="main-carts__promotion-item"
            v-bind="evoluTagData.attrs"
          />
          <VoucherTag
            v-if="voucherTagData.isShow"
            class="main-carts__promotion-item"
            v-bind="voucherTagData.attrs"
          />
          <PromotionTag
            v-if="promotionTagData.isShow"
            class="main-carts__promotion-item"
            v-bind="promotionTagData.attrs"
          />
          <MorePromotionTag
            v-if="morePromotionTagData.isShow"
            class="main-carts__promotion-item"
            v-bind="morePromotionTagData.attrs"
          />
        </div>
        <!-- 提示信息 -->
        <div
          v-if="goodsAlertData.attrs.isShow || reducePriceHintData.attrs.isShow"
          class="main-carts__tips"
        >
          <GoodsAlert
            v-if="goodsAlertData.isShow"
            class="main-carts__newbie-price-hint"
            v-bind="goodsAlertData.attrs"
          />
          <ReducePriceHint
            v-if="reducePriceHintData.isShow"
            class="main-carts__reduce-price-hint"
            v-bind="reducePriceHintData.attrs"
          />
        </div>
      </div>
      <!-- 价格、数量、删除、找同款、收藏-->
      <div class="main-carts__right-bottom">
        <div class="main-carts__prices">
          <GoodsPrice
            v-if="goodsPriceData.isShow"
            class="main-carts__price"
            v-bind="goodsPriceData.attrs"
          />
          <EstimatedPrice
            v-if="estimatedPriceData.isShow"
            class="main-carts__estimated-price"
            v-bind="estimatedPriceData.attrs"
          />
        </div>
        <div class="main-carts__operation">
          <GoodsQty
            v-if="goodsQtyData.isShow"
            v-bind="goodsQtyData.attrs"
          />
          <FindSimilarIcon
            v-if="findSimilarIconData.isShow"
            class="main-carts__find-similar"
            v-bind="findSimilarIconData.attrs"
          />
          <GoodsAddWish
            v-if="goodsAddWishData.isShow"
            class="main-carts__add-wish"
            v-bind="goodsAddWishData.attrs"
          />
          <GoodsDeleteButtonV1
            v-if="goodsDeleteButtonData.isShow && goodsDeleteButtonData.type === 'v1'"
            class="main-carts__delete"
            v-bind="goodsDeleteButtonData.attrs"
          />
          <GoodsDeleteButtonV2
            v-if="goodsDeleteButtonData.isShow && goodsDeleteButtonData.type === 'v2'"
            class="main-carts__delete"
            v-bind="goodsDeleteButtonData.attrs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

import { ClientOnly } from '@sheinfe/vue-client-only'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goodsImg'
import { GoodsTitle, useGoodsTitle } from '../cell/goodsTitle'
import { GoodsStore, useGoodsStore } from '../cell/goodsStore'
import { GoodsSeriesFlag, useGoodsSeriesFlag } from '../cell/goodsSeriesFlag'
import { GoodsBlackFridayFlag, useGoodsBlackFridayFlag } from '../cell/goodsBlackFridayFlag'
import { GoodsDeleteButtonV1, GoodsDeleteButtonV2, useGoodsDeleteButton } from '../cell/goodsDeleteButton'
import { GoodsDiscountFlag, useGoodsDiscountFlag } from '../cell/goodsDiscountFlag'
import { GoodsCustomContent, useGoodsCustomContent } from '../cell/goodsCustomContent'
import { GoodsSaleAttr, useGoodsSaleAttr } from '../cell/goodsSaleAttr'
import { GoodsAddWish, useGoodsAddWish } from '../cell/goodsAddWish'
import { GoodsAlert, useGoodsAlert } from '../cell/goodsAlert'
import { BehaviorLabel, useBehaviorLabel } from '../cell/behaviorLabel'
import { FindSimilarText, useFindSimilarText } from '../cell/findSimilarText'
import { FindSimilarIcon, useFindSimilarIcon } from '../cell/findSimilarIcon'
import { MorePromotionTag, useMorePromotionTag } from '../cell/goodsTags/morePromotionTag'
import { QuickShipTag, useQuickShipTag } from '../cell/goodsTags/quickShipTag'
import { FreeShippingTag, useFreeShippingTag } from '../cell/goodsTags/freeShippingTag'
import { EvoluTag, useEvoluTag } from '../cell/goodsTags/evoluTag'
import { VoucherTag, useVoucherTag } from '../cell/goodsTags/voucherTag'
import { PromotionTag, usePromotionTag } from '../cell/goodsTags/promotionTag'
import { ReducePriceHint, useReducePriceHint } from '../cell/reducePriceHint'
import { GoodsQty, useGoodsGoodsQty } from '../cell/goodsQty'
import { GoodsBelt, useGoodsBelt } from '../cell/goodsBelt'
import { GoodsPrice, useGoodsPrice } from '../cell/goodsPrice'
import { EstimatedPrice, useEstimatedPrice } from '../cell/estimatedPrice'
import { GoodsTitleIcon, useGoodsTitleIcon } from '../cell/goodsTitleIcon'

/**
 * 主车商品行模板
 */

// template hooks
import { templateProps, useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps(templateProps)

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsTitleData,
    goodsStoreData,
    goodsSeriesFlagData,
    goodsBlackFridayFlagData,
    goodsDeleteButtonData,
    goodsDiscountFlagData,
    goodsCustomContentData,
    goodsSaleAttrData,
    goodsAddWishData,
    goodsAlertData,
    behaviorLabelData,
    findSimilarTextData,
    findSimilarIconData,
    morePromotionTagData,
    quickShipTagData,
    freeShippingTagData,
    evoluTagData,
    voucherTagData,
    promotionTagData,
    reducePriceHintData,
    goodsQtyData,
    goodsBeltData,
    estimatedPriceData,
    goodsPriceData,
    goodsTitleIconData,
  ],

  // methods
  registerExpose,
} = useTemplate(
  props,
  // Helper functions used by the current template
  [
    useGoodsImg,
    useGoodsTitle,
    useGoodsStore,
    useGoodsSeriesFlag,
    useGoodsBlackFridayFlag,
    useGoodsDeleteButton,
    useGoodsDiscountFlag,
    useGoodsCustomContent,
    useGoodsSaleAttr,
    useGoodsAddWish,
    useGoodsAlert,
    useBehaviorLabel,
    useFindSimilarText,
    useFindSimilarIcon,
    useMorePromotionTag,
    useQuickShipTag,
    useFreeShippingTag,
    useEvoluTag,
    useVoucherTag,
    usePromotionTag,
    useReducePriceHint,
    useGoodsGoodsQty,
    useGoodsBelt,
    useEstimatedPrice,
    useGoodsPrice,
    useGoodsTitleIcon,
  ]
)

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMounted(handleExpose)
</script>

<style lang="less" scoped>
.main-carts {
  &__wrap {
    display: flex;
    background-color: #fff;
  }
  &__left {
    margin-right: 16px;
    width: 120px;
    height: 120px;
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    width: 0;
  }
  &__right-top {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
  &__right-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__right-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__tips {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 8px;
  }
  &__sale-attr {
    margin-right: 8px;
  }
  &__find-similar-text {
    margin-right: 8px;
  }
  &__custom-content {
    margin-right: 8px;
  }
  &__behavior-label {
    margin-right: 8px;
  }
  &__prices {
    flex: 1;
    overflow: hidden;
    margin-right: 20px;
  }
  &__price {
    margin-right: 8px;
  }
  &__operation {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  &__find-similar {
    margin-left: 12px;
  }
  &__add-wish {
    margin-left: 12px;
  }
  &__delete {
    margin-left: 12px;
  }
  &__newbie-price-hint {
    margin-bottom: 8px;
  }
  &__reduce-price-hint {
    padding-top: 4px;
    margin-top: auto;
  }
  &__promotion {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: 8px;
  }
}
</style>
