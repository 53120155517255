import { h } from 'vue'
import CouponStyleA from './CouponStyleA.vue'

/**
 * @description 优惠券凑单/多优惠/优惠券样式
 */
export default {
  name: 'CouponMultipleItem',

  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    abtInfo: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  render() {
    return h(CouponStyleA, {
      ...this.$props,
      ...this.$attrs,
    })
  }
}
