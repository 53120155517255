<template>
  <div class="normal-foot">
    <div class="left">
      <p 
        class="tips"
        v-html="desc"
      ></p>
      <ProgressBar
        transparent
        animation
        :progress-data="progressData"
      />
    </div>
    <div class="right">
      <s-button
        :type="['primary', 'H36PX']"
        class="cart-btn"
        @click="handleBack"
      >
        {{ config?.continueBtnUseLangKey ? language[config.continueBtnUseLangKey] : language.SHEIN_KEY_PC_28053 }}
      </s-button>
      <slot name="incentivePoint"></slot>
    </div>
  </div>
</template>

<script>
import ProgressBar from '../../../components/ProgressBar.vue'
import { getCouponDiscount, getCouponProgress } from '../../../utils/index'
import { template } from '@shein/common-function'

export default {
  name: 'NormalFoot',
  components: {
    ProgressBar,
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    // simpleFreeShipping: {
    //   type: Boolean,
    //   default: false,
    // },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['back'],
  computed: {
    progressData() {
      const { coupon_rule_list, _data } = this.couponInfo
      const { activeRuleIndex, activeRule } = _data || {}
      if (!Array.isArray(coupon_rule_list)) return []

      const is_0ThresholdCoupon = activeRuleIndex === 0 && parseFloat(activeRule.min) <= 0 // 判断是否为0门槛券且未选择任何商品

      return coupon_rule_list.map((rule, index) => {
        let progressRate = 0
        if (activeRuleIndex == -1) { // 未找到凑单档位代表全部已满足门槛
          progressRate = 100
        } else if (index == activeRuleIndex) { // 当前凑单档位
          const preRule = coupon_rule_list[index - 1]
          progressRate = is_0ThresholdCoupon ? 0 : getCouponProgress(rule, preRule) // 0门槛券时，文案与进度需要与其他券做区分
        } else { // 小于当前档位进度为100，反之为0
          progressRate = index < activeRuleIndex ? 100 : 0
        }

        return {
          progressRate, // 凑单进度
          ruleIndex: index, // 当前档位
          activeRuleIndex, // 正在凑单档位
        }
      })
    },
    desc() {
      if (!this.couponInfo?._data) return ''
      const { activeRule, activeRuleIndex, preRule } = this.couponInfo._data

      const is_0ThresholdCoupon = activeRuleIndex === 0 && parseFloat(activeRule.min) <= 0 // 判断是否为0门槛券且未选择任何商品

      if (this.isShipCoupon) {
        // 0门槛免邮券时，文案需要与其他券做区分
        return activeRuleIndex == -1
          ? template(this.language.SHEIN_KEY_PC_27081)
          : is_0ThresholdCoupon
            ? this.language.SHEIN_KEY_PC_30901
            : template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PC_27080)
      }

      if (activeRuleIndex == -1) {
        return template(getCouponDiscount(preRule), this.language.SHEIN_KEY_PC_27079)
      } else if (activeRuleIndex == 0) {
        // 0门槛券时，文案需要与其他券做区分
        return is_0ThresholdCoupon
          ? template(getCouponDiscount(this.couponInfo?.coupon_rule_list[0]), this.language.SHEIN_KEY_PC_30900)
          : template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, getCouponDiscount(activeRule), this.language.SHEIN_KEY_PC_27077)
      }

      return template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, getCouponDiscount(preRule), getCouponDiscount(activeRule), this.language.SHEIN_KEY_PC_27078)
    },
    isShipCoupon() { // apply_for: 5/6/9为邮费券，其他都是商品券
      return [5, 6, 9].includes(+this.couponInfo?.apply_for)
    },

  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
    refreshBusinessCart() {
      this.$refs?.FootWithBusinessCart?.refetchCarts?.()
    }
  },
}
</script>

<style lang="less" scoped>
.normal-foot {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  .left {
    flex-grow: 1;
    margin-right: 16px;
    .tips {
      text-align: initial;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 14px;
      color: @sui_color_gray_dark1;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /deep/ em {
        color: #FF6D2A;
        font-weight: bold;
      }
    }
  }
  .right {
    position: relative;
    .cart-btn {
      padding: 0 12px;
      color: #fff;
    }
    .free-ship {
      position: absolute;
      right: 0;
      top: -10px;;
      height: 18px;

      display: inline-flex;
      padding: 0px 4px;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      border: 1px solid #FFF;
      background: var(--sui-color-success, #198055);
      color: #fff;
      /* stylelint-disable-next-line selector-max-specificity */
      .free-ico {
        margin-right: 2px;
      }

      /* stylelint-disable-next-line selector-max-specificity */
      .free-text {
        color: #FFF;
        text-align: center;
        font-family: SF Pro;
        font-size: 8px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }
    }
  }
}
</style>
