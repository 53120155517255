<script setup>
import { cloneDeep } from 'lodash'
import { ref, inject, nextTick } from 'vue'
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import { SMessage } from '@shein-aidc/sui-message'
import { handleUpdateAndUpdateAttrErr } from 'public/src/pages/cart_new/utils/index.js'
import { handleFlashSaleOverScene } from 'public/src/pages/common/business-cart/utils/promotion.js'
import {
  selectGoodsReport,
  updateGoodsAttrReport,
  clickQtyInputReport,
  qtyExposeReport,
  inputQtyChangeReport,
  selectQtyChangeReport,
} from 'public/src/pages/common/business-cart/utils/analysis.js'
import { scroll } from 'public/src/pages/common/business-cart/utils/scroll.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import MainCartItem from 'public/src/pages/cart_new/components/cartItem/template/MainCartItem.vue'
import QuickViewPopover from 'public/src/pages/common/quickView/index.js'

/**
 * 非标购物车商品行组件
 */
const emit = defineEmits(['expose'])
const refetchData = inject('refetchData')
const modify = inject('modify')
const updateQty = inject('updateQty')
const constant = inject('constant')
const autoChecked = inject('autoChecked')
const openDelete = inject('openDelete')

// props
const props = defineProps({
  /**
   * 商品行数据
   */
  data: {
    type: Object,
    default: () => ({})
  },
  /**
   * 多语言包
   */
  language: {
    type: Object,
    default: () => ({})
  },
  /**
   * 非标界面配置
   */
  uiConfig: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 购物车信息
   */
  cartInfo: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 闪购超限数据
   */
  flashSaleOverLimit: {
    type: Object,
    default: () => ({}),
  }
})

// 商品行配置
const config = ref({
  // 商品图片
  GoodsImg: {
    props() {
      return {
        isClick: false,
      }
    }
  },
  // 黑五角标
  GoodsBlackFridayFlag: {
    isShow: false,
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props({ data, constant }) {
      const DISCOUNT_BG_COLOR = ['#FA6338', '#FC4070']
      const NORMAL_BG_COLOR = ['#000', '#000']
      const SHEIN_CLUB = ['#873C00', '#873C00']
      const getColor = colors => constant.value.IS_RW ? colors[1] : colors[0]

      const cartDiscountShow = data.value.product.sale_discount && data.value.promotionTypeIds?.includes(3) && data.value.product.sale_discount != data.value.product.unit_discount
      const cartPromotionDiscount = data.value.isUnderPriceAbtValue && data.value.promotionTypeIds?.includes(30) && data.value.product.unit_discount
      const specialCouponDiscount = data.value.promotionTypeIds?.includes(32) && data.value.product.unit_discount
      const flashSaleType = data.value.promotionTypeIds?.includes(10) ? data.value.flashType : data.value.promotionTypeIds?.includes(11) ? -1 : 0
      const isNewComerItem4App = data.value.isNewComerItem4App
      const notSpecialDiscount = data.value.promotionTypeIds?.some((id) => [1, 3, 9, 10, 11, 14, 15, 16, 17, 24, 25, 26, 29, 30, 31, 32].includes(id)) && data.value.product.unit_discount
      const isVip = data.value.isGiftItem && !data.value.isClubGiftItem
      const isSheinClub = data.value.isSheinClubItem

      let bgColor
      if (cartDiscountShow || cartPromotionDiscount || specialCouponDiscount || flashSaleType || isNewComerItem4App || notSpecialDiscount || isVip) {
        bgColor = getColor(DISCOUNT_BG_COLOR)
      } else if (isSheinClub) {
        bgColor = getColor(SHEIN_CLUB)
      } else {
        bgColor = getColor(NORMAL_BG_COLOR)
      }

      return {
        bgColor,
        fontColor: '#FFF',
      }
    }
  },
  // 商品腰带
  GoodsBelt: {
    props({ data }) {
      const BG_COLOR = {
        GIFT_BELT: [
          'rgba(250, 99, 56, 0.8)',
          'rgba(255, 105, 110, 0.8)',
        ],
        VIP_GIFT_BELT: [
          'rgba(135, 60, 0, 0.8)',
          // RW 没有
          'rgba(135, 60, 0, 0.8)',
        ],
        FLASH: [
          'rgba(0, 0, 0, 0.6)',
          'rgba(255, 80, 120, 0.8)'
        ],
        FLASH_NEW_PERSON: [
          'rgba(0, 0, 0, 0.6)',
          'rgba(255, 105, 110, 0.8)',
        ],
        INVENTORY_BELT: [
          'rgba(0, 0, 0, 0.6)',
          'rgba(0, 0, 0, 0.6)',
        ],
        ADD_ON_BELT: [
          'rgba(255, 248, 235, 0.8)',
          'rgba(255, 248, 235, 0.8)',
        ]
      }

      const FONT_COLOR = {
        GIFT_BELT: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        VIP_GIFT_BELT: [
          '#FFFFFF',
          // RW 没有
          '#FFFFFF',
        ],
        FLASH: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        FLASH_NEW_PERSON: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        INVENTORY_BELT: [
          '#FFFFFF',
          '#FFFFFF',
        ],
        ADD_ON_BELT: [
          '#A86104',
          '#A86104',
        ]
      }

      // 转换闪购类型, 0: 非闪购, 1: 普通闪购, 2: 新人闪购, 3: 特殊闪购, 4: 分人群专区闪购, 5: 不分人群专区闪购, -1: 直播闪购(PC无直播闪购)
      const flashSaleType = data.value.promotionTypeIds?.includes(10) ? data.value.flashType : (data.value.promotionTypeIds?.includes(11) ? -1 : 0)
      // 赠品腰带
      const isGiftBelt = data.value.isGiftItem && !data.value.isSheinClubGiftItem
      // 会员赠品腰带
      const isVipGiftBelt = data.value.isGiftItem && data.value.isSheinClubGiftItem
      // 库存腰带
      const isInventoryBelt = data.value.stock_show_type == 1 && constant.value.CART_STOCK_TIPS_SWITCH
      // 闪购腰带
      const isFlashBelt = data.value.stock_show_type == 0 && data.value.realTimeInventory > 0 && [-1, 1, 4, 5].includes(flashSaleType)
      // 新人腰带
      const isFlashNewPersonBelt = data.value.stock_show_type == 0 && data.value.realTimeInventory > 0 && [0, 2, 3, 6].includes(flashSaleType)
      // 换购品腰带
      const isAddItem = data.value.isAddItem
      // 获取不同站点腰带颜色
      const getColor = (colors) => constant.value.IS_RW ? colors[1] : colors[0]

      // 获取闪购腰带数量
      const getFlashNum = () => {
        const promotionFlash = data.value.product.product_promotion_info.filter(i => [10].includes(i.type_id))
        const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
        const realTimeInventory = +data.value.realTimeInventory || 0
        const flashNum = stock - sold_num
        const minNum = Math.min(realTimeInventory, flashNum)
        const maxNum = Math.max(realTimeInventory, flashNum)
        return minNum > 0 ? minNum : maxNum
      }

      if (isAddItem) {
        return {
          bgColor: getColor(BG_COLOR.ADD_ON_BELT),
          fontColor: getColor(FONT_COLOR.ADD_ON_BELT),
          beltText: props.language.SHEIN_KEY_PC_29518,
        }
      } else if (isGiftBelt) {
        return {
          bgColor: getColor(BG_COLOR.GIFT_BELT),
          fontColor: getColor(FONT_COLOR.GIFT_BELT),
          beltText: props.language.SHEIN_KEY_PC_30158,
        }
      } else if (isVipGiftBelt) {
        return {
          bgColor: getColor(BG_COLOR.VIP_GIFT_BELT),
          fontColor: getColor(FONT_COLOR.VIP_GIFT_BELT),
          beltText: props.language.SHEIN_KEY_PC_30158,
        }
      } else if (isInventoryBelt) {
        return {
          bgColor: getColor(BG_COLOR.INVENTORY_BELT),
          fontColor: getColor(FONT_COLOR.INVENTORY_BELT),
          // 非标不展示
          beltText: '',
        }
      } else if (isFlashBelt) {
        return {
          bgColor: getColor(BG_COLOR.FLASH),
          fontColor: getColor(FONT_COLOR.FLASH),
          beltText: props.language?.SHEIN_KEY_PC_25797?.replace('{0}', flashSaleType ? getFlashNum() : data.value.realTimeInventory)
        }
      } else if (isFlashNewPersonBelt) {
        return {
          bgColor: getColor(BG_COLOR.FLASH_NEW_PERSON),
          fontColor: getColor(FONT_COLOR.FLASH_NEW_PERSON),
          beltText: props.language?.SHEIN_KEY_PC_25797?.replace('{0}', flashSaleType ? getFlashNum() : data.value.realTimeInventory),
        }
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        isClick: false,
      }
    }
  },
  // 标题图标
  GoodsTitleIcon: {
    isShow: false,
  },
  // 行内店铺头
  GoodsStore: {
    isShow: false,
  },
  // 找同款按钮版
  FindSimilarText: {
    isShow: false,
  },
  // 商品主图定制
  GoodsCustomContent: {
    isShow: false,
  },
  // 营销标签
  PromotionTag: {
    isShow: false,
  },
  // 代金券标签
  VoucherTag: {
    isShow: false,
  },
  // 快速发货标签
  QuickShipTag: {
    isShow: !props.uiConfig.disableQuickShip,
    props({ data }) {
      return {
        timeText: data.value.quickShipTime,
      }
    }
  },
  // 免邮标签
  FreeShippingTag: {
    isShow: !props.uiConfig.disableFreeShipping,
  },
  MorePromotionTag: {
    props() {
      return {
        isShowCouponDiscount: !!props.uiConfig.autoUseCoupon,
        isShowPointDiscount: !!props.uiConfig.autoUsePoints,
      }
    },
  },
  // 用户行为标签
  BehaviorLabel: {
    isShow: false,
  },
  // 商品价格
  GoodsPrice: {
    props({ data, constant }) {
      const getSalePriceColor = () => {
        const FONT_COLOR = {
          SHEIN_CLUB: [
            '#873C00',
            '#873C00'
          ],
          DISCOUNT: [
            '#FA6338',
            '#FC4070'
          ],
          NORMAL: [
            '#222',
            '#222',
          ]
        }
        const getColor = colors => constant.value.IS_RW ? colors[1] : colors[0]
        const promotionCutPriceShow = data.value.reduce_price && data.value.is_checked != 1 && data.value.reduce_price.siteFrom == constant.value.SiteUID && data.value.reduce_price.reduceAmount.amount > 0
        const isVipItem = data.value.promotionTypeIds?.includes(12)
        const isPremiumMember = data.value.promotionTypeIds?.includes(29) || data.value.isSheinClubGiftItem
        const isDiscountProduct = !isVipItem && !isPremiumMember && (promotionCutPriceShow || data.value.product.insurable == 1 || data.value.product?.feSalePrice.amount !== data.value.product?.feOriginalPrice.amount || (constant.value.IS_SUGGESTED === 'special_de' && [5].includes(data.value.suggestedPriceType) && data.value.product?.feSalePrice.amount !== data.value.product?.suggested_sale_discount.amount))

        if (constant.value.IS_SUGGESTED && constant.value.IS_SUGGESTED != 'special_de' && data.value.is_inversion === 1 && !data.value.isPromotionB && !data.value.isSheinClubItem) {
          return getColor(FONT_COLOR.NORMAL)
        }
        if (constant.value.IS_SUGGESTED == 'special_de' && ![1, 3, 5].includes(data.value.suggestedPriceType)) {
          return getColor(FONT_COLOR.NORMAL)
        }
        if (isDiscountProduct) {
          return getColor(FONT_COLOR.DISCOUNT)
        } else if (isPremiumMember) {
          return getColor(FONT_COLOR.SHEIN_CLUB)
        }
        return getColor(FONT_COLOR.NORMAL)
      }
      return {
        salePriceColor: getSalePriceColor(),
        isShowSalePrice: true,
        isShowTimeIcon: false,
        isShowAppOnlyIcon: false,
        isShowBrandSaleIcon: false,
        isShowFlashSaleIcon: false,
        premiumMemberIconColor: '#873C00',
      }
    }
  },
  // 找同款图标版
  FindSimilarIcon: {
    isShow: false,
  },
  // 商品删除
  GoodsDeleteButton: {
    type: 'v2',
    events: {
      onClick({ data }) {
        openDelete(data.value)
      }
    }
  },
  GoodsAddWish: {
    isShow: false,
  },
  GoodsQty: {
    props({ data }) {
      return {
        isShow: !data.value.isGiftItem && !data.value.isAddItem,
        max: data.value.maxQty,
      }
    },
    events: {
      async onChange({ data, event }) {
        // 缓存更新前的值
        const num = data.value.quantity
        // 更新成字符串，避免后续恢复原值不触发更新
        data.value.quantity = String(event.quantity)

        const handleReport = (item, result) => {
          if (event.action === 'input') {
            inputQtyChangeReport({ item, result })
          }
          if (event.action === 'select') {
            selectQtyChangeReport({ item, result })
          }
        }
        updateQty({
          id: data.value.id,
          quantity: event.quantity,
          item: data.value,
          onError() {
            // 更新失败，恢复原值
            data.value.quantity = num
            handleReport(data.value, 0)
          },
          onSuccess() {
            handleReport(data.value, 1)
          }
        })
      },
      onReport({ event, data }) {
        if (event.type === 'clickInput') {
          clickQtyInputReport({ item: data.value })
        }
        if (event.type === 'clickNullValueOption') {
          selectQtyChangeReport({ item: data.value, result: '-' })
        }
      },
      onExpose({ data }) {
        qtyExposeReport({ item: data.value })
      }
    }
  },
  EstimatedPrice: {
    props({ data, constant }) {
      const isShowEstimated = data.value.coupon_flag == 1 || data.value.is_auto_use_points_flag == 1
      const isShowDiscount = +data.value.product.unit_discount > 0
        && !data.value.isGiftItem
        && !constant.value.IS_SUGGESTED
        && data.value.isNewDiscountStyle
      return {
        isShow: isShowEstimated || isShowDiscount,
        isShowEstimated,
        isShowDiscount,
        isHover: false,
      }
    }
  },
  GoodsSaleAttr: {
    props({ data }) {
      return {
        loading: data.value.isOpenQuickView,
      }
    },
    events: {
      async onClick({ data, language }) {
        const toggleLoading = () => {
          data.value.isOpenQuickView = !data.value.isOpenQuickView
        }
        toggleLoading()
        if (data.value.isCustomGood) {
          SMessage({
            htmlString: language.value.SHEIN_KEY_PC_27388,
            type: 'warning',
          })
          return
        }

        const getSelectedAttrIndex = () => {
          const selectedAttrIndex = []
          const attrs = data.value.attrs ? JSON.parse(data.value.attrs) : []
          const sku_sale_attr = data.value.product?.sku_sale_attr || []
          if(attrs) {
            attrs.forEach(item => {
              selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
            })
          }
          sku_sale_attr && sku_sale_attr.forEach(item => {
            if(!item.is_main){
              selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
            }
          })
          return selectedAttrIndex
        }
        const handleUpdateOrDone = async (res) => {
          if (+res.code === 0) {
            let originCarts = cloneDeep(props.cartInfo.carts)
            await refetchData()
            if((data.value.is_checked == 1 && data.value.id == res.info.cart.id) || (data.value.id != res.info.cart.id)){
              handleFlashSaleOverScene({
                editCartId: res.info.cart.id,
                carts: props.cartInfo.carts,
                originCarts: originCarts,
                flashSaleOverLimit: props.flashSaleOverLimit,
              })
            }
            if(data.value.mall_code != res.info.cart.mall_code || data.value.id != res.info.cart.id){
              nextTick(() => {
                scroll(res.info.cart.id)
              })
            }
          } else {
            let errorMessage = handleUpdateAndUpdateAttrErr(res, props.language, data.value)
            errorMessage.msg && SMessage({
              message: errorMessage.msg,
              type: errorMessage.times ? 'error' : 'warning',
              offset: 180,
              duration: errorMessage.times || 3000
            })
          }
          // 接入 apollo 控制是否调用 check 接口
          if (res.CART_AUTO_CHECKOUT !== 'ON') autoChecked(data.value, modify)
        }
        QuickViewPopover.show({
          goodsId: data.value.product.goods_id,
          imgRatio: await getImageRatio(data.value.product.goods_img),
          mallCode: data.value.mall_code,
          showPromotionTagInfo: false,
          extendForm: {
            id: data.value.id,
            quantity: +data.value.quantity,
            selectedAttrIndex: getSelectedAttrIndex(),
          },
          config: {
            isUpdateButton: true,
            hideViewFullDetail: true,
            hideWishlistBtn: true,
            showOneClickPay: false,
            showPromotionTagInfo: false,
          },
          callback: {
            handleOpenQuickViewDone() {
              toggleLoading()
              updateGoodsAttrReport({
                sku_id: data.value.product.goods_sn,
              })
            },
            // 点击更新按钮之后的回调
            handleUpdateDone: handleUpdateOrDone,
            // 加车成功之后的回调
            handleAddToCartDone: handleUpdateOrDone,
          }
        })
      }
    }
  },
})

// methods
const handleCheckedItem = async ({ label = '', checked = false }) => {
  let targetItem = props.data
  if((parseInt(targetItem.quantity) > parseInt(targetItem.realTimeInventory))){
    SMessage({
      message: props.language.SHEIN_KEY_PC_21623,
      type: 'error'
    })
    return
  }
  let reqParam = {
    operation_type: checked ? 1 : 2,
    cart_id_list: [],
    item: targetItem,
  }
  reqParam.cart_id_list.push(label)
  if (checked) {
    reqParam.cart_id_list = reqParam.cart_id_list.concat(targetItem.appendIds || [])
  }
  await modify(reqParam)
  selectGoodsReport({
    is_select: checked ? 0 : 1,
    select_type: 1,
  })
}
</script>

<template>
  <div class="bc-cart-item">
    <div class="bc-cart-item__checked">
      <SCheckbox
        :label="data.id"
        :disabled="data.isDisabled"
        :model-value="Boolean(data.is_checked)"
        :gap="'4px'"
        :outside-check="true"
        @change="handleCheckedItem"
      />
    </div>
    <div class="bc-cart-item__item">
      <MainCartItem
        :data="data"
        :config="config"
        :constant="constant"
        :language="language"
        @expose="emit('expose')"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
.bc-cart-item {
  display: flex;
  align-items: center;
  &__checked {
    flex-shrink: 0;
    width: 20px;
    margin-right: 16px;
  }
  &__item {
    flex: 1;
  }
}
</style>
