import MorePromotionTag from './MorePromotionTag.vue'
import { getProps, getEvents } from './helper.js'
import { createHelper } from '../../../hooks/useHelper'

const useMorePromotionTag = createHelper('MorePromotionTag', getProps, getEvents)

export {
  MorePromotionTag,
  useMorePromotionTag,
}
