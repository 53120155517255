import { ref } from 'vue'
import { abtservice } from '@/public/src/services/abt'
import { NEW_POS_KEYS } from '../constants'

export default function useAbt() {
  const ABTParams = ref({})

  const getAbtData = async () => {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const res = await abtservice.getUserAbtResult({ newPosKeys: NEW_POS_KEYS })
    ABTParams.value = res
  }

  return {
    ABTParams,
    getAbtData
  }
}
