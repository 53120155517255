import { ref } from 'vue'
import { getLanguage } from '../utils/fetcher'
import { PAGES } from '../constants'

export default function useLanguage() {
  const language = ref({})
  
  const getLanguageData = async () => {
    const [ data1, data2 ] = await Promise.all(PAGES.map(page => getLanguage({ page })))
    language.value = {
      ...data1.language,
      ...data2.language
    }
  }

  return {
    language,
    getLanguageData
  }
}
