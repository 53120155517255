import { getCouponInfo } from './fetcher'

/**
 * 优惠券分组
 * @param {object} param
 * @param {object} param.state
 * @param {array} param.showCartList 需要展示的购物车列表
 * @param {object} param.API 请求接口
 * @returns 
 */
async function getGroupByCoupon({ language, showCartList, promotionId }) {
  const cartGroups = []
  const cartPromotionGroup = {
    list: [],
    title: language.SHEIN_KEY_PC_30156,
    total: 0, // 商品总数
    amount: '', // 商品总价
    isChecked: '0', // 是否全选
  }
  const cartNormalGroup = {
    list: [],
    title: language.SHEIN_KEY_PC_30157,
    total: 0, // 商品总数
    amount: '', // 商品总价
    isChecked: '0', // 是否全选
  }
  if (promotionId) {
    const res = await getCouponInfo({ coupon_codes: [promotionId], error_type: 0 })
    if (res.code == 0) {
      const { select_available_price = {}, carts = [] } = res?.info?.coupon_diff?.[0] || {}
      cartPromotionGroup.amount = select_available_price?.amountWithSymbol || ''
      const cartIds = carts.map((cart) => cart.id)
      showCartList.forEach((cartItem) => {
        if (cartIds.includes(cartItem.id)) {
          cartPromotionGroup.list.push(cartItem)
          if (cartItem.is_checked == 1) {
            cartPromotionGroup.total += cartItem.quantity
          }
        } else {
          cartNormalGroup.list.push(cartItem)
          if (cartItem.is_checked == 1) {
            cartNormalGroup.total += cartItem.quantity
          }
        }
      })
    } else {
      showCartList.forEach((cartItem) => {
        cartNormalGroup.list.push(cartItem)
        if (cartItem.is_checked == 1) {
          cartNormalGroup.total += cartItem.quantity
        }
      })
    }
  } else {
    showCartList.forEach((cartItem) => {
      cartNormalGroup.list.push(cartItem)
      if (cartItem.is_checked == 1) {
        cartNormalGroup.total += cartItem.quantity
      }
    })
  }
  
  cartPromotionGroup.isChecked = (cartPromotionGroup.list.length && cartPromotionGroup.list.every(item => item.is_checked == 1)) ? '1' : '0'
  cartNormalGroup.isChecked = (cartNormalGroup.list.length && cartNormalGroup.list.every(item => item.is_checked == 1)) ? '1' : '0'
  cartGroups.push(cartPromotionGroup, cartNormalGroup)
  return cartGroups
}

export {
  getGroupByCoupon,
}
