import { getMainImageUrl } from 'public/src/pages/cart_new/utils/index.js'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index.js'
import { SToast } from '@shein-aidc/sui-toast'

/**
 * GoodsImg 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, constant }) {
  const imgUrl = getMainImageUrl(data.value, constant.value.IS_RW)

  return {
    width: '100%',
    height: '100%',
    imgUrl,
    isShowMask: ((data.value.isGiftItem || data.value.isSheinClubGiftItem) && !data.value.is_checked) || data.value.isSoldOutItem,
    isLazyLoad: true,
    isCutImg: true,
    isClick: !(data.value.isGiftItem && !data.value.isSheinClubGiftItem),
    isNewTabOpen: true,
  }
}

export function getEvents() {
  return {
    /**
     * 点击图片
     * @param {Object} data - 商品行数据
     * @param {Object} props - 当前组件 props
     * @param {Object} constant - 常量配置
     */
    onClick: async ({ data, props, constant, language }) => {
      if (data.value.isSoldOutItem && data.value.isSheinClubGiftItem) {
        SToast(data.value.isInvalid ? language.value.SHEIN_KEY_PC_31013 : language.value.SHEIN_KEY_PC_30889)
        return
      }
      const url = await getProductDetailUrl({
        imgSrc: data.value?.product?.goods_img,
        goods_url_name: data.value?.product?.goods_url_name,
        goods_id: data.value?.product?.goods_id,
        cat_id: data.value?.product?.cat_id,
        mall_code: data.value.mall_code,
      })
      window.open(constant.value.forceHttp + url, props.value.isNewTabOpen ? '_blank' : '_self')
    },
  }
}
