<template>
  <div class="normal-head">
    <div class="header-content">
      <div class="header-left">
        <p class="header-title">
          {{ couponTips }}
        </p>
        <p
          v-if="showCountDown"
          class="countdown"
        >
          <span class="ends-in">{{ endInText }}</span>
          <CountDown
            :time-stamp="timeStamp"
          />
        </p>
      </div>
      <div class="header-right">
        <Coupon
          :value="discountValue"
          :threshold="threshold"
          :language="language"
          :show-up-to="showUpTo"
          :font-style="{
            'font-size': isFreeShipCoupon ? '12px' : '16px'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCouponDiscount } from '../../../utils/index'
import { template } from '@shein/common-function'
import Coupon from './Coupon.vue'
import CountDown from '../../CountDown.vue'

export default {
  name: 'NormalHead',
  components: {
    CountDown,
    Coupon,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    couponTips() {
      return this.isFreeShipCoupon ? 
        this.language.SHEIN_KEY_PC_28084 : 
        template(this.discountValue || '', this.language.SHEIN_KEY_PC_28083)
    },
    endInText() {
      return this.language.SHEIN_KEY_PC_27520?.replace('{0}', '')
    },
    showCountDown() {
      const diffSec = this.couponInfo.use_end_time - Date.now() / 1000
      return diffSec < 60 * 60 * 24 * 3 // 大于72小时不显示
    },
    timeStamp() {
      return (this.couponInfo.use_end_time || 0) - Date.now() / 1000
    },
    isFreeShipCoupon() {
      return ['5', '6', '9'].includes(this.couponInfo.apply_for)
    },
    discountValue() {
      const { coupon_rule_list } = this.couponInfo
      if (this.isFreeShipCoupon) {
        return this.language.SHEIN_KEY_PC_28086
      }
      return getCouponDiscount(coupon_rule_list?.[coupon_rule_list.length - 1])
    },
    threshold() { // 券门槛
      const { coupon_rule_list } = this.couponInfo
      if (!coupon_rule_list) return
      return template(coupon_rule_list?.[coupon_rule_list.length - 1]?.min_amount?.amountWithSymbol, this.language.SHEIN_KEY_PC_27687)
    },
    showUpTo() {
      return this.couponInfo?.coupon_rule_list?.length >= 2
    }
  },
}
</script>

<style lang="less" scoped>
.normal-head {
  height: 100px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-image: url('/she_dist/images/add-on/coupon-addon-header-bg-92a0fc44f5.png');
  /* rw:begin */
  background-image: url('/she_dist/images/add-on/coupon-addon-header-bg_rw-c41995d347.png');
  .header-content {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    direction: ltr;
    padding: 0 92px 0 48px;

    .header-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      /* stylelint-disable-next-line selector-max-specificity */
      .header-title {
        font-size: 20px;
        font-weight: 860;
        line-height: 24px;
        text-align: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        font-family: "SF Pro";
        color: #fff;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .countdown {
        display: flex;
        align-items: center;
        /* stylelint-disable-next-line selector-max-specificity */
        .ends-in {
          margin-right: 5px;
          color: #000;
          font-size: 12px;
          font-weight: 510;
          font-family: SF Pro;
        }
        /* stylelint-disable-next-line selector-max-specificity */
        :deep(.count-down) {
          font-family: SF Pro;
          /* stylelint-disable-next-line selector-max-specificity */
          :deep(.divider) { color: #000; }
        }
      }
    }
    .header-right {
      width: 200px;
      height: 100%;
      flex-shrink: 0;
    }
  }
}
</style>
