import GoodsDiscountFlag from './GoodsDiscountFlag.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsDiscountFlag = createHelper('GoodsDiscountFlag', getProps)

export {
  GoodsDiscountFlag,
  useGoodsDiscountFlag,
}
