<template>
  <s-popover
    v-model="show"
    placemen="bottom"
    :title="title"
    :show-footer="true"
    trigger="unset"
    @button-click="handleButtonClick"
  >
    <template #buttonYes>
      {{ buttonYes }}
    </template>
    <template #buttonNo>
      {{ buttonNo }}
    </template>
    <template #reference>
      <span
        class="goods-delete-button"
        role="button"
        @click="handleClick"
      >
        <sui_icon_delete_16px_1 size="16px" />
      </span>
    </template>
  </s-popover>
</template>

<script setup>
import { ref } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover'
import { sui_icon_delete_16px_1 } from '@shein-aidc/icon-vue3'

/**
 * 商品删除组件V1
 */

// events
const emit = defineEmits(['click', 'report', 'button-click', 'button-report'])

// props
defineProps({
  title: {
    type: String,
    default: '',
  },
  buttonYes: {
    type: String,
    default: '',
  },
  buttonNo: {
    type: String,
    default: '',
  },
})

// data
const show = ref(false)

const handleClick = () => {
  if (show.value) return
  show.value = true
  emit('report')
  emit('click')
}
const handleButtonClick = (e) => {
  show.value = false
  emit('button-report', e)
  emit('button-click', e)
}
</script>

<style lang="less" scoped>
.goods-delete-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  color: @sui_color_gray_dark1;
  text-decoration: none;
  font-size: 13px;
  &:hover {
    background: #f6f6f6;
  }
}
</style>
