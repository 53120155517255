<template>
  <template v-if="!disabled && redraw">
    <SPopover
      v-model="visible"
      :trigger="trigger"
      :placemen="placemen"
      :delay-render="delayRender"
      :append-to-body="appendToBody"
      :fix-disappear-position="fixDisappearPosition"
      :theme="theme"
    >
      <template #reference>
        <div
          ref="reference"
          style="display: inline-block"
        >
          <slot
            name="reference"
            :visible="visible"
          ></slot>
        </div>
      </template>
      <template #default>
        <slot name="default"></slot>
      </template>
    </SPopover>
  </template>
  <template v-else>
    <slot name="reference"></slot>
  </template>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  trigger: {
    type: String,
    default: 'hover',
  },
  placemen: {
    type: String,
    default: 'bottom',
  },
  delayRender: {
    type: Number,
    default: 300,
  },
  appendToBody: {
    type: Boolean,
    default: true,
  },
  fixDisappearPosition: {
    type: Boolean,
    default: true,
  },
  theme: {
    type: String,
    default: 'dark',
  },
})

const redraw = ref(true)
const visible = ref(false)
</script>
