<template>
  <swiper-container
    v-if="isShow"
    ref="behaviorSwiper"
    :style="styles"
    init="false"
    class="behavior-label"
  >
    <swiper-slide
      v-for="(item, index) in labelList"
      :key="index"
      :index="index"
    >
      <div
        ref="behaviorSwiperContent"
        class="behavior-label__content"
      >
        <img
          :src="item.iconSrc"
          width="13"
          height="13"
        />
        <span class="label-text">
          {{ getItemText(item) }}
        </span>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script setup>
import { computed, watch, nextTick, ref, onBeforeUnmount } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { useInjectConstants } from'../../hooks/useConstant'
import { useInjectLanguage } from '../../hooks/useLanguage'
import { Autoplay } from 'swiper/modules'
import { register } from 'swiper/element'
import { isInViewPort } from 'public/src/pages/cart_new/utils/index.js'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'

typeof window !== 'undefined' && register()

/**
 * 商品行用户行为标签
 */

// hooks
const constants = useInjectConstants()
const registerExpose = useInjectRegisterExpose()
const language = useInjectLanguage()

// events
const emit = defineEmits(['expose', 'labelExpose'])

// props
const props = defineProps({
  /**
   * 是否展示遮罩
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 行为标签数据
   * @type {Array}
   */
  labels: {
    type: Array,
    default: () => ([]),
  },
  /**
   * 是否 swiper 滑动
   * @type {Boolean}
   */
  isSwiper: {
    type: Boolean,
    default: false,
  },
})

// data
const swiper = ref(null)
const behaviorSwiper = ref(null)
const behaviorSwiperContent = ref(null)

// computed
const labelList = computed(() => props.labels.filter((v) => v.labelId && v.showLabelTag) || [])
const isShow = computed(() => labelList.value.length > 0 && !constants.value.IS_RW)
const styles = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))

// methods

/**
 * 获取标签文案
 */
const getItemText = (item) => {
  return BehaviorLabel.getText(item, language.value)
}

/**
 * 用户行为标签曝光
 */
const handleLabelExpose = (index) => {
  const isInView = isInViewPort(behaviorSwiperContent.value[index])
  if (!isInView || !props.isSwiper) return
  const item = labelList[index]
  if (item) {
    item.isExposed = true
    emit('labelExpose', index)
  }
}

/**
 * 销毁 swiper
 */
const destorySwiper = () => {
  if (!swiper.value) return
  swiper.value?.autoplay?.stop?.()
  swiper.value?.destroy?.()
  swiper.value = null
}

/**
 * 初始化 swiper
 */
const initSwiper = () => {
  nextTick(() => {
    if (!swiper.value) {
      destorySwiper()
      // needSwipe TODO
      const config = {
        modules: [Autoplay],
        direction: 'vertical',
        allowTouchMove: false,
        autoplay: {
          delay: 1500,
          disableOnInteraction: false,
        },
        loop: true,
        observer: true,
        observeParents: true,
        on: {
          init: (swiper) => {
            const { realIndex } = swiper
            handleLabelExpose(realIndex)
          },
          realIndexChange: (swiper) => {
            const { realIndex } = swiper
            handleLabelExpose(realIndex)
          },
        }
      }
      Object.assign(behaviorSwiper.value, config)
      behaviorSwiper.value?.initialize?.()
      swiper.value = behaviorSwiper.value?.swiper
    } else {
      swiper.value?.loopDestroy?.()
      swiper.value?.loopCreate?.()
      swiper.value?.autoplay?.start?.()
    }
  })
}

/**
 * 初始化用户行为标签
 */
const initBehaviorLabel = ()=> {
  watch(() => labelList.value, (n) => {
    if (!n?.length) return
    if (typeof window != 'undefined') {
      initSwiper()
    }
  }, {
    immediate: true,
  })
}

/**
 * 用户行为组件曝光
 */
const handleExpose = () => {
  watch(() => isShow, (n) => {
    if (n.value) {
      const callback = () => {
        emit('expose', props.labels)
      }
      registerExpose('BehaviorLabel', callback)
    }
  }, {
    immediate: true,
  })
}

handleExpose()
initBehaviorLabel()
onBeforeUnmount(destorySwiper)
</script>

<style lang="less" scoped>
.behavior-label {
  margin: 0;
  height: 14px;
  display: block;
  opacity: var(--mask-opacity);

  &__content {
    overflow: hidden;
    font-size: 12px;
    color: @sui_color_micro_emphasis;
    display: inline-flex;
    align-items: center;
    height: 100%;
    .label-text {
      margin-left: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* rtl:begin:ignore */
      direction: ltr;
    }
  }
}
</style>
