import {
  onMounted,
  onUnmounted,
  inject,
  provide,
  watch,
  ref,
  reactive,
} from 'vue'
import { Expose } from '@shein-aidc/basis-expose'

/**
 * 商品行曝光处理
 * @param {Ref<Element>} exposeRef - 曝光元素
 */
export const useExpose = (exposeRef) => {
  const isParentExposed = ref(false)
  const exposeTask = reactive({})
  const expose = new Expose()

  // 提供给组件注册曝光事件
  const registerExpose = (key, callback) => {
    const component =
      exposeTask[key] || (exposeTask[key] = { exposed: false, callback: null })
    component.callback = callback

    // 同步注册的曝光事件
    if (
      isParentExposed.value &&
      typeof callback === 'function' &&
      !component.exposed
    ) {
      callback()
      component.exposed = true
    }
  }

  onMounted(() => {
    expose.observe(
      {
        elements: exposeRef.value,
        once: false,
      },
      () => {
        isParentExposed.value = true
      },
    )
  })

  watch(isParentExposed, (exposed) => {
    if (exposed) {
      // 异步注册的曝光事件
      Object.entries(exposeTask).forEach(([key, { exposed, callback }]) => {
        if (!exposed && typeof callback === 'function') {
          callback()
          exposeTask[key].exposed = true
        }
      })
    }
  })

  onUnmounted(() => {
    expose.unobserve?.()
  })

  return registerExpose
}

export const useProvideRegisterExpose = (registerExpose) => {
  provide('registerExpose', registerExpose)
}

export const useInjectRegisterExpose = () => {
  return inject('registerExpose', () => {
    console.error('useInjectRegisterExpose: registerExpose is not provided!')
  })
}
