<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <span class="more-tag__text">{{ text }}</span>
    <SuperPopover
      v-bind="superPopoverProps"
    >
      <template #default>
        <MoreTagContent
          v-bind="moreTagContentProps"
        />
      </template>
      <template #reference>
        <sui_icon_doubt_16px_1
          :class="classesIcon"
          size="12px"
        />
      </template>
    </SuperPopover>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { sui_icon_doubt_16px_1 } from '@shein-aidc/icon-vue3'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import SuperPopover from '../../../components/SuperPopover.vue'
import MoreTagContent from './components/MoreTagContent.vue'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose'])

const props = defineProps({
  /**
   * 是否展示当前组件
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 标签文本
   * @type {String}
   */
  text: {
    type: String,
    default: '',
  },
  /**
   * Popover标题
   * @type {String}
   */
  popoverTitle: {
    type: String,
    default: '',
  },
  /**
   * 促销折扣明细
   * @type {Array}
   */
  promotionDiscounts: {
    type: Array,
    default: () => ([]),
  },
  /**
   * 特价折扣
   * @type {Object}
   */
  singleOtherDiscount: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 优惠券折扣
   */
  couponDiscount: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 积分折扣
   * @type {Object}
   */
  pointDiscount: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 展示促销折扣
   * @type {Boolean}
   */
  isShowPromotionDiscounts: {
    type: Boolean,
    default: true,
  },
  /**
   * 展示特价折扣
   * @type {Boolean}
   */
  isShowSingleOtherDiscount: {
    type: Boolean,
    default: true,
  },
  /**
   * 展示优惠券折扣
   * @type {Boolean}
   */
  isShowCouponDiscount: {
    type: Boolean,
    default: true,
  },
  /**
   * 展示积分折扣
   */
  isShowPointDiscount: {
    type: Boolean,
    default: true,
  },
  /**
   * 图标是否禁用点击
   */
  disabledIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const classes = computed(() => ([
  'more-tag',
]))

const classesIcon = computed(() => ([
  'more-tag__icon',
  {
    'more-tag__icon-disabled': props.disabledIcon,
  },
]))

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const superPopoverProps = computed(() => ({
  disabled: props.disabledIcon,
}))

const moreTagContentProps = computed(() => {
  const content = {
    title: props.popoverTitle,
    list: []
  }

  if (props.isShowPromotionDiscounts) {
    content.list.push(...props.promotionDiscounts)
  }
  if (props.isShowSingleOtherDiscount) {
    Object.keys(props.singleOtherDiscount).length && content.list.push(props.singleOtherDiscount)
  }
  if (props.isShowCouponDiscount) {
    Object.keys(props.couponDiscount).length && content.list.push(props.couponDiscount)
  }
  if (props.isShowPointDiscount) {
    Object.keys(props.pointDiscount).length && content.list.push(props.pointDiscount)
  }

  return {
    content,
  }
})

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('MoreTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less" scoped>
.more-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  color: @sui_color_discount_dark;
  background: rgba(250, 240, 238, 0.71);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    margin-left: 2px;
    height: 12px;
    cursor: pointer;
    color: @sui_color_discount_dark;
  }

  &__icon-disabled {
    cursor: auto;
  }
}
</style>
