<template>
  <div
    v-if="isShow"
    :class="classnames"
    :style="style"
    @click="handleClick"
  >
    <div class="promotion-tag__text">
      {{ text }}
    </div>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="promotion-tag__line"></div>
        <template v-if="displayDateOrTime && dateFormat">
          <div class="promotion-tag__date">
            {{ dateFormat }}
          </div>
        </template>
        <template v-else>
          <CartCountdown
            v-if="timestamp"
            class="promotion-tag__count-down"
            v-bind="cartCountDownProps"
          />
        </template>
      </template>
    </ClientOnly>
    <sui_icon_closed_36px
      v-if="showMoreIcon"
      size="12px"
      class="promotion-tag__icon"
    />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'

import { ClientOnly } from '@sheinfe/vue-client-only'
import { sui_icon_closed_36px } from '@shein-aidc/icon-vue3'

import { template, timeTransformer } from '@shein/common-function'
import { MESSAGE_DATE } from '@/public/src/pages/common/biz_helper/timer_format/dictionary.js'

import CartCountdown from '../../../components/CartCountdown.vue'
import { useInjectConstants } from'../../../hooks/useConstant'
import { useInjectLanguage } from '../../../hooks/useLanguage'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'

// hooks
const constants = useInjectConstants()
const language = useInjectLanguage()
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['click', 'report', 'expose'])

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: false,
  },
  showMoreIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 标签类型 ( normal | flash | club-gift | coupon )
   * 该值决定了标签样式
   */
  type: {
    type: String,
    default: 'normal',
  },
  text: {
    type: String,
    default: '',
  },
  /**
   * 隐藏倒计时
   */
  hideCountdown: {
    type: Boolean,
    default: false,
  },
  timestamp: {
    type: Number,
    default: null,
  },
  /**
   * 倒计时显示的格式
   * true: Ends in 2024/06/14
   * false: 72:59:58
   */
  displayDateOrTime: {
    type: Boolean,
    default: false,
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const classnames = computed(() => [
  'promotion-tag',
  `promotion-tag__${props.type || 'normal'}`,
  {
    'promotion-tag__pointer': props.isClick,
    'has-count-down': showCountdown.value,
  }
])

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const dateFormat = computed(() => {
  if (typeof window === 'undefined') return ''

  if (!props.timestamp || !language.value.SHEIN_KEY_PC_30895) return ''

  const text = timeTransformer({
    time: props.timestamp * 1000,
    sDateMap: MESSAGE_DATE,
  })
  return template(text, language.value.SHEIN_KEY_PC_30895)
})

const showCountdown = computed(() => {
  if (props.hideCountdown || !props.timestamp) return false

  if (props.displayDateOrTime) return !!dateFormat.value

  return true
})

const cartCountDownProps = computed(() => ({
  timestamp: props.timestamp,
  hideOnZero: true,
  reloadOnZero: true,
  fontColor: constants.value.IS_RW ? '#e85172' : '#222',
  colonColor: constants.value.IS_RW ? '#e85172' : '#222',
  fontBgColor: '#fff',
}))

const handleClick = (e) => {
  emit('report')
  if (props.isClick) {
    emit('click', e)
  }
}

const handleExpose = () => {
  watch(() => props.show, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('PromotionTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less" scoped>
.promotion-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__normal {
    background: @sui_color_promo_bg;
    &.has-count-down {
      background: linear-gradient(90deg, @sui_color_promo_bg 0%, rgba(@sui_color_promo_bg, .5) 100%);
    }

    .promotion-tag__text {
      color: @sui_color_promo_dark;
    }

    .promotion-tag__date {
      color: @sui_color_promo_dark;
    }
  }

  &__flash {
    background: #FDF4D0;
    &.has-count-down {
      background: linear-gradient(90deg, #FDF4D0 0%, rgba(#FDF4D0, .3) 100%);
    }

    .promotion-tag__text {
      color: #A86104;
    }

    .promotion-tag__date {
      color: #A86104;
    }
  }

  &__club-gift {
    background: @sui_color_promo_bg;
    &.has-count-down {
      background: linear-gradient(90deg, #FFECE9 0%, rgba(#FFECE9, .3) 100%);
    }

    .promotion-tag__text {
      color: @sui_color_club_rosegold_dark1;
    }

    .promotion-tag__line {
      border-left-color: rgba(@sui_color_club_rosegold_dark1, .4);
    }

    .promotion-tag__date {
      color: @sui_color_club_rosegold_dark1;
    }

    .promotion-tag__icon {
      color: @sui_color_club_rosegold_dark1;
    }
  }

  &__coupon {
    position: relative;
    color: #FA6338;
    background: @sui_color_white;
    border: 1px solid #FA6338;
    border-radius: 1px;
    overflow: hidden;
    mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #FA6338;
      border-radius: 50%;
      top: 50%;
      /* rtl:begin:ignore */
      margin-top: -4px;
      left: -5px;
    }

    .promotion-tag__text {
      color: #FA6338;
    }
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid @sui_color_gray_dark3;
  }

  &__count-down {
    flex-shrink: 0;
    margin-top: 0;
  }

  &__pointer {
    cursor: pointer;
  }
}
</style>
