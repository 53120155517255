import GoodsSeriesFlag from './GoodsSeriesFlag.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsSeriesFlag = createHelper('GoodsSeriesFlag', getProps)

export {
  GoodsSeriesFlag,
  useGoodsSeriesFlag,
}
