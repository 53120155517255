import SimilarPopover from 'public/src/pages/common/similar_products/index.js'

/**
 * FindSimilarText 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  return {
    isShow: data.value.isSoldOutItem && !(data.value.isGiftItem || data.value.isAddItem),
    isDisabled: Boolean(Object.keys(data.value.product?.customization_info || {}).length),
    isClick: true,
  }
}

export function getEvents() {
  return {
    /**
     * 点击
     * @param {Object} data - 商品行数据
     * @param {Object} props - 当前组件 props
     * @param {Object} constant - 常量配置
     */
    onClick: async ({ data }) => {
      SimilarPopover.showModal(data.value.product, {
        similarFrom: 'out_of_stock',
      })
    },
  }
}
