/**
 * GoodsAddWish 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }) {
  return {
    isShow: !data.value.isGiftItem && !data.value.isAddItem,
    trigger: data.value.isNewcomerItem ? 'hover' : 'click',
    placement: data.value.isNewcomerItem ? 'top-end' : 'bottom',
    title: data.value.isNewcomerItem ? '' : language.value.SHEIN_KEY_PC_17955,
    content: data.value.isNewcomerItem ? language.value.SHEIN_KEY_PC_27119 : '',
    delayRender: 300,
    showFooter: !data.value.isNewcomerItem,
  }
}
