import EstimatedPrice from './EstimatedPrice.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useEstimatedPrice = createHelper('EstimatedPrice', getProps)

export {
  EstimatedPrice,
  useEstimatedPrice,
}
