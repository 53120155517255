import { handleOrderOrSingleIsMeetOrOver } from '@/public/src/pages/cart_new/utils/index'

/**
 * 获取限购数量
 * @param {*} param0 
 * @returns 
 */
function getLimitNum({ item, integrityPromotionInfo }) {
  const promotionGroupInfo = item.promotionGroupInfo
  /**
 * 超限限购数量
 * 0: 不限购
 * -1: 限购1，同类商品的其他sku已经满足或超过限购数量（该商品行只能选1）
 * 其他: 限购数量
 */
  let limitNum = 0
  let promotion = item.product.product_promotion_info?.find(item => item.type_id == 3)
  if (promotion) {
    const { promotion_id = '' } = promotion
    const promotionInfo = integrityPromotionInfo[promotion_id] || {}
    limitNum = promotionInfo.cart_limit?.single || 0
  } else {
    const { itemLimitNum } = handleOrderOrSingleIsMeetOrOver(item, promotionGroupInfo, false, true)
    limitNum = itemLimitNum
  }
  return limitNum
}

/**
 * 获取最大购买数量
 * @param {*} param0 
 * @returns 
 */
export default function getQtyMax({ item, integrityPromotionInfo }) {
  const limitNum = getLimitNum({ item, integrityPromotionInfo })
  if (limitNum === 0) {
    return Math.min(item.realTimeInventory, 99)
  } else if (limitNum === -1) {
    return Math.min(item.realTimeInventory, 1)
  } else {
    return Math.min(item.realTimeInventory, limitNum, 99)
  }
}
