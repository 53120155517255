<template>
  <div class="coupon-add-head">
    <div class="head-title">
      {{ title }}
    </div>
    <CouponProgress
      :language="language"
      :coupon-info="couponInfo"
    />
  </div>
</template>

<script>
import CouponProgress from './CouponProgress.vue'

export default {
  name: 'CouponAddHead',
  components: {
    CouponProgress,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    total: {
      type: String,
      default: '',
    }
  },
  computed: {
    title() {
      return this.language.SHEIN_KEY_PC_16480 + `${this.total ? `(${this.total})` : ''}`
    },
  },
}
</script>

<style lang="less" scoped>
.coupon-add-head {
  padding: 32px 48px 16px;
}
.head-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  position: relative;
  text-overflow: -o-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
